const Compliance = () => {
	return (
		<div className="legal page-padding">
			<div id="en">
				<h1>TERMOS E CONDIÇÕES DE USO</h1>
				<p>
					Termo de Adesão que firmam, entre si, o Titular que ao final
					e eletronicamente o assina, e a 
					VIATECH BANK PROCESSADORA DE PAGAMENTO LTDA, instituição de pagamento, 
					pessoa jurídica inscrita no CNPJ sob o nº35.858.847/0001­96, com sede na Avenida Doutor 
					Chucri  Zaidan,  nº1550,  Vila  São  Francisco,  São  Paulo/SP,  CEP:  04.711­130, 
					doravante 
					denominada Viatech, para regular os direitos e obrigações das partes na contratação, utilização 
					e manutenção dos produtos, aplicativos e serviços oferecidos pela Viatech ao Titular.
				</p>
				<h2>2. TERMOS E CONDIÇÕES DE USO ESPECÍFICOS</h2>
				<p>
					2.1  Para  fazer  uso  dos  serviços  online  disponibilizados,  cadastrar­se  neste 
					website  e,  eventualmente, manifestar  a  autonomia  de  sua  vontade  em  obter 
					conta transacional  junto  à 
					Viatech, diante dos Termos e Condições de Uso aqui apresentados, o(a) usuário(a), nos termo
					s 
					da legislação civil brasileira (Código Civil de 2002), declara ser PLENAMENTE capaz (artigos 
					1º ao 5º da Lei Federal 10.406/02, Código Civil brasileiro).
				</p>
				<p>
					2.2 O site oficial da Viatech, acessível pelo endereço 
					viatechbank.com.br, tem por finalidade 
					precípua o oferecimento de informações e ferramentas para a assistência financeira oferecida 
					pela  Instituição  aos  potenciais  e  efetivos  clientes  e,  eventualmente,  aos 
					demais  terceiros  interessados.
				</p>
				<p>
					2.3  É  imprescindível  que  o(a)  usuário(a)  leia,  compreenda  e  aceite  as 
					informações  aqui  apresentadas,  sob  seu total consentimento, já que a navegação pelo 
					site e canais oficiais da  Viatech  importará  na  coleta  de  dados  pessoais  dos 
					titulares,  bem  como  seu  tratamento, 
					considerando as finalidades determinadas de cada produto e/ou serviço escolhido pelo titular. 
					Este  consentimento  poderá  ser  revogado  pelo  titular  mediante  sua  expressa 
					manifestação, 
					através dos canais disponíveis e previstos na política de privacidade da Viatech.
				</p>
				<p>
					2.4 Ao acessar, usar, navegar ou comprar no site, aplicativo e demais canais oficiais da Viatech, 
					o(a) titular garante ter lido, compreendido e aceito, na integralidade, os Termos e Condições 
					de Uso do Website aqui descritos, bem como a sua Política de Privacidade.
				</p>
				<p>
					2.5 Quando requisitado, o Titular, cliente da Viatech, devera
					preencher os campos de abertura  da  Conta  com  informaçoes  completas,  recentes, 
					validas  e  corretas,  sendo  de  sua  exclusiva 
					responsabilidade manter todas as informaçoes fornecidas aos Serviços, Produtos e Aplicativo 
					da Viatech permanentemente atualizadas, de  forma a  sempre  refletir os  seus dados 
					reais.  A  Viatech nao se responsabiliza por erros no cadastro do Cliente.
				</p>
				<p>
					2.6 A Política de Privacidade da Viatech estabelece as condições em que a Instituição realiza 
					o tratamento dos
					dados pessoais do titular, cliente ou não, durante o oferecimento dos serviços, 
					produtos e aplicativo, bem como as informações sobre os direitos garantidos ao titular desses 
					dados.
				</p>
				<p>
					2.7 Os Termos e Condições de Uso do site, aplicativo e demais canais oficiais da Viatech, seu 
					conteúdo, seu uso, sua redação, seu acesso, sua disponibilização e a respectiva anuência dos 
					seus termos pelo usuário são regidos e amparados pela Constituição Federal (artigo 5º, caput e 
					incisos XII, XXVII e LXXIX e art. 21, XXVI), pela Lei Geral de Proteção de Dados, em sua 
					integralidade, Decreto nº 11.491, de 12 de abril de 2023, pelo Marco Civil Brasileiro (artigo 
					3º, incisos I, II, III e V, artigo 7º, inciso I, II, III, VI, VII, VIII, alíneas “a”, “b”
					e “c”, IX, X,
					XI, XIII, artigo 8º, caput, parágrafo único, incisos I e II, artigo 10º, caput e §§§§ 1º, 2º, 3º e 4º, 
					artigo 11º, §§§§ 1º, 2º, 3º e 4º, artigo 15, §§§§ 1º, 2º, 3º e 4º da lei federal 12.965/14) e pelo 
					Decreto regulamentar do Marco Civil Brasileiro (Decreto Presidencial 8.771 de 11 de maio de 
					2016).
				</p>
				<p>
					2.8  O  Titular  poderá  exercer  seus  direitos,  contemplando  a  exclusão  e  correção 
					de  dados 
					incompletos, inexatos ou desatualizados, para manter as informações fornecidas atualizadas e 
					em conformidade, em observância à Política de Privacidade da Viatech.
				</p>
				<p>
					2.9 A Viatech não encaminha e­mail, SMS, whatsapp ou utiliza outras ferramentas, que não 
					aquelas previstas em seu site oficial, para entrar em contato com o Titular. Ao receber qualquer 
					comunicação não oficial, em nome da Viatech, e suspeita
					de fraude, recomenda­se que o Titular 
					não abra os arquivos anexos e nem clique em qualquer link ou botão. O Titular poderá enviar 
					uma  mensagem  para suporte@viatechbr.com.br,  relatando  a  situação,  para  que  a 
					equipe  da  Instituição possa tomar as medidas cabíveis.
				</p>
				<h2>3. DA UTILIZAÇÃO DE CONTA TRANSACIONAL</h2>
				<p>
					3.1 Pelo presente instrumento, o Cliente, ao requerer a abertura de Conta Transacional junto à 
					Viatech, declara total conhecimento das regras e condições a seguir descritas:
				</p>
				<ul>
					<p>
						a)
						Por se tratar de conta transacional, sua movimentação se dará apenas através dos meios 
						eletrônicos oficiais da Viatech;
					</p>
					<p>
						b) A Conta transacional não se confunde com conta corrente de livre movimentação, e não 
						se propõe a oferecer ou conceder linhas de crédito ou investimentos ou movime ntação 
						por meio de cheques;
					</p>
					<p>
						c) A abertura e manutenção da conta transacional junto à Viatech estão condicionadas à 
						sua avaliação das informações cadastrais prestadas pelo Cliente, podendo sua abertura 
						ser rejeitada, por critérios internos, uma vez não satisfeitas as condições gerenciais da 
						Viatech;
					</p>
					<p>
						d) A Viatech poderá, a  seu exclusivo critério, na abertura ou durante a manutenção da 
						conta,  exigir  dados,  documentos  e  declarações  que  entenda  necessários  à 
						perfeita 
						identificação, qualificação, localização e conhecimento do Cliente, em especial, mas 
						não se limitando, às informações necessárias a prevenção e ao combate à lavagem de 
						dinheiro e financiamento ao terrorismo.
					</p>
					<p>
						e) A  recusa  do  Cliente  no  fornecimento  de  dados,  informações,  documentos  ou 
						declarações solicitadas pela Viatech poderá, a critério deste, ensejar a não realização do 
						cadastro para abertura da conta e/ou o bloqueio e encerramento da conta;
					</p>
					<p>
						f) O  acesso  por  meio  eletrônico  à  conta  transacional  dar­se­á  mediante 
						utilização  da 
						SENHA ELETRÔNICA de uso pessoal e intransferível, cadastrada por este junto ao 
						APLICATIVO, ou por identificação biométrica;
					</p>
					<p>
						g) As transações 
						financeiras geradas por iniciativa do Cliente somente serão concluídas 
						mediante a aposição de senha de efetivação, cujo sequencial numérico foi previamente 
						escolhido  por  este  quando  da  abertura  da  conta,  ou  posteriormente  alterada, 
						não  se 
						responsabilizando a Viatech por quaisquer prejuízos advindos da utilização das senhas 
						ou  de  qualquer  outro mecanismo  de  identificação  do Cliente  devido  ao  seu  uso 
						por  qualquer outra pessoa que não este, ainda que por ele autorizada;
					</p>
					<p>
						h)
						A Viatech estabelecerá limites de valor e de horário para a realização de operações nos 
						meios eletrônicos disponíveis ou quaisquer outros que venham a ser disponibilizados, 
						em conformidade com  as  regulamentações pertinentes à  Instituição, divulgada pelos 
						órgãos reguladores.
					</p>
				</ul>
				<p>
					3.2 A Conta aberta junto à Viatech é pessoal e intransferível, protegida por senha criada pelo 
					próprio cliente, a este cabe manter essa senha confidencial, evitando qualquer acesso indevido 
					às suas informações pessoais. Ainda, ao Titular, cliente da Viatech, cabe a 
					responsabilidade  pelas atividades realizadas em e com a conta de sua titularidade.
				</p>
				<h3>3.3 O Cliente declara­se ciente de que:</h3>
				<ul>
					<p>
						a) não  serão processadas as transações efetuadas após o horário  fixado e/ou com 
						valor 
						superior ao limite estabelecido, bem como de que a liberação de saques em terminais 
						eletrônicos, inclusive nos finais de semana, feriados ou em horário noturno, está
						condicionada à existência de saldo, já deduzidos eventuais débitos programados para o 
						primeiro dia útil seguinte;
					</p>
					<p>
						b)
						não será possível o cancelamento das transações financeiras efetuadas em sua conta por 
						meios eletrônicos, após a confirmação da operação;
					</p>
					<p>
						c) está  sujeito  à  cobrança  de tarifas  sobre  os  serviços,  e  desde já  autoriza 
						a  Viatech  a 
						debitar em sua conta aquelas referentes a produtos ou serviços por ele solicitados e/ou 
						utilizados ou decorrentes de normas do Banco Central do Brasil  – BACEN, conforme 
						Tabela de Tarifas disponibilizadas pela Viatech, na forma da regulamentação vigente;
					</p>
					<p>
						d) está  sujeito e autoriza a  realização do débito mensal  no  valor do pacote de 
						serviços  contratado, em sua conta;
					</p>
					<p>
						e) deverá observar e cumprir com as leis que dispõem sobre a prevenção e combate aos 
						crimes  de  lavagem  de  dinheiro  e  de  financiamento  ao  terrorismo  e  as  normas 
						complementares  editadas  pelo  Banco  Central  do Brasil  (BACEN)  e  Conselho 
						Monetário Nacional (CMN) e, ainda, têm ciência de que a Viatech, por força dessas leis 
						e  normas,  está  obrigada  a  comunicar  às  autoridades  competentes  a  ocorrência 
						de  operações previstas nas referidas normas;
					</p>
					<p>
						f) a Viatech poderá bloquear, pelo tempo necessário à apuração do fato, e/ou encerrar a 
						conta transacional, em caso de suspeitas de fraude na documentação ou nas informações 
						apresentadas, bem como em caso de descumprimento das normas que dispõem sobre a 
						prevenção  e  combate  aos  crimes  de  lavagem  de  dinheiro  e  de  financiamento  ao 
						terrorismo, podendo a Viatech adotar todos os procedimentos necessários à devolução 
						de recursos eventualmente depositados a bancos emissores, nos termos da legislação e 
						regulamentação em vigor;
					</p>
					<p>
						g) É  facultado  às Partes, de maneira unilateral, a 
						solicitação de encerramento da conta, 
						mediante manifestação expressa, com antecedência mínima de 30 (trinta) dias;
					</p>
					<p>
						h) A conta que permanecer 180 (cento e oitenta) dias corridos sem movimentação, será 
						considerada inativa, o que ensejará por parte da Viatech, as providências de início do 
						processo de encerramento;
					</p>
					<p>
						i) Constituirão, ainda, motivos para a rescisão e consequente cancelamento/bloqueio da 
						conta, além de outros previstos neste Termo:
						<ul>
							<li>
								Descumprimento  das  condições  previstas  neste  termo,  na  política  de 
								privacidade e  nos demais documentos jurídicos  firmados entre a Viatech e o 
								Cliente;
							</li>
							<li>
								A identificação da apresentação de informações inverídicas e/ou ausência das 
								informações  requeridas  ao  Cliente,  podendo  a  Viatech  realizar  prévia 
								comunicação  ao  Cliente  para  a  regularização  da  ocorrência  observada  para 
								evitar o encerramento da conta;
							</li>
							<li>
								A prática dolosa de qualquer ato ou omissão que, de qualquer forma, possa ter 
								concorrido  para  proporcionar  para  si  ou  para  terceiros,  vantagens  ilícitas 
								decorrentes do uso indevido da Conta transacional; e
							</li>
							<li>
								A  não  prestação  de  informações  essenciais  para  atendimento  as  normas  de 
								Prevenção e Lavag
							</li>
						</ul>
					</p>
				</ul>
				<h2>4 TERMOS E CONDIÇÕES QUE REGEM O USO DO WEBSITE DA VIATECH</h2>
				<p>
					4.1  O(A)  usuário(a),  ao  se  cadastrar,  enviar  dados,  trocar  informações  ou  usar 
					quaisquer 
					serviços de nosso website, age em concordância com a integralidade dos Termos e Condições 
					de Uso aqui descritos.
				</p>
				<p>
					4.2. O website usa cookies. Ao utilizar e acessar este domínio, o(a) usuário(a) manifesta o seu 
					assentimento no uso de cookies pela Viatech, nos termos da sua Política de Privacidade.
				</p>
				<p>
					4.3. Os limites e garantias expressamente previstos nestes Termos e Condições de Uso não 
					excluem ou invalidam outros direitos e
					garantias previstos no Código de Defesa do Consumidor 
					(8.078/90), Marco Civil da Internet (12.965/14) e Código Civil brasileiro (Lei 10.406/02).
				</p>
				<p>
					4.4. O(A) Titular poderá, desde que respeitadas as regras destes Termos e Condições de Uso, 
					da Política de Privacidade e da legislação, publicar e compartilhar o conteúdo deste website em 
					redes sociais nas quais a publicidade seja permitida e garantida.
				</p>
				<h2>5. LIMITES À USABILIDADE DO WEBSITE DA VIATECH</h2>
				<p>
					5.1. Salvo a propriedade inerente a terceiros, a Viatech é proprietária do conteúdo e material 
					veiculados neste website e dos direitos de propriedade industrial desse mesmo conteúdo, tais 
					como marca e logotipo, sendo vedado o uso de quaisquer destes bens sem autorização expressa.
				</p>
				<p>
					5.2. A Viatech  reserva a 
					si o direito de descontinuar ou alterar, parcial ou integralmente, o 
					conteúdo e os serviços deste website, bem como de sua publicidade, unilateralmente, sem aviso 
					prévio e a qualquer tempo.
				</p>
				<p>
					5.3.  A  Viatech  reserva  a  si  o  direito  de  suspender, temporariamente  ou 
					definitivamente,  o 
					cadastro e a aceitação de novos contratos quando constatados problemas técnicos que impeçam 
					a prestação de seus serviços dentro dos padrões mínimos ou quando tais medidas forem de seu 
					indispensável interesse.
				</p>
				<p>
					5.4. Na hipótese de alteração dos Termos e Condições de Uso, o prazo para sua entrada em 
					vigor será de 5 (cinco) dias a contar da data de publicação do novo documento no website.
				</p>
				<h3>5.5. Salvo autorização expressa da Viatech, o Titular não poderá:</h3>
				<ul>
					<p>
						a)
						Captar, mediante artifícios fraudulentos e não permitidos em lei, dados transmitido por 
						terceiros entre seus computadores pessoais e os servidores de conexão e hospedagem 
						do Website da Viatech;
					</p>
					<p>
						b) Fazer  uso  deste  website  para  copiar,  armazenar,  hospedar,  transmitir, 
						enviar,  usar,  publicar ou distribuir
						quaisquer materiais que consistam, ou estejam ligado a spyware,  malware,  vírus  de 
						computadores,  cavalos  de  tróia,  worms,  keyloggers,  rootkits  ou 
						outros softwares maliciosos;
					</p>
					<p>
						c) Executar procedimentos sistemáticos ou automatizados de coleta de dados, incluindo 
						data scraping, data mining e data harvesting, sem a autorização expressa da Viatech;
					</p>
					<p>
						d) Utilizar  no  cadastro  criado  neste  website  palavras  com  caráter  ofensivo, 
						discriminatório,  pejorativo,  de  baixo  calão  ou  que  possam,  em  razão  de  sua 
						terminologia, caracterizar apologia ao crime.
					</p>
				</ul>
				<h2>6 CANAIS DE ATENDIMENTO DA VIATECH </h2>
				<p>
					6.1. Viatech, ciente da importância de um canal de comunicação direta com os seus clientes, 
					dispõe de um canal de atendimento para esclarecimento de dúvidas, reclamações e sugestões, 
					respeitando  a  legislação  pátria,  em  especial  as  normas  previstas  no  Código  de 
					Defesa  do 
					Consumidor (Lei 8078/1990), Código Civil brasileiro (Lei Federal 10.406/02) e o Marco Civil 
					da internet (12.965/14).
				</p>
				<p>
					6.2. A comunicação com a Viatech poderá  ser  realizada por e­mail, telefone 
					fixo e/ou pelo  website da Viatech.
				</p>
				<p>
					6.3. O horário de atendimento dos canais de comunicação, do  suporte e do atendimento da 
					Viatech é de segunda a sexta­feira, das 09:00 às 18:00.
				</p>
				<p>
					6.4. A Viatech fornece, modalidades de atendimento para que o (a) usuário(a) possa escolher 
					o canal mais apropriado para o saneamento das suas necessidades, encontradas na Central de 
					Relacionamento, através do link:
					<a href="www.viatechbank.com.br" target="_blank" rel="external nofollow noopener">
						{' '}
						www.viatechbank.com.br
					</a>
					. 
				</p>
				<h2>7 ENCARREGADO PELO TRATAMENTO DE DADOS PESSOAIS</h2>
				<p>
					7.1 Seguindo as diretrizes da Lei n° 13.709/2018, 
					a Viatech mantém a estrutura de  Proteção
					de Dados ou DPO, que pode ser acionado em caso de dúvidas relacionadas ao cumprimento da 
					LGPD e demais diretrizes da Política de Privacidade e também deste Termos e Condições de 
					uso, pelo e­mail: dpo@viatechbr.com.br
				</p>
				<h2>8 DISPOSIÇÕES FINAIS</h2>
				<p>
					8.1  O(A)  usuário(a)  é  responsável,  civil  e  criminalmente,  pela  mau  uso  ou 
					pela  utilização 
					indevida de dados, vídeos, textos, ilustrações, marcas, imagens e demais bens de direitos de 
					propriedade intelectual ou industrial do website da Viatech ou de terceiros cujos serviços ou 
					produtos estejam expostos neste website.
				</p>
				<p>
					8.2  As  imagens  de  prestadores  de  serviços  no  website  da  Viatech  possuem 
					finalidade 
					meramente ilustrativa e não representam o colaborador que, eventualmente, prestará o serviço
				</p>
				<p>
					8.3 As informações técnicas de 
					serviços prestados por terceiros, intermediados por meio do 
					website da Viatech, são de integral responsabilidade deles (indústria ou empresa produtora ou 
					fornecedora), não se responsabilizando a Viatech por eventual erro, equívoco ou disparidade 
					nas descrições constantes nos produtos e serviços desses terceiros.
				</p>
				<p>
					8.4 Estes Termos e Condições de Uso foram redigidos e deverão ser interpretados na língua 
					portuguesa.
				</p>
				<p>
					8.5 As cláusulas e os itens aqui previstos são regidos pela legislação da República Federativa 
					do Brasil.
				</p>
				<p>
					8.6 Os Termos e Condições de Uso e a Política de Privacidade serão disponibilizados de forma 
					clara, adequada e ostensiva.
				</p>
				<p>
					8.7 Na hipótese de dúvidas, sugestões ou discordância sobre estes Termos e Condições de Uso, 
					o (a) Titular deverá contatar a Viatech pelos seguintes meios de comunicação, no horário de 
					suporte e atendimento, que é de segunda a sexta­feira, das 09:00 às 18:00:
				</p>
				<p>
					e­mail:
					<a href="suporte@viatechbr.com.br" target="_blank" rel="external nofollow noopener">
						 suporte@viatechbr.com.br
					</a>
				</p>
				{/* <p>b) telefone: xxxxxxxxxxxxxx</p> */}
				<p>
					8.9 O foro competente para a resolução de eventuais litígios é o do consumidor ou o da comarca 
					de São Paulo/SP.
				</p>
			</div>
		</div>
	);
};

export default Compliance;
