import React, { useEffect } from 'react';

import './footer.css';
import { Link } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import { FadeIn } from '../fadein';
import { FormattedMessage } from 'react-intl';

export const Footer = () => {
	return (
		<div className="footer-wrapper ">
			<FadeIn className="footer-services">
				<div className="footer-col footer-company-name">Viatech Bank.</div>
				<div className="footer-col">
					<div className="footer-item footer-title">
						<FormattedMessage id="menu.productAndService" defaultMessage="Product And Service" />
					</div>
					<div
						onClick={() => {
							window.location.href = '/about';
						}}
						className="footer-item"
					>
						<FormattedMessage id="menu.aboutUs" defaultMessage="About us" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/payment';
						}}
					>
						<FormattedMessage id="menu.payment" defaultMessage="Payment" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/digital_wallet';
						}}
					>
						<FormattedMessage id="menu.bankWallet" defaultMessage="Bank wallet" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/creditCard';
						}}
					>
						<FormattedMessage id="menu.creditCard" defaultMessage="Credit cards" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/fiatCurrencyExchange';
						}}
					>
						<FormattedMessage
							id="menu.globalFiat"
							defaultMessage="Global fiat and digital currency conversion"
						/>
					</div>
				</div>
				<div className="footer-col">
					<div className="footer-item footer-title">
						<FormattedMessage id="menu.support" defaultMessage="Support" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/faq';
						}}
					>
						<FormattedMessage id="menu.faq" defaultMessage="FAQ" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/laws';
						}}
					>
						<FormattedMessage id="menu.legal" defaultMessage="Legal" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/recruitment';
						}}
					>
						<FormattedMessage id="menu.career" defaultMessage="Career" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/business_support';
						}}
					>
						<FormattedMessage id="menu.helpAndSupport" defaultMessage="Help And Support" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/dashbord';
						}}
					>
						<FormattedMessage id="menu.dashboard" defaultMessage="Dashboard" />
					</div>
				</div>
				<div className="footer-col">
					<div className="footer-item footer-title">
						<FormattedMessage id="menu.globalLayout" defaultMessage="Global Layout" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/cross_border';
						}}
					>
						<FormattedMessage id="menu.cross-border" defaultMessage="Cross-border payment" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/latin';
						}}
					>
						<FormattedMessage id="menu.latinAmerica" defaultMessage="Latin America" />
					</div>
					<div
						className="footer-item"
						onClick={() => {
							window.location.href = '/europe';
						}}
					>
						<FormattedMessage id="menu.europe" defaultMessage="Europe" />
					</div>
				</div>
			</FadeIn>
			<FadeIn className="footer-bottom page-padding">
				<div className="footer-left">
					Copyright ©<b>ViatechBank</b> -Todos os direitos reservados.
				</div>
				<div className="footer-right">
					{/* <div onClick={() => {
							window.location.href = '/';
						}}>Imprint</div> */}
					<div onClick={() => {
							window.location.href = '/laws';
						}}>Privacy Policy</div>
				</div>
			</FadeIn>
		</div>
	);
};
