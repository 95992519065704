import React from 'react';
import './index.scss';
import { useIntl } from 'react-intl';
import dashbordbanner from '../../asserts/images/dashbordbanner.png';
import { FadeIn } from '../../components/fadein';
import professionaltechnical from '../../asserts/images/professionaltechnical.png';
import dashbordpt from '../../asserts/images/dashbordpt.png';
import bestuser from '../../asserts/images/bestuser.png';
import accurate from '../../asserts/images/accurate.png';
import Questions from '../../components/questions';
import { useLanguage } from '../../config/LanguageContext';

const Dashbord = () => {
	const intl = useIntl();
	const { locale } = useLanguage();
	return (
		<div className="dashbord">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container support-hero-grid">
					<div className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.dashbord.visual',
								defaultMessage: 'Visual fund management',
							})}
						</div>
						<div className="banner-des">
							{intl.formatMessage({
								id: 'page.dashbord.weGuarantee',
								defaultMessage:
									'We guarantee reliable data and in-depth analysis to facilitate the management of your payment operations',
							})}
						</div>
						<div className="navbar-right">
							<a href="/contactUs" className="button primary small w-inline-block">
								<div className="button-inner">
									<div className="button-inner-text">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
									<div className="button-inner-text-hover">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
								</div>
							</a>
						</div>
					</div>
					<div className="banner-img in">
						<img src={dashbordbanner} alt="" />
					</div>
				</div>
			</div>
			<div className="about-section  page-padding">
				<div className="dashbord-title">
					{intl.formatMessage({
						id: 'page.dashbord.officeManagement',
						defaultMessage: 'Viatech bank back office management system',
					})}
				</div>
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-des">
							<div className="dashbord-box">
								<img src={accurate} alt="" />
								<div className="dashbord-box-title">
									{intl.formatMessage({
										id: 'page.dashbord.accurate',
										defaultMessage: `Accurate data statistics`,
									})}
								</div>
								<div className="dashbord-box-desc">
									{intl.formatMessage({
										id: 'page.dashbord.control',
										defaultMessage: `Control your own money flow at any time through our system`,
									})}
								</div>
							</div>
						</div>
					</div>
					<div className="about-item-content">
						<div className="about-item-des">
							<div className="dashbord-box">
								<img src={bestuser} alt="" />
								<div className="dashbord-box-title">
									{intl.formatMessage({
										id: 'page.dashbord.bestUser',
										defaultMessage: `Best user experience`,
									})}
								</div>
								<div className="dashbord-box-desc">
									{intl.formatMessage({
										id: 'page.dashbord.prioritize',
										defaultMessage: `Prioritizing customer needs, just to give you a better experience`,
									})}
								</div>
							</div>
						</div>
					</div>
				</FadeIn>
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.dashbord.professional',
								defaultMessage:
									'Professional technical team and perfect system to track your business',
							})}
						</div>
					</div>
					<div className="about-item-img">
						<img src={locale === 'en-US' ? professionaltechnical : dashbordpt} alt="" />
					</div>
				</FadeIn>
			</div>
			<FadeIn>
				<section className="question page-padding">
					<Questions />
				</section>
			</FadeIn>
		</div>
	);
};
export default Dashbord;
