export default {
	'page.creditCard.bannerTitle': 'Cartão de crédito Viatechbank',
	'page.creditCard.bannerDesc':
		'O uso de cartões de crédito para adiantar o consumo ou parcelar é cada vez mais o método de pagamento predominante nas pessoas',
	'page.creditCard.limitIterm_01.title': 'Alto limite de crédito, baixa taxa de juros',
	'page.creditCard.limitIterm_01.desc':
		'oferece um limite de crédito generoso e taxas de juros ultrabaixas, permitindo gerenciar facilmente grandes compras e despesas diárias, desfrutando de uma experiência de fluxo de caixa sem preocupações.',
	'page.creditCard.limitIterm_02.title': 'Aceitação global, uso sem complicações',
	'page.creditCard.limitIterm_02.desc':
		'oferece um limite de crédito generoso e taxas de juros ultrabaixas, permitindo gerenciar facilmente grandes compras e despesas diárias, desfrutando de uma experiência de fluxo de caixa sem preocupações.',
	'page.creditCard.limitIterm_03.title': 'Uso seguro e sem preocupações',
	'page.creditCard.limitIterm_03.desc':
		'Fornecemos medidas de segurança abrangentes para garantir que cada transação seja segura e sem preocupações.',
	'page.aboutUs.bannerTitle': 'Facilite os pagamentos',
	'page.aboutUs.bannerDesc': 'Forneceremos serviços de pagamento para empresas em todo o mundo',
	'page.aboutUs.bannerBtn': 'Conta aberta',
	'page.aboutUs.item_01.title': 'Quem somos nós',
	'page.aboutUs.item_01.desc':
		'Somos uma organização dedicada a facilitar os pagamentos para os usuários. Nossa missão é trabalhar com você por muito tempo para desenvolver ferramentas de pagamento que melhor atendam às suas necessidades',
	'page.aboutUs.item_02.title': 'Curso de desenvolvimento',
	'page.aboutUs.item_02.desc_01':
		'Os pagamentos online na América Latina ainda estão numa fase inicial de desenvolvimento. Sempre tivemos a visão de desenvolver o ecossistema da Internet na América Latina para tornar a experiência de pagamento mais tranquila, estável e segura para os usuários.',
	'page.aboutUs.item_02.desc_02':
		'Ao mesmo tempo, também temos muitos recursos de tráfego no mercado local, por isso podemos fornecer serviços de gestão de dinheiro aos comerciantes, mas também ajudar os comerciantes a estabelecer uma sólida influência local.',
	'page.aboutUs.item_03.title': 'Somos a primeira escolha de um grande número de comerciantes',
	'page.aboutUs.item_03.desc':
		'Nosso excelente atendimento ao cliente conquistou um grande número de usuários no setor, ocupou a maior parte do market share no mercado brasileiro e é a primeira escolha para os comerciantes cooperarem.',
	'page.aboutUs.serves.title': 'ViatechBank atende o mercado global',
	'page.aboutUs.serves.desc_01': 'volume diário de pedidos: 30 milhões',
	'page.aboutUs.serves.desc_02': 'volume diário de pedidos: 50 milhões de pedidos',
	'page.payment.bannerDesc': 'pagamento',
	'page.payment.bannerTitle': 'Fornece soluções de pagamento em todo o mundo',
	'page.payment.item.desc_01':
		'Fornecemos métodos de pagamento eficientes e convenientes para simplificar suas transações financeiras. Com o banco viatech, você pode atender facilmente às suas necessidades de pagamento por transferência',
	'page.payment.item.desc_02':
		'Ao mesmo tempo, também temos muitos recursos de tráfego no mercado local, por isso podemos fornecer serviços de gestão de dinheiro aos comerciantes, mas também ajudar os comerciantes a estabelecer uma sólida influência local.',
	'page.payment.limit_01.title': 'fatura',
	'page.payment.limit_01.desc':
		'Insira o valor da compra na plataforma viatechbank para receber o pagamento dos clientes e gerar um código QR para pagamento. O valor arrecadado é cobrado em sua conta e pode ser liquidado em 7*24h para gerenciar seus fundos com flexibilidade',
	'page.payment.limit_02.title': 'pagamento',
	'page.payment.limit_02.desc':
		'Efetue pagamentos a clientes através da plataforma viatechbank O pagamento pode ser recebido em segundos, de forma mais cómoda e segura',
	'page.payment.limit_03.title': 'Gerenciamento de contas',
	'page.payment.limit_03.desc':
		'Oferecemos soluções personalizadas para o seu negócio. Fornece uma compreensão clara e intuitiva dos encargos de fundos da sua conta. Torne sua gestão de despesas mais clara e razoável.',
	'page.creditCard.contactUs': 'Entre em contato conosco para ter seu cartão de crédito exclusivo',
	'page.creditCard.contact.button': 'Contate-nos',
	'page.contactUs.bannerTitle': 'É aqui que você começa a abrir sua conta',
	'page.contactUs.bannerDesc': 'Comece sua jornada de pagamento',
	'page.contactUs.safe': 'Conveniente, seguro',
	'page.contactUs.service_time': 'Atendimento 7*24h',
	'page.contactUs.fnancial': '"Seu administrador financeiro premium"',
	'page.contactUs.form_service': '1. Qual serviço você precisa?',
	'page.contactUs.form_name': '2.  Seu nome:',
	'page.contactUs.form_phone': '3.  Seu número de telefone:',
	'page.contactUs.form_email': '4.  Seu email:',
	'page.contactUs.form_say': '5.  O que você gostaria de nos dizer?',
	'page.contactUs.submit': 'Enviar',
	'page.contactUs.placeholder_text': 'Por favor, insira',
	'page.contactUs.placeholder_select': 'Por favor selecione',
	'page.contactUs.options_create_account': 'Criar uma conta',
	'page.contactUs.options_currency': 'Conversão de fiat',
	'page.contactUs.options_credit_card': 'Cartão de crédito',
	'page.contactUs.options_answer': 'formato',
	'page.contactUs.options_feedback': 'Comentários comentários comentários',
	'page.fiatCurrencyExchange.title': 'Você pode negociar a qualquer hora, em qualquer lugar',
	'page.fiatCurrencyExchange.bannerDesc': 'Oferecemos câmbio global fiduciário e digital',
	'page.FiatCurrencyExchange.sliderList.title_1':
		'Taxa de câmbio em tempo real para reduzir o risco de volatilidade',
	'page.FiatCurrencyExchange.sliderList.desc_1':
		'Forneça informações atualizadas sobre taxas de câmbio em tempo real, para que você possa obter a taxa de câmbio mais precisa ao realizar transações internacionais, reduzindo o risco de flutuações nas taxas de câmbio',
	'page.FiatCurrencyExchange.sliderList.title_2': 'Apoie a moeda legal global, escolha flexível',
	'page.FiatCurrencyExchange.sliderList.desc_2':
		'Realize a conversão de moeda digital e moeda fiduciária em todo o mundo. Esteja você viajando, estudando no exterior ou negociando internacionalmente, você pode desfrutar da liberdade de pagar sem limites.',
	'page.FiatCurrencyExchange.sliderList.title_3': 'Professional customer service, 24/7 support',
	'page.FiatCurrencyExchange.sliderList.desc_3':
		'Nossa equipe profissional de atendimento ao cliente está online 24 horas por dia para fornecer suporte e serviço abrangentes. Não importa se você encontrar algum problema na transação, você pode entrar em contato conosco a qualquer momento, teremos o maior prazer em resolver para você.',
	'page.FiatCurrencyExchange.sliderList.title_4': 'Proteção de transações segura e confiável',
	'page.FiatCurrencyExchange.sliderList.desc_4':
		'Utilizamos tecnologias de segurança de ponta e medidas de criptografia para garantir que todas as transações transfronteiriças sejam seguras e protegidas. A plataforma possui um sistema abrangente de controle de risco para garantir a segurança de seus fundos.',
	'page.crossborder.crossBorderPayments': 'Pagamentos transfronteiriços',
	'page.crossborder.safe':
		'Seguro, eficiente e conveniente. Facilite suas transações internacionais!',
	'page.crossborder.viatechResponsible':
		'Viatech Responsável por todos os processos de pagamento cross-border: conversão de moeda, taxas de transação, taxas de câmbio e alinhamento com entidades nacionais.',
	'page.crossborder.weGuarantee':
		'Garantimos taxas de câmbio fixas, impostos ideais e contacto direto com parceiros locais 24 horas por dia.',
	'page.crossborder.tools': 'Ferramentas',
	'page.crossborder.weSupport': 'Apoiamos vários métodos de pagamento',
	'page.crossborder.sercure': 'Integração segura e criptografada',
	'page.crossborder.fully': 'Interface totalmente API',
	'page.crossborder.worldwide': 'Pagamentos em todo o mundo',
	'page.crossborder.crossborderPaymentSecurity': 'Segurança de pagamento transfronteiriço',
	'page.crossborder.weUseMulti-factor':
		'Utilizamos técnicas de autenticação multifatorial para identificar usuários e realizar transações legítimas, monitoramento de transações 24*7, gerenciamento de riscos e proteção contra fraudadores.',
	'page.digitalWallet.support': 'Apoiar',
	'page.digitalWallet.solve': 'Resolva o',
	'page.digitalWallet.cash': 'fluxo de caixa',
	'page.digitalWallet.expertise': 'Somos especialistas em',
	'page.digitalWallet.provide':
		'Fornece solução de fluxo de caixa conveniente, profissional e fácil de usar',
	'page.digitalWallet.userAccount': 'Conta de usuário',
	'page.digitalWallet.userAccountDesc':
		'Conta em várias moedas para ajudá-lo a resolver seus problemas de pagamento, recebimento e transferência vitalícia',
	'page.digitalWallet.merchantAccount': 'Conta de comerciante',
	'page.digitalWallet.merchantAccountDesc':
		'Ajudá-lo a resolver seus problemas de receita operacional',
	'page.digitalWallet.withdraw': 'retirar',
	'page.digitalWallet.withdrawDesc': 'Saque de dinheiro via PIX, leitura de código, transferência',
	'page.digitalWallet.top-up': 'completar',
	'page.digitalWallet.top-upDesc':
		'Recarregue dinheiro em contas em diferentes moedas, de forma segura e sob demanda',
	'page.digitalWallet.transferMoney': 'Transferir dinheiro',
	'page.digitalWallet.transferMoneyDesc': 'Transferência via código QR, simples e eficiente',
	'page.digitalWallet.hourService': 'Atendimento 7x24 horas',
	'page.digitalWallet.hourServiceDesc':
		'Atendimento ao cliente online para ajudá-lo a resolver dúvidas a tempo',
	'page.digitalWallet.yourPersonalWallet': 'Sua carteira pessoal',
	'page.digitalWallet.multiCurrencyAccount': 'Conta multimoeda',
	'page.digitalWallet.createYourOwnAccount': 'Crie sua própria conta, segura e fácil',
	'page.digitalWallet.justCompleteTheSimpleOperation':
		'Basta realizar a operação simples para criar sua própria conta, facilitando o pagamento, a transferência e o recebimento de dinheiro.',
	'page.digitalWallet.yourMerchantWallet': 'Sua carteira de comerciante',
	'page.digitalWallet.createYourOwnMerchantAccount':
		'Crie sua própria conta de comerciante e pague rapidamente',
	'page.digitalWallet.afterOpeningTheMerchantService':
		'Após abrir o serviço comercial, você pode criar sua própria conta comercial, tornando seu negócio mais conveniente.',
	'page.digitalWallet.scanCodeAndRecharge': 'Digitalize o código e recarregue',
	'page.digitalWallet.easyToEfficiently':
		'Fácil de recarregar dinheiro em sua conta com eficiência',
	'page.digitalWallet.pixCodeScanning': 'PIX, leitura de código, retirada de transferência',
	'page.digitalWallet.makeCashWithdrawal':
		'Torne a retirada de dinheiro mais conveniente e rápida para a conta',
	'page.digitalWallet.youCanEnterYourPIXKey':
		'Você pode inserir sua chave PIX, escanear o código QR gerado pelo pix e sacar dinheiro via Transferência, onde os saques PX são gratuitos e ocorrem em uma hora ou menos, inclusive nos finais de semana.',
	'page.digitalWallet.qrCodePayment': 'Pagamento e cobrança por código QR',
	'page.digitalWallet.transfersAreEasier': 'As transferências são mais fáceis e rápidas',
	'page.digitalWallet.youCanSetUpDIfferent':
		'Você pode configurar valores diferentes e gerar um código QR de transferência, que pode ser escaneado por outras pessoas após enviá-lo a elas.',
	'page.digitalWallet.merchantWallet': 'Carteira de comerciante',
	'page.button.openAccount': 'Conta aberta',
	'page.button.contactUs': 'Contact Us',
	'page.europe.solutions': 'Soluções para o mercado europeu',
	'page.europe.supportYourBusiness': 'Apoie o seu negócio com a nossa tecnologia mais avançada',
	'page.europe.theProspect': 'A perspectiva de pagamentos online na Europa',
	'page.europe.atPresent':
		'Actualmente, nos países europeus, o comércio electrónico, os campos de pagamento online, as carteiras electrónicas estão em expansão, apresentando métodos de pagamento diversificados',
	'page.europe.paymentMethods': 'Apoiamos vários métodos de pagamento',
	'page.europe.aOne-Click': 'Uma experiência de pagamento com um clique que conecta você ao mundo',
	'page.europe.withAGlobal':
		'Com uma rede global de pagamentos, soluções de pagamento diversificadas, tecnologia de segurança avançada e uma equipa dedicada de atendimento ao cliente, iremos ajudá-lo a destacar-se no altamente competitivo mercado europeu',
	'page.latin.latinAmericanMarket': 'Mercado latino-americano',
	'page.latin.onlinePayment': 'Perspectiva de pagamento online',
	'page.latin.inFastGrowing':
		'Na América Latina, em rápido crescimento, os pagamentos online estão a tornar-se um motor-chave da economia.',
	'page.latin.growth':
		'crescimento. Você pode aproveitar nossas soluções avançadas de pagamento para acompanhar isso',
	'page.latin.growthMarket': `mercado crescente. Junte-se a nós para uma nova era na economia digital da América Latina!`,
	'page.latin.paymentPreferencesBrazilian': `Preferências de pagamento dos consumidores brasileiros`,
	'page.latin.paymentPreferencesMexican': `Preferências de pagamento dos consumidores mexicanos`,
	'page.latin.seamless': `Integração perfeita de todos os métodos de pagamento`,
	'page.latin.weOfferGlobal': `Oferecemos serviços de pagamento globais. Oferecemos suporte a cartões de crédito, cartões de débito, carteiras eletrônicas ou transferências bancárias instantâneas.`,
	'page.latin.weOfferCustomized': `Oferecemos soluções personalizadas para garantir que seu processo de pagamento seja eficiente e tranquilo.`,
	'page.latin.contactUsNow': `Contate-nos agora para uma experiência de pagamento mais simples e flexível que o ajudará a se destacar da concorrência global!`,
	'page.faq.support': 'Apoiar',
	'page.faq.howCan': 'Como pode',
	'page.faq.weHelpYou': 'nós ajudamos você?',
	'page.faq.howToCreate': 'Como criar uma conta bancária',
	'page.faq.youCanContact':
		'Você pode entrar em contato conosco, nós o ajudaremos a abrir uma conta bancária, email: support@viatechbank.com',
	'page.faq.imNotFinding': `Não estou encontrando a forma de pagamento que desejo utilizar, como faço?`,
	'page.faq.Pagsmile': `O Pagsmile oferece quatro opções de formas de pagamento: Loteria, Boleto, Depósito Expresso e Cartão de Crédito. Porém, algumas lojas que utilizam o viatechbank podem optar por não utilizar todas elas, portanto você pode não encontrar a opção que deseja dentro do método viatechbank. Caso a sua loja preferida não ofereça todos os métodos de pagamento viatechbank, você pode entrar em contato diretamente com o suporte e dar sua opinião. :)`,
	'page.faq.myOrder':
		'Meu pedido aparece como pago na loja, mas não recebi meu produto. O que devo fazer?',
	'page.faq.viatechbankIsResponsibleForReceiving':
		'O viatechbank é responsável por receber os pagamentos e informar a loja quando eles forem aprovados, para que ela possa entregar o produto adquirido por você. Portanto, caso seu pedido seja aprovado, entre em contato diretamente com a loja para entender o que pode ter acontecido com a entrega do seu pedido.',
	'page.faq.howCanIMake': 'Como posso ter certeza de que tudo correrá bem com minha compra?',
	'page.faq.manyApproval':
		'Muitos problemas de aprovação podem ser evitados fornecendo um endereço de e-mail válido no momento do pagamento.',
	'page.faq.manyCustomers':
		'Muitos clientes, na hora da compra, informam algum e-mail na página da loja. Quando isso acontece, eles não recebem mais atualizações sobre a compra, como dados, valor, forma de pagamento, etc. Se algo der errado com o pagamento, é para este endereço de e-mail que as informações são enviadas, e caso o cliente não tiver acesso ao e-mail, ele deixa de ser informado.',
	'page.faq.anotherReason':
		'Outro motivo é que, muitas vezes dependendo da forma de pagamento que você escolheu, a instituição precisa verificar as informações da compra com o cliente, e ele avisa por e-mail. O cliente, não tendo acesso ao e-mail, não consegue fazer tal confirmação e então tem seu pedido cancelado, trazendo uma frustração desnecessária.',
	'page.faq.otherFactors':
		'Outros fatores a serem observados em sua compra é sempre verificar se você está na loja correta onde deseja comprar seu produto, verificar se está logado na conta correta (em casos de clientes que possuem mais de uma conta) e verifique novamente as informações colocadas na página da loja, evitando assim que os dados sejam colocados incorretamente. Muitos problemas de aprovação podem ser evitados fornecendo um endereço de e-mail válido no momento em que você efetua o pagamento.',
	'page.faq.iWantToCancel': 'Quero cancelar minha compra, como faço?',
	'page.faq.hereAtPagsmile':
		'Aqui na Pagsmile somos responsáveis ​​por receber os pagamentos e informar a loja quando eles forem aprovados, para que ela possa entregar o produto adquirido por você.',
	'page.faq.therefore':
		'Portanto, como não somos responsáveis ​​pela compra ou pelo produto, não temos autonomia para decidir quando uma compra pode ser cancelada e, neste caso, a única forma de fazer isso é diretamente com o suporte ao cliente da loja onde foi realizada a compra. foi feito.',
	'page.recruitment.viatechLooks': 'A Viatech espera que você se junte a nós',
	'page.recruitment.weLookForward':
		'Estamos ansiosos para conversar mais com você e explorar suas oportunidades de carreira em nossa empresa',
	'page.recruitment.weAreOrganization':
		'Somos uma organização dedicada a facilitar o pagamento dos usuários.',
	'page.recruitment.viatechBankHasA':
		'O ViatechBank possui um grande número de usuários locais e uma grande participação de mercado no Brasil. Oferecemos uma gama abrangente de soluções de pagamento globais online e offline aos nossos clientes globais.',
	'page.recruitment.jobDescription': 'Descrição do trabalho',
	'page.recruitment.recruitmentInformation': 'Não há informações de recrutamento...',
	'page.home.yourGlobal': 'Seu parceiro de pagamento global',
	'page.home.weProvide':
		'Oferecemos serviços de pagamento eficientes, seguros e convenientes em todo o mundo',
	'page.home.loremIpsumDolor':
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidente ut labore.',
	'page.home.instantTransfer': 'Transferência Instantânea',
	'page.home.paymentsWorldwide': 'Pagamentos em todo o mundo',
	'page.home.savingAccounts': 'Salvando contas',
	'page.home.mobileBanking': 'Banco 100% móvel',
	'page.home.internationalService': 'Serviço internacional',
	'page.home.provideInstantPayments':
		'Forneça pagamentos instantâneos na América Latina, Europa e outras regiões para ajudar as empresas a alcançar a expansão dos negócios em todos os lugares',
	'page.home.viatechbankApp': 'Aplicativo Viatechbank',
	'page.home.paymentTransfer':
		'Pagamento, transferência, PIX, cartão de débito, cartão de crédito, e-wallet, sempre tem uma para você',
	'page.home.makeYourMoney': 'Deixe seu dinheiro mais seguro',
	'page.home.cryptographic':
		'Vários algoritmos criptográficos e gerenciamento de controle de risco tornam seu dinheiro mais seguro',
	'page.home.serviceSupport': 'Suporte de serviço 7*24',
	'page.home.serviceSupportToAnswer':
		'Suporte de serviço 24 horas por dia, 7 dias por semana, para responder às suas perguntas a qualquer momento',
	'page.home.oneBanking': 'Um banco.',
	'page.home.payment': 'Pagamento',
	'page.home.paymentDesc':
		'Para fornecer a você o serviço global de recebimento e pagamento instantâneo',
	'page.home.global': 'Conversão global de moeda fiduciária e digital',
	'page.home.globalDesc': '7*24 Fornecer serviços de câmbio',
	'page.home.digitalWallet': 'Carteiras Digitais',
	'page.home.digitalWalletDesc': 'Resolva o problema do giro de capital',
	'page.home.crossBorderPayment': 'Pagamento transfronteiriço',
	'page.home.crossBorderPaymentDesc': 'Facilite a expansão do seu negócio global',
	'page.home.crditCard': 'Cartões de crédito e cartões de fronteira',
	'page.home.crditCardDesc': 'Compre agora, pague depois',
	'page.home.dashbord': 'Painel',
	'page.home.dashbordDesc': 'Facilite o gerenciamento do dinheiro',
	'page.home.creditCardService': 'Serviço de cartão de crédito',
	'page.home.allPayment':
		'Todas as operações de pagamento podem ser realizadas através de um telemóvel.',
	'page.shopcard.oneApp': 'Um aplicativo',
	'page.questions.anyOtherQuestions': 'Alguma outra dúvida?',
	'page.questions.weAreHereToHelp': 'Estamos aqui para ajudá-lo',
	'page.questions.support@viatechbank': 'suporte@viatechbank.com',
	'page.questions.supportEmail': 'E-mail de suporte',
	'page.businessSupport.viatechbankTornar':
		'Viatechbank Facilitando o gerenciamento do seu dinheiro',
	'page.businessSupport.supportManyBusiness':
		'Apoie muitas necessidades de negócios, apenas para oferecer o melhor serviço',
	'page.businessSupport.instant': 'Transações instantâneas',
	'page.businessSupport.secureAndFast': 'Seguro e rápido para facilitar o fluxo de dinheiro',
	'page.businessSupport.oneLump': 'Um pagamento único',
	'page.businessSupport.oneInterface': 'Uma interface, serviços globais',
	'page.businessSupport.cardServices': 'Serviços de cartão',
	'page.businessSupport.highCredit':
		'Altas linhas de crédito, baixas taxas de juros, disponibilidade mundial, seguro e controlado',
	'page.businessSupport.mobileBanking': 'Banco móvel',
	'page.businessSupport.oneMachine':
		'Com uma máquina em mãos, você pode desfrutar da liberdade de pagamento a qualquer hora e em qualquer lugar',
	'page.businessSupport.professional': 'Equipe técnica profissional',
	'page.businessSupport.deepPlowing':
		'Pagamento profundo, com rica experiência de pagamento, para fornecer um serviço profissional e eficiente.',
	'page.businessSupport.onlineCustomer': 'Atendimento ao cliente on-line',
	'page.businessSupport.providing': '7*24 horas, prestando serviço o tempo todo.',
	'page.dashbord.officeManagement': 'Sistema de gerenciamento de back office do banco Viatech',
	'page.dashbord.visual': 'Gestão visual de fundos',
	'page.dashbord.weGuarantee':
		'Garantimos dados confiáveis ​​e análises aprofundadas para facilitar a gestão das suas operações de pagamento',
	'page.dashbord.accurate': 'Estatísticas de dados precisas',
	'page.dashbord.control':
		'Controle seu próprio fluxo de dinheiro a qualquer momento através de nosso sistema',
	'page.dashbord.bestUser': 'Melhor experiência do usuário',
	'page.dashbord.prioritize':
		'Priorizando as necessidades do cliente, apenas para proporcionar uma experiência melhor',
	'page.dashbord.professional':
		'Equipe técnica profissional e sistema perfeito para rastrear seu negócio',
};
