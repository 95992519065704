import React, { Fragment } from 'react';

import { Outlet } from 'react-router-dom';

import './layout.scss';
import { Header } from '../../components/navbar';
import { Footer } from '../../components/footer';
export const Layout = () => {
	return (
		<Fragment>
			<Header />
			<main className="main-wrapper">
				<Outlet />
			</main>
			<footer className="footer">
				<Footer />
			</footer>
		</Fragment>
	);
};
