export default {
	'menu.lang.language': 'language',
	'menu.lang.en': 'English',
	'menu.lang.pt': 'Português',
	'menu.productAndService': 'Product And Service',
	'menu.support': 'Support',
	'menu.globalLayout': 'Global Layout',
	'menu.aboutUs': 'About us',
	'menu.payment': 'Payment',
	'menu.bankWallet': 'Bank wallet',
	'menu.creditCard': 'Credit cards',
	'menu.globalFiat': 'Global fiat and digital currency conversion',
	'menu.faq': 'FAQ',
	'menu.legal': 'Legal',
	'menu.career': 'Career',
	'menu.helpAndSupport': 'Help And Support',
	'menu.dashboard': 'Dashboard',
	'menu.cross-border': 'Cross-border payment',
	'menu.latinAmerica': '	Latin America',
	'menu.europe': 'Europe',
	'menu.button.createAccount': 'Create Account',
};
