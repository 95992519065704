import { useIntl } from 'react-intl';
import React from 'react';
const Legal = () => {
	const intl = useIntl();
	return (
		<div className="legal page-padding">
			<div id="en">
				<h1>PRIVACY POLICY</h1>
				<p>
					This particular instrument, <strong>"Privacy Policy"</strong>, regulates collection of
					personal data of users, known as "Holder", as well as the form of their processing by
					VIATECH BANK PROCESSADORA DE PAGAMENTO LTDA, payment institution, legal entity registered
					with the CNPJ under nº35.858.847/000196, referred to in this document as "Viatech".
				</p>
				<h2>INITIAL SETTINGS</h2>
				<ul>
					<li>
						Internet Protocol Address (IP Address): code assigned to an Internet Terminal 
						a network to allow their identification, defined according to international parameters;
					</li>
					<li>
						Internet: system consisting of a set of logical protocols, structured on a scale 
						worldwide for public and unrestricted use, with the purpose of enabling the communication of 
						data between terminals through different networks;
					</li>
					<li>
						Application: means by which the User accesses the services and content available by
						Viatech;
					</li>
					<li>
						User: any natural person, over 18 (eighteen) years old or emancipated and fully capable
						of carrying out acts of civil life or persons absolutely or relatively duly represented
						or assisted, who, meeting the criteria necessary and pre-established, will use the
						website or application;
					</li>
					<li>
						Data Protection Officer: is the professional who, within an Institution, is responsible
						for to take care of issues relating to the protection of the organization's data and its
						customers, in accordance with the provisions of Law n ̊13,709/2018. In his work, he helps
						the Institution adapt its processes to structure a compliance program focusing on
						greater security of information under its custody;
					</li>
					<li>
						Backlinks: Viatech websites are indicated ("linked*), not to be confused with a
						referral;
					</li>
					<li>
						Browser: Software whose primary function is to guarantee access to the virtual store
						interface and the Internet;
					</li>
					<li>
						Cookies: small files saved by websites on the hard drive of the person's computer user
						and which are intended to store the user's preferred information, such as such as
						usernames, favorite products and services and visited addresses;
					</li>
					<li>
						Domain: unique address on the internet that identifies, by the prefix www (world wide
						web) and by a certain suffix (for example, www.dominio.com.br), a website of a certain
						company or subject;
					</li>
					<li>
						Email: electronic  address  for  receiving  correspondence  and  information  in 
						digital  format (for  example,
						<a href="destinatario@dominio.com.br" target="_blank" rel="external nofollow noopener">
							destinatario@dominio.com.br
						</a>
						)
					</li>
					<li>
						Firewall:  software or  hardware  of  a  computer  network  that  has  by purpose 
						protect a network, controlling the flow of data between distinct networks and computers and 
						blocking unauthorized communications;
					</li>
					<li>Internet: global communication network between computers;</li>
					<li>
						Links:  virtual  addresses,  located  within  the  internet,  which  can  be  accessed 
						within the Portal (internal links) or outside it (external links);
					</li>
					<li>Online: via the internet;</li>
					<li>
						Offline: 
						outside the internet environment, that is, in the physical and tangible environment in which 
						we live;
					</li>
					<li>
						Portal: online  platform  that  allows  the  user  to  access  a  set of 
						services that, in turn, enable Viatech’s activity through the internet;
					</li>
					<li>
						Referral:  search  mechanism  through  which  the  Viatech  website  can,  and, 
						eventually, it is located;
					</li>
					<li>
						Spam: mass sending of unwanted and unsolicited correspondence (emails) 
						to an indeterminate number of recipients;
					</li>
					<li>
						SSL  (Secure  Sockets  Layer):  technology  (protocol)  security  standard  in 
						establishment of a secure and encrypted connection between a server and a client, 
						as for example between a website and a browser.
					</li>
				</ul>
				<h2>COOKIES</h2>
				<p>
					Cookies are small text files sent by websites to your browser, which 
					may or may not be stored in the browser directory of your device. These files 
					store and recognize data that guarantees the correct functioning of websites and applications, 
					as well as tracking your movements on these channels and helping you identify preferences and others 
					customization functions.
				</p>
				<h3>Examples of Cookies and their purposes are:</h3>
				<ul>
					<li>
						Functional:  guarantee  correct  access  and  functioning  of  applications  and 
						functionalities;
					</li>
					<li>
						Authentication: recognizes the user, enabling their access to restricted areas or 
						no, for the purpose of offering content, offers and services;
					</li>
					<li>
						Security: to assist in monitoring and detecting unauthorized activity, in  prevention 
						of  fraud  and  in  protecting  the  information of  members,  potential 
						associates, customers, partners and, also, of Viatech;
					</li>
					<li>
						Marketing: used to check, measure and analyze the audience, offer content 
						most relevant and of interest to the users of the channels, in addition to evaluating the performance of 
						promotional and advertising campaigns.
					</li>
				</ul>
				<p>
					You can agree to use Cookies when you visit this site for the first time, through 
					of the alert displayed on our home page. It is possible, at any time, to change or withdraw your 
					consent to the Cookie Statement on our website, by clicking on the “Cookie” icon, in the 
					bottom  of  the  screen.  However,  some  of  the  functions  and/or  contents  of  the 
					website  may  have  their  limited  functionality.
				</p>
				<h2>DATA COLLECTED AND PURPOSES</h2>
				<p>
					For  any  and  any  navigation  on  the  platform  made  available  by{' '}
					<strong>Viatech</strong>, at the moment of carrying
					out registrations, with the acquisition or not of any service or product 
					available, getting in touch through the official service channels or even even 
					just by participating in research and promotions, the
					<strong> Viatech</strong> may collect both personal and non-personal data.
				</p>
				<h2>
					The Personal Information, requested when carrying out registration by the user, via 
					Digital applications, in turn, include data that allows the individualization of 
					<strong>Holder</strong>
					at the 
					online and offline environment, through the collection of data such as name, email, telephone
					numbers, city and  status, data, these, to be provided by the <strong>Holder</strong>
					, with your consent, at the time of your pre-registration and/or acceptance.
				</h2>
				<h3>Personal information is collected whenever the holder:</h3>
				<ul>
					<p>
						a) Use the official applications of <strong>Viatech</strong>
						, intended for smartphones; 
					</p>
					<p>b) Request the contracting of any of our products or services;</p>
					<p>
						c) Browse the <strong>Viatech</strong> website;
					</p>
					<p>d) Get in contact with us through the official service channels;</p>
					<p>e) Participate in surveys or promotions organized by Viatech.</p>
				</ul>
				<h3>The data will also be stored, as delimited by the Legislation, of who:</h3>
				<ul>
					<p>a) He was our client;</p>
					<p>b) Performed transactions through the Institution or with one of our clients;</p>
					<p>
						w)
						Was or is a representative, attorney, guardian, trustee, employee or partner of some client, 
						any company or entity with which we maintain a relationship.
					</p>
				</ul>
				<p>
					The  data  collected,  as  well  as  its  treatment,  aim  to  meet 
					sufficiently for the purposes stipulated in this privacy policy and in the terms of use. They
					are  examples of personal data that may be collected by <strong>Viatech</strong>:
				</p>
				<ul>
					<p>
						a) Identification data, including full name, date of birth, activity  professional, 
						gender,  CPF,  ID  and  other  identification  documents,  state  civil, 
						nationality, place of birth, among others;
					</p>
					<p>
						b) Contact  and  location  information,  including  home  address  and  business
						address,  email  address,  home  and  work  telephone  number,  cell phone,
						among others;
					</p>
					<p>
						c) Information  related  to  third parties  linked  to  the  holder,  such  as 
						affiliation,  representatives,  guarantor,  attorney,  collaborator,  partner  or 
						beneficiary  of  product or  service, among others;
					</p>
					<p>d) Sensitive personal data, comprising biometric data (facial and/or digital);</p>
					<p>
						e) Usage  information  of  products  and  services  from,  e.g.  of 
						transactions and financial movements with <strong>Viatech</strong>;
					</p>
					<p>
						f) Financial data, including information related to the financial situation and  assets
						of the <strong>Holder</strong>;
					</p>
					<p>
						g) Information  relating  to  electronic,  device,  and  interaction  records  on the
						channels 
						officials, collecting identification data and use of devices, connection (date, time, 
						IP address, network used, among others), as well as information obtained from 
						the use of cookies on <strong>Viatech</strong>'s websites and applications
						. In addition to necessary information 
						the realization of specific functionalities, such as geolocation;
					</p>
					<p>
						h) Information  from  browsing  data,  media  and  social  networks,  such  as  pages 
						and  functionalities accessed on <strong>Viatech</strong>'s official channels
						Viatech, as well as the number of clicks;
					</p>
					<p>
						i) Research data, comprising the information, evaluations and opinions granted 
						on a voluntary manner during conducting research conducted by 
						<strong>Viatech</strong>
						Viatech, from  same way, when filling out forms in the relationship channels, central 
						of relationships and other available official channels;
					</p>
					<p>
						j) Access  data,  such  as  login  information  and  professional  information,  such
						as  CV,  professional  history  and  other  information,  made available  by  the Holder
						himself,  necessary  to  apply  for  professional  vacancies  published  by {''}
						<strong>Viatech</strong>.
					</p>
				</ul>
				<p>
					The information and data may be provided directly by the Holders on our  channels,  be 
					collected  as  a  result  of  the  provision  of  services  or  supplied  by sources 
					legitimate external partners, service providers, other institutions of  financial 
					system,  credit  bureaus,  credit information systems, public bodies,  in
					addition to sources of preventing money laundering and fraud.
				</p>
				<p>
					The data collected and processed is used by <strong>Viatech</strong>
					, in observance of the limits 
					of current legislation, especially with regards to the General Data Protection Law (LGPD), 
					for the following purposes:
				</p>
				<ul>
					<p>
						a)
						meet the interests and needs of the customer, potential customer, partners and others 
						interested third parties;
					</p>
					<p>
						b)
						Provide services, offer products and perform activities related to its performance in 
						national financial system;
					</p>
					<p>
						c)
						Keep the client's registration updated, as well as verifying their identity and enable 
						the access to products and services;
					</p>
					<p>
						d) Execute  contracts  signed  with  Viatech with  customers,  service  providers 
						service  and  partners;
					</p>
					<p>
						e) Promote  technical  and  security  improvements,  as  well  as  resolving  problems 
						related  to  the  themes  and  monitor  the  use  and  performance  of  the  products 
						and  services  made available;
					</p>
					<p>f) Comply with legal, regulatory and self-regulatory obligations;</p>
					<p>g) Enable Open Finance operations;</p>
					<p>
						h) Carry out investigations and take measures to prevent and combat illegal conducts, 
						fraudulent
						and financial crimes, aiming to guarantee the security of customers and the system 
						national financial;
					</p>
					<p>i) Comply with judicial, administrative and arbitration orders and decisions;</p>
					<p>
						j)
						Comply with the mandatory provisions provided for in the relevant legislation and regulations 
						the segment, activities and operations carried out by the institution;
					</p>
					<p>
						k) Promote  dissemination,  promotion,  research,  events,  sponsorships,  support and 
						any other marketing activities and communication with publics of interest;
					</p>
					<p>
						l) Develop statistics, studies, research and surveys relating to the segments and 
						the activities carried out by the Institution;
					</p>
					<p>
						m)
						Create, analyse, and improve products and services, as well as measure and understand the interaction 
						and  customer  satisfaction  with  the  services  provided,  relationship  channels, 
						social  networks, websites, applications and other tools necessary for the service.
					</p>
				</ul>
				<p>
					To  meet  the  purposes  envisaged  for  the  processing  of  collected  data,  as  well 
					as  complying  with  legislative  and  normative  determinations,  the 
					<strong>Viatech</strong> will be able to carry out the  sharing this data with third
					parties, partners, judicial, administrative, authorities,  regulatory,  governmental, 
					public  entities,  institutions  of  the  financial  system  and  regulatory 
					bodies, with strict observance of the needs and intended purposes.
				</p>
				<h3>The  main  possibilities  for  sharing  data  held  by  Viatech comprise:</h3>
				<ul>
					<p>Provision and offering of products and services;</p>
					<p>Improvement of the operational structure and offering of products and services;</p>
					<p>Marketing, prospecting, research and promotion of new products and services;</p>
					<p>
						Maintenance and improvement of the technological structure, as well as the resolution 
						of technical and/or security problems;
					</p>
					<p>Improvement and maintenance of the institution's websites and applications;</p>
					<p>
						Contact  by  telephone,  email,  SMS,  Whatsapp,  push  or  other  means  of 
						communication available;
					</p>
					<p>Enablement of Open Finance operations;</p>
					<p>
						Validation of identification information and documents, as well as application 
						of periodic evaluations;
					</p>
					<p>
						Compliance with legal and regulatory obligations, as well as for the exercise 
						regulated of rights;
					</p>
					<p>
						Research and development of tools, methodologies and other means 
						of illegality prevention.
					</p>
				</ul>
				<p>
					Personal Data may be transferred and processed in other countries, in accordance with 
					the hypotheses provided for in the LGPD and applicable legislation, in observance of the intended purposes 
					in this Privacy Policy.
				</p>
				<p>
					With the irreversible and irrevocable closing of the <strong>Holder</strong>
					's virtual account , for any 
					reason, the storage and processing of personal data may be maintained for a period 
					additional time of 10 (ten) years or for the exact mandatory prescription period of your right 
					of  action,  whichever  is  greater,  for  the  purposes  of  auditing  compliance  with 
					obligations  legal  or  regulatory, for the exercise 
					regular of rights or also for the necessary term in accordance  with  the  legal  basis 
					that  justify  the  retention  of  the  data,  for  example,  to  comply  with 
					provisions of the Central Bank of Brazil or for preservation of rights in case of lawsuit 
					of legal actions to the detriment of <strong>Viatech</strong> itself.
				</p>
				<h2>RIGHTS AND RESPONSIBILITIES OF THE HOLDER</h2>
				<h3>
					The General Data Protection Law (LGPD) guarantees the following rights to holders 
					of data:
				</h3>
				<ul>
					<p>a) Confirmation of the existence of treatment;</p>
					<p>b) Access to data;</p>
					<p>c) Correction of incomplete, inaccurate or outdated data;</p>
					<p>
						d) Anonymization, blocking, or elimination of unnecessary, excessive, or processed data 
						in noncompliance with the provisions in the LGPD;
					</p>
					<p>
						e) Portability of data to another service or product supplier, upon request  expressly, 
						in  accordance  with  the  regulations  of  the  national  authority,  observed 
						commercial and industrial secrets;
					</p>
					<p>
						f) Deletion  of  personal  data  processed  with  the  consent  of  the  holder, 
						except  in  the  hypotheses  provided  for  in  art. 16 of Law 13,709;
					</p>
					<p>g) Revocation of consent, under the terms of § 5 of art. 8th of Law 13,709. </p>
				</ul>
				<p>
					Pursuant to art. 41 of Law No. 13,709/2018 – General Data Protection Law (LGPD),  the
					<strong> Viatech</strong> has a data officer – Data Protection Officer (DPO). Through 
					the  email dpo@viatechbr.com.br,  the <strong>Holder</strong>
					You may  request  the  person in charge  to  analyze  your  request, listed above.
				</p>
				<h3>
					The <strong>Holder</strong> is assigned the following responsibilities:
				</h3>
				<ul>
					<p>
						a)
						for the actions or omissions performed by him during the use of the internet, the website, application 
						and other official Viatech channels, as well as through the content transmitted through 
						of these channels;
					</p>
					<p>
						b) the  compensation  of  any  and  any  damages,  direct  or  indirect,  that  may 
						arise  due  to  violations  of  rights,  such  as  those  of  property  intellectual, 
						secrecy  and  individuality, from others users or third
						parties, and that may affect Viatech and 
						whether due to failure to compliance with these Terms of Use and Privacy Policy or the 
						any action performed while accessing the internet, the website or the application.
					</p>
				</ul>
				<h2>GENERAL PROVISIONS</h2>
				<p>
					When  reading  this  Privacy  Policy  and  clicking,  at  the  end,  on  "I  have  read 
					and  am  aware  of  the  conditions  of  processing  of  my  personal  data  and  I 
					provide  my  consent, when applicable”, the Holder consents to the Processing of Personal
					Data mentioned in this Policy.
				</p>
				<p>
					The provisions stipulated in this may be changed whenever necessary, aimed at 
					the adequate compliance with current laws and regulations and, in the same way, to 
					the promotion of improvements and adaptations in Viatech’s privacy structure. 
				</p>
				<h3>
					Furthermore,  when  using  the  platforms,  applications  and  official  channels  of 
					the  Institution, it is  expressly  prohibited:
				</h3>
				<ul>
					<p>
						a)
						Make use of the site for illegal, illegal, fraudulent or harmful means, purposes, or activities 
						to  <strong>Viatech</strong>, as well as to carry out attacks that interfere  with  the 
						faithful  operation of platforms, applications and other available channels;
					</p>
					<p>
						b) Capture, through fraudulent artifices not allowed by law, data transmitted by  third
						parties between your personal computers and the connection and hosting servers of site;
					</p>
					<p>
						c)
						Under the terms of the Privacy Policy, using false data for access and registration on 
						<strong>Viatech's website platform;</strong>
					</p>
					<p>
						d) Make use of this site to copy, store, host, transmit, send, use, publish  or 
						distribute  any  materials  that  consist  of,  or  are  linked  to  spyware, 
						malware, computer viruses, keyloggers, rootkits or other malicious software;
					</p>
					<p>
						e) Perform systematic or automated data collection procedures, including 
						data scraping, data mining and data harvesting, without the express authorization of the Institution;
					</p>
					<p>
						f)
						Disseminate, for personal or commercial purposes, the contents of this website by means of sending
						in  bulk  (spam)  from  direct  mail,  by  email,  SMS,  telemarketing  or  others 
						mechanisms, for non-requesting or undetermined recipients;
					</p>
					<p>
						g) Use  in  the  registration  created  on  this  website  words  with  an  offensive 
						character,  discriminatory,  pejorative,  of  low  profanity  or  which  may,  by 
						reason  of  their  terminology, characterize apology for crime.
					</p>
				</ul>
				<p>
					The forum elected as the one competent to resolve any disagreements that may be 
					may occur as a result of compliance with this Privacy Policy, to the detriment of 
					any other, is that of the comarca of São PauloSP.
				</p>
			</div>
		</div>
	);
};

export default Legal;
