type Props = {};

const Compliance = (props: Props) => {
	return (
		<div className="legal page-padding">
			<div id="en">
				<h1>TERMS AND CONDITIONS OF USE</h1>
				<p>
					Term of Adhesion signed by the Holder, who at the end
					and electronically signs it, and the 
					VIATECH BANK PROCESSADORA DE PAGAMENTO LTDA, payment institution, 
					legal person registered with the CNPJ under no.
					35.858.847/000196, with headquarters at Avenida Doctor  Chucri  Zaidan,  nº1550,  Vila 
					São  Francisco,  São  Paulo/SP,  CEP:  04.711130,  henceforth 
					called Viatech, to regulate the rights and obligations of the parties in the contracting, use 
					and maintenance of the products, applications and services offered by Viatech to the Owner.
				</p>
				<h2>2. SPECIFIC TERMS AND CONDITIONS OF USE</h2>
				<p>
					2.1  To  make  use  of  the  online  services  available,  register  on this  website 
					and,  eventually,  manifest  the  autonomy  of  their  will  in  obtaining  transactional 
					account  with  the 
					Viatech, under the Terms and Conditions of Use presented here, the user, under the terms
					s 
					of Brazilian civil legislation (Civil Code of 2002), declares to be FULLY capable (articles 
					1st to 5th of Federal Law 10.406/02, Brazilian Civil Code).
				</p>
				<p>
					2.2 The official website of Viatech, accessible at the address 
					viatechbank.com.br, has the purpose 
					essential of offering information and tools for the financial assistance offered  by  the 
					Institution  to  potential  and  actual  customers  and,  eventually,  to   other
					interested  third parties.
				</p>
				<p>
					2.3  It  is  essential  that  the  user  reads,  understands  and  accepts  the 
					information  presented  here,  under  your  full  consent,  as  browsing  through 
					Viatech's  official  website  and  channels  will  matter  in  the  collection  of 
					personal  data  from  holders,  as  well  as  their  treatment, 
					considering the determined purposes of each product and/or service chosen by the holder. 
					This  consent  may  be  revoked  by  the  holder  upon  his  express  manifestation, 
					through the channels available and provided for in Viatech’s privacy policy.
				</p>
				<p>
					2.4 When accessing, using, browsing or purchasing on Viatech's website, application and other official channels, 
					The holder guarantees to
					have read, understood and accepted, in fullness, the Terms and Conditions 
					of Use of the Website described here, as well as its Privacy Policy.
				</p>
				<p>
					2.5 When requested, the Holder, a client of Viatech, shall
					Fill in the Account opening fields with  complete,  recent,  information.  valid  and 
					correct,  being  their  exclusive 
					responsibility to maintain all information provided to the Services, Products and Application 
					of Viatech permanently updated, so as to  always  reflect  your  data  real. 
					Viatech is not responsible for errors in the Customer’s registration.
				</p>
				<p>
					2.6 The Viatech Privacy Policy establishes the conditions under which the Institution performs 
					the treatment of
					personal data of the holder, customer or not, during the offering of the services, 
					products and application, as well as information about the rights guaranteed to the holder of these 
					data.
				</p>
				<p>
					2.7 The Terms and Conditions of Use of the website, application and other official channels of Viatech, your 
					content, its use, its writing, its access, its availability and the respective consent of 
					its terms by the user are governed and supported by the Federal Constitution (article 5, caput and 
					items XII, XXVII and LXXIX and art. 21, XXVI), by the General Data Protection Law, in its 
					completeness, Decree no. 11,491, of 12 April 2023, by the Brazilian Civil Marco (article 
					3rd, items I, II, III and V, article 7th, item I, II, III, VI, VII, VIII, items “a”, “b”
					and “c”, IX, XI, XIII, art. 
					article 11, §§§§ 1st, 2nd, 3rd and 4th, article 15, §§§§ 1st, 2nd, 3rd and 4th of federal law 12.965/14), 
					Regulatory decree of the Brazilian Civil Marco (Presidential Decree 8,771 of 11 May of 
					2016).
				</p>
				<p>
					2.8  The  Owner  may  exercise  their  rights,  including  the  deletion  and  correction 
					of data 
					incomplete, inaccurate, or outdated, to keep the information provided up-to-date and 
					in accordance, in observance of Viatech’s Privacy Policy.
				</p>
				<p>
					2.9 Viatech does not send email, SMS, Whatsapp or use other tools, which are not 
					those provided on its official website, to contact the Holder. Upon receiving any 
					unofficial communication, on name of Viatech, and suspicious
					of fraud, it is recommended that the Holder  do
					not open the attached files or click on any link or button. The Holder may send  a 
					message  to suporte@viatechbr.com.br,  reporting  the  situation,  so  that  the  The
					Institution's team can take the appropriate measures.
				</p>
				<h2>3. THE USE OF TRANSACTIONAL ACCOUNTS</h2>
				<p>
					3.1 By this instrument, the Customer, by requesting the opening of a Transactional Account with the 
					Viatech, declares full knowledge of the rules and conditions described below:
				</p>
				<ul>
					<p>
						a) As it is a transactional account, its movements will only take
						place through the means  official Viatech electronics;
					</p>
					<p>
						b)
						The Transactional Account is not to be confused with free movement current account, and it is not 
						proposes to offer or grant lines of credit or investment or movement  through checks;
					</p>
					<p>
						c)
						The opening and maintenance of the transactional account with Viatech is conditioned on 
						its evaluation of the registration information provided by the Customer, which may be opened 
						be rejected, by internal criteria, once the management conditions of the  Viatech;
					</p>
					<p>
						d) Viatech may, at its sole discretion, at the opening or during maintenance of 
						account,  demand  data,  documents  and  statements  that  it  deems  necessary  to 
						perfect 
						identification, qualification, location and knowledge of the Customer, in particular, but 
						not limited, to the information necessary to prevent and combat the laundering of 
						money and terrorist financing.
					</p>
					<p>
						e) The  refusal  of  the  Customer  to  provide  data,  information,  documents  or 
						statements requested by Viatech may, at its discretion, lead to not carrying out the 
						registration for opening the account and/or blocking and closing the account;
					</p>
					<p>
						f) Access  by  electronic  means  to  the  transactional  account  will  be  given 
						through  use of 
						ELECTRONIC PASSWORD for personal use and non-transferable, registered by this with the 
						APPLICATION, or by biometric identification;
					</p>
					<p>
						g) The transactions 
						financial generated by the Customer's initiative will only be completed 
						by affixing an effectiveness password, whose numeric sequential was previously  chosen 
						by  this  when  opening  the  account,  or  later  changed,  do not 
						holding Viatech responsible for any damages arising from the use of passwords  or  any 
						other  Customer  identification  mechanism  due  to  its  use  by  any  other  person 
						than  this,  even  if  authorized  by  him;
					</p>
					<p>
						h) Viatech will establish value and time limits for carrying out operations in 
						available electronic means or any other that may be made available,  in compliance with 
						the  regulations pertinent to the Institution, disclosed by  regulatory bodies.
					</p>
				</ul>
				<p>
					3.2 The Account opened with Viatech is personal and non-transferable, protected by a password created by 
					The customer itself, it is up to keep this password confidential, avoiding any inappropriate access 
					to your personal information. Furthermore, the Holder, a client of Viatech, is up to 
					responsibility  for the activities carried out in and with the account in its ownership.
				</p>
				<h3>3.3 The Customer declares to be aware of that:</h3>
				<ul>
					<p>
						a) Transactions made after the fixed time and/or with  value 
						higher than the established limit, as well as the release of withdrawals in terminals 
						electronic devices, including on weekends, holidays or at night, is
						conditioned on the existence of a
						balance, already deducted any debits programmed for the  first business day following;
					</p>
					<p>
						b) It will not be possible to cancel financial transactions carried
						out on your account by  electronic means, after confirmation of the operation;
					</p>
					<p>
						c) is  subject  to  charging  fees  on  the  services,  and  from  now  authorizes  to 
						Viatech  to 
						debit his account for those referring to products or services requested by him and/or 
						used or resulting from standards of the Central Bank of Brazil – BACEN, as per 
						Table of Rates made available by Viatech, in form of the current regulations;
					</p>
					<p>
						d) is  subject  to  and  authorizes  the  making  of  the  monthly  debit  in  the 
						value  of  the  package  of  contracted services, on your account;
					</p>
					<p>
						e) must observe and comply with the laws that provide for the prevention and combat of 
						crimes  of  money  laundering  and  financing  of  terrorism  and  the  rules 
						supplementary  published  by  the  Central  Bank  of Brazil (BACEN)  and  Council 
						Monetário Nacional
						(CMN) and, further, they are aware that Viatech, by force of these laws  and 
						standards,  is  obliged  to  communicate  to  the  competent  authorities  the 
						occurrence  of  operations provided for in the mentioned standards;
					</p>
					<p>
						f) Viatech may block, for the time necessary to investigate the fact, and/or close the 
						transactional account, in case of suspected fraud in the documentation or information 
						presented, as well as in case of noncompliance with the norms that govern the 
						preventing  and  combating  the  crimes  of  money  laundering  and  financial 
						financing  terrorism, and Viatech may adopt all the necessary procedures for the return 
						of resources eventually deposited to issuing banks, under the terms of the legislation and 
						regulations in force;
					</p>
					<p>
						g) The Parties are allowed, in a unilateral manner, to  request to close the account, 
						upon express manifestation, with a minimum advance of 30 (thirty) days;
					</p>
					<p>
						h)
						The account that remains 180 (one hundred and eighty) calendar days without movement, will
						be  considered inactive, which will lead to Viatech, the measures to begin the 
						closure process;
					</p>
					<p>
						i)
						They will also constitute reasons for termination and consequent cancellation/blocking of 
						account, in addition to others provided for in this Term:
						<ul>
							<li>
								Failure  to  comply  with  the  conditions  provided  for  in  this  term,  in  the 
								policy  of  privacy and  in other legal documents signed between Viatech and the 
								Client;
							</li>
							<li>
								The identification of the presentation of untrue information and/or absence of 
								information  required  from  the  Customer,  and  Viatech  may  carry out  prior 
								communication  to  the  Customer  for  the  regularization  of  the  observed 
								occurrence  for  avoid account closure;
							</li>
							<li>
								The intentional practice of any act or omission that, in any way, may have 
								competed  to  provide  for  themselves or  third parties,  illicit  advantages 
								arising from the improper use of the transactional Account; and
							</li>
							<li>
								Failure  to  provide  essential  information  to  comply  with  the  standards  of 
								Prevention and Lavag
							</li>
						</ul>
					</p>
				</ul>
				<h2>4 TERMS AND CONDITIONS WITH GOVERNING THE USE OF THE VIATECH WEBSITE</h2>
				<p>
					4.1  The  user,  when  registering,  sending  data,  exchanging  information  or  using 
					any 
					services on our website, act in accordance with the entirety of the Terms and Conditions 
					of Use described here.
				</p>
				<p>
					4.2. The website uses cookies. By using and accessing this domain, the user expresses their 
					consent to the use of cookies by Viatech, under the terms of its Privacy Policy.
				</p>
				<p>
					4.3. The limits and guarantees expressly provided for in these Terms and Conditions of Use are not 
					exclude or invalidate other rights and
					guarantees provided for in the Consumer Defense Code 
					(8.078/90), Civil Marco da Internet (12.965/14) and Brazilian Civil Code (Law 10.406/02).
				</p>
				<p>
					4.4. The Holder may, provided that the rules of
					these Terms and Conditions of Use are respected, 
					of Privacy Policy and legislation, publish and share the contents of this website on 
					social networks in which advertising is permitted and guaranteed.
				</p>
				<h2>5. LIMITS TO USABILITY OF THE VIATECH WEBSITE</h2>
				<p>
					5.1. Except for the inherent ownership of third
					parties, Viatech is the owner of the content and material 
					published on this website and the industrial property rights of this same content, such 
					such
					as brand and logo, being prohibited the use of any of these goods without express authorization.
				</p>
				<p>
					5.2. Viatech reserves the 
					whether the right to discontinue or change, partially or entirely, the 
					content and the services of this website, as well as its advertising, unilaterally, without notice 
					prior and at any time.
				</p>
				<p>
					5.3.  Viatech  reserves  the  right  to  suspend,  temporarily  or  definitely, the 
					registration and acceptance of new contracts when technical problems are found that prevent 
					the provision of your services within the minimum standards or when such measures are at your 
					indispensable interest.
				</p>
				<p>
					5.4. In the hypothesis of changing the
					Terms and Conditions of Use, the deadline for their entry into 
					validity will be 5 (five) days from the date of publication of the new document on the website.
				</p>
				<h3>5.5. Unless expressly authorized by Viatech, the Holder may not:</h3>
				<ul>
					<p>
						a) Capture, through fraudulent artifices not allowed by law, data transmitted by  third
						parties between your personal computers and the connection and hosting servers 
						the Viatech Website;
					</p>
					<p>
						b) Make  use  of  this  website  to  copy,  store,  host,  transmit,  send,  use, 
						publish or distribute any materials that consist of, or are linked to spyware, 
						malware,  computer  viruses,  computers,  Trojan  horses,  worms,  keyloggers, 
						rootkits  or  other malicious software;
					</p>
					<p>
						c) Perform systematic or automated data collection procedures, including 
						data scraping, data mining and data harvesting, without the express authorization of Viatech;
					</p>
					<p>
						d) Use  in  the  registration  created  on  this  website  words  with  an  offensive 
						character,  discriminatory,  pejorative,  of  low  profanity  or  which  may,  by 
						reason  of  their  terminology, characterize apology for crime.
					</p>
				</ul>
				<h2>6 VIATECH SERVICE CHANNELS </h2>
				<p>
					6.1.
					Viatech, aware of the importance of a direct communication channel with its customers, 
					has a service channel to clarify doubts, complaints and suggestions,  respecting  the 
					national  legislation,  in  particular  the  norms  provided  for  in  the  Code  of 
					Defense of 
					Consumer (Law 8078/1990), Brazilian Civil Code (Federal Law 10.406/02) and the Civil Marco 
					of the internet (12,965/14).
				</p>
				<p>
					6.2. Communication with Viatech can  be  carried out by email, telephone 
					landline and/or through the Viatech website.
				</p>
				<p>
					6.3. The opening hours of the communication channels, support and customer service 
					Viatech is from Monday to Friday, from 09:00 to 18:00.
				</p>
				<p>
					6.4. Viatech provides service modalities so that the (user) can choose 
					the most appropriate channel for the sanitation of your needs, found at the Center of 
					Relationship, through the link:
					<a href="www.viatechbank.com.br" target="_blank" rel="external nofollow noopener">
						{' '}
						www.viatechbank.com.br
					</a>
					. 
				</p>
				<h2>7 RESPONSIBLE  FOR THE PROCESSING OF PERSONAL DATA</h2>
				<p>
					7.1 Following the guidelines of Law No. 13,709/2018, 
					Viatech maintains the Protection structure
					of Data or DPO, which can be triggered in case of doubts related to compliance with 
					LGPD and other guidelines of the Privacy Policy and also of these Terms and Conditions of 
					usage, by email: dpo@viatechbr.com.br
				</p>
				<h2>8 FINAL DISPOSITIONS</h2>
				<p>
					8.1  The  user  is  responsible,  civilly  and  criminally,  for  the  misuse  or  by the
					use 
					undue use of data, videos, texts, illustrations, brands, images and other property of proprietary rights 
					intellectual or industrial property of the website of Viatech or of third parties whose services or 
					products are displayed on this website.
				</p>
				<p>
					8.2  The  images  of  service  providers  on  the  Viatech  website  have  goal 
					merely illustrative and does not represent the employee who, eventually, will provide the service
				</p>
				<p>
					8.3 The technical information  services provided by third
					parties, intermediated through the 
					website of Viatech, are their full responsibility (industry or production company or 
					supplier), Viatech is not held responsible for any error, mistake or disparity 
					in the descriptions in the products and services of these third parties.
				</p>
				<p>
					8.4 These Terms and Conditions of Use have been drafted and shall be interpreted in the language 
					Portuguese.
				</p>
				<p>
					8.5 The clauses and items provided here are governed by the legislation of the Federative Republic 
					from Brazil.
				</p>
				<p>
					8.6 The Terms and Conditions of Use and the Privacy Policy will be made available in a form 
					clear, adequate and ostensible.
				</p>
				<p>
					8.7 In the case of questions, suggestions or disagreement about these Terms and Conditions of Use, 
					o (a) Holder must contact Viatech through the following means of communication, during business hours 
					support and service, which is from Monday to Friday, from 09:00 to 18:00:
				</p>
				<p>
					email:
					<a href="suporte@viatechbr.com.br" target="_blank" rel="external nofollow noopener">
						 suporte@viatechbr.com.br
					</a>
				</p>
				{/* <p>b) telefone: xxxxxxxxxxxxxx</p> */}
				<p>
					8.9
					The competent forum for the resolution of any disputes is that of the consumer or that of the district 
					from São Paulo/SP.
				</p>
			</div>
		</div>
	);
};

export default Compliance;
