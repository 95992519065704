import enlang from '../locals/en-US/index'
import ptlang from '../locals/pt-BR/index'

const messages: Record<string, Record<string, string>> = {
	'en-US': {
    ...enlang
	},
	'pt-BR': {
   ...ptlang
	},
};

export default messages;
