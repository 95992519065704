import './aboutUs.scss';
import AboutBanner from '../../components/AboutBanner';
import ShopCard from '../../components/shopcard';
import Questions from '../../components/questions';

import aboutImg_01 from '../../asserts/images/aboutImg_01.png';
import aboutImg_02 from '../../asserts/images/aboutImg_02.png';
import aboutImg_03 from '../../asserts/images/aboutImg_03.png';
import servesImg from '../../asserts/images/serves-global.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { FadeIn } from '../../components/fadein';

type Props = {};

const AboutUs = (props: Props) => {
	const intl = useIntl();
	return (
		<div className="about-container">
			<section className="aboutBanner background-color-primary-light">
				<AboutBanner
					title={intl.formatMessage({
						id: 'page.aboutUs.bannerTitle',
						defaultMessage: 'Make payments easier',
					})}
					description={intl.formatMessage({
						id: 'page.aboutUs.bannerDesc',
						defaultMessage: 'We will provide payment services to companies all over the world',
					})}
				/>
			</section>
			<section className="about-section  page-padding">
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.aboutUs.item_01.title',
								defaultMessage: 'Who are we',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.aboutUs.item_01.desc',
								defaultMessage:
									'We are an organization dedicated to making payments easier for users. Our mission is to work with you for a long time to develop payment tools that better meet your needs',
							})}
						</div>
					</div>
					<div className="about-item-img">
						<img src={aboutImg_01} alt="" />
					</div>
				</FadeIn>
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.aboutUs.item_02.title',
								defaultMessage: 'Development course',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.aboutUs.item_02.desc_01',
								defaultMessage:
									'Online payments in Latin America are still at an early stage of development. We have always had a vision to develop the Latin American Internet ecosystem to make the payment experience smoother, more stable and more secure for users.',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.aboutUs.item_02.desc_02',
								defaultMessage:
									'At the same time, we also have a lot of traffic resources in the local market, so we can provide money management services for merchants, but also help merchants establish a solid local influence.',
							})}
						</div>
					</div>

					<div className="about-item-img">
						<img src={aboutImg_02} alt="" />
					</div>
				</FadeIn>
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.aboutUs.item_03.title',
								defaultMessage: 'We are the first choice of a large number of merchants',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.aboutUs.item_03.desc',
								defaultMessage:
									'Our excellent customer service has gained a large number of users in the industry, occupied most of the market share in the Brazilian market, and is the first choice for merchants to cooperate with.',
							})}
						</div>
					</div>
					<div className="about-item-img">
						<img src={aboutImg_03} alt="" />
					</div>
				</FadeIn>
			</section>
			<section className="serves-section background-color-primary-light page-padding">
				<FadeIn className="serves-content">
					<div className="serves-title">
						{intl.formatMessage({
							id: 'page.aboutUs.serves.title',
							defaultMessage: 'ViatechBank serves the global market',
						})}
					</div>
					<div className="serves-des">
						{intl.formatMessage({
							id: 'page.aboutUs.serves.desc_01',
							defaultMessage: 'daily order volume：30 million',
						})}
					</div>
					<div className="serves-des">
						{intl.formatMessage({
							id: 'page.aboutUs.serves.desc_02',
							defaultMessage: 'daily order volume: 50 million orders',
						})}
					</div>
				</FadeIn>
				<FadeIn className="serves-img">
					<img src={servesImg} alt="" />
				</FadeIn>
			</section>
			<section className="shop-section page-padding">
				<ShopCard />
			</section>
			<section className="question-section page-padding">
				<Questions />
			</section>
		</div>
	);
};

export default AboutUs;
