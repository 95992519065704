import { FadeIn } from '../../components/fadein';
import './creditCard.scss';
import creditCard from '../../asserts/images/credit-card-banner.png';
import limitIterm_01 from '../../asserts/images/limit-iterm-01.png';
import limitIterm_02 from '../../asserts/images/limit-iterm-02.png';
import limitIterm_03 from '../../asserts/images/limit-iterm-03.png';

import { FormattedMessage, useIntl } from 'react-intl';
type Props = {};

const CreditCard = (props: Props) => {
	const intl = useIntl();
	return (
		<div className="creditCard-container ">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container">
					<FadeIn className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.creditCard.bannerTitle',
								defaultMessage: 'Viatechbank credit card',
							})}
						</div>
						<div className="banner-des">
							{intl.formatMessage({
								id: 'page.creditCard.bannerDesc',
								defaultMessage:
									"The use of credit cards to advance consumption, or installment payment is now more and more people's mainstream payment methods",
							})}
						</div>
						<a href="/" className="button primary">
							<div className="button-inner">
								<div className="button-inner-text">
									<FormattedMessage
										id="menu.button.createAccount"
										defaultMessage="Create Account"
									/>
								</div>
								<div className="button-inner-text-hover">
									<FormattedMessage
										id="menu.button.createAccount"
										defaultMessage="Create Account"
									/>
								</div>
							</div>
						</a>
					</FadeIn>
					<div className="banner-img">
						<img className="in" src={creditCard} alt="" />
					</div>
				</div>
			</div>
			<section className="limit-section page-padding">
				<div className="limit-container">
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={limitIterm_01} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.creditCard.limitIterm_01.title',
								defaultMessage: 'High Credit Limit, Low Interest Rate',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.creditCard.limitIterm_01.desc',
								defaultMessage:
									'offers you a generous credit limit and ultralow interest rates, allowing you to easily manage large purchases and daily expenses, enjoying a worry-free cash flow experience.',
							})}
						</div>
					</FadeIn>
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={limitIterm_02} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.creditCard.limitIterm_02.title',
								defaultMessage: 'Global Acceptance, Hassle-Free Use',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.creditCard.limitIterm_02.desc',
								defaultMessage:
									'offers you a generous credit limit and ultralow interest rates, allowing you to easily manage large purchases and daily expenses, enjoying a worry-free cash flow experience.',
							})}
						</div>
					</FadeIn>
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={limitIterm_03} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.creditCard.limitIterm_03.title',
								defaultMessage: 'Secure and Worry-Free Usage',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.creditCard.limitIterm_03.desc',
								defaultMessage:
									'We provide comprehensive security measures to ensure every transaction is safe and worryfree.',
							})}
						</div>
					</FadeIn>
				</div>
			</section>
			<FadeIn>
				<section className="contact-section page-padding background-color-primary-light">
					<div className="conract-title">
						{intl.formatMessage({
							id: 'page.creditCard.contactUs',
							defaultMessage: 'Contact us to have your exclusive credit card',
						})}
					</div>
					<a href="/" className="button primary">
						<div className="button-inner">
							<div className="button-inner-text">
								<FormattedMessage id="page.creditCard.contact.button" defaultMessage="Contact Us" />
							</div>
							<div className="button-inner-text-hover">
								<FormattedMessage id="page.creditCard.contact.button" defaultMessage="Contact Us" />
							</div>
						</div>
					</a>
				</section>
			</FadeIn>
		</div>
	);
};

export default CreditCard;
