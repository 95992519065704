import React, { useEffect } from 'react';
import './index.scss';
import { useIntl } from 'react-intl';
import lawsbanner from '../../asserts/images/lawsbanner.png';
import { ConfigProvider, Tabs, TabsProps } from 'antd';
import Legal from '../../components/Legal';
import LegalPr from '../../components/LegalPr';
import ComplianceEn from '../../components/Compliance_En';
import CompliancePt from '../../components/Compliance_Pt';
import { useLanguage } from '../../config/LanguageContext';
const Laws = () => {
	const { locale } = useLanguage();
	const intl = useIntl();
	const onChange = (key: string) => {};

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: intl.formatMessage({
				id: 'page.laws.privacyPolicy',
				defaultMessage: 'PRIVACY POLICY',
			}),
			children: locale === 'en-US' ? <Legal /> : <LegalPr />,
		},
		{
			key: '2',
			label: intl.formatMessage({
				id: 'page.laws.termsAndConditionsOfUse',
				defaultMessage: 'TERMS AND CONDITIONS OF USE',
			}),
			children: locale === 'en-US' ? <ComplianceEn /> : <CompliancePt />,
		},
	];
	return (
		<div className="laws">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container support-hero-grid">
					<div className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.laws.lawsAndRegulations',
								defaultMessage: 'Laws and regulations',
							})}
						</div>
						{/* <div className="banner-des">
							{intl.formatMessage({
								id: 'page.laws.supportYourBusiness',
								defaultMessage: 'Support your business with our most advanced technology',
							})}
						</div>
						<div className="navbar-right">
							<a href="/" className="button primary small w-inline-block">
								<div className="button-inner">
									<div className="button-inner-text">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
									<div className="button-inner-text-hover">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
								</div>
							</a>
						</div> */}
					</div>
					<div className="banner-img in">
						<img src={lawsbanner} alt="" />
					</div>
				</div>
			</div>
			<div className="lawsContent">
				<ConfigProvider
					theme={{
						components: {
							Tabs: {
								/* 这里是你的组件 token */
								itemHoverColor: '#5BB5A2',
								itemSelectedColor: '#5BB5A2',
								inkBarColor: '#5BB5A2',
							},
						},
					}}
				>
					<Tabs
						defaultActiveKey="1"
						items={items}
						onChange={onChange}
						indicator={{ size: (origin) => origin - 60, align: 'start' }}
					/>
				</ConfigProvider>
			</div>
		</div>
	);
};
export default Laws;
