import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import blackcard from '../../asserts/images/premiumcard.png';
import greendcard from '../../asserts/images/freecard.png';
import './index.scss';
import { FadeIn } from '../../components/fadein';
import cardservices from '../../asserts/images/cardservices.png';
import instant from '../../asserts/images/Instant.png';
import onelump from '../../asserts/images/onelump.png';
import mobilebanking from '../../asserts/images/mobilebanking.png';
import professional from '../../asserts/images/professional.png';
import onlinecustomerservice from '../../asserts/images/onlinecustomerservice.png';
import Questions from '../../components/questions';
const BusinessSupport = () => {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const intl = useIntl();
	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
		const rect = e.currentTarget.getBoundingClientRect();
		const mouseX = e.clientX - rect.left;
		const mouseY = e.clientY - rect.top;
		const centerX = rect.width / 2;
		const centerY = rect.height / 2;
		const offsetX = (mouseX - centerX) / centerX;
		const offsetY = (mouseY - centerY) / centerY;

		setMousePosition({ x: offsetX, y: offsetY });
	};
	const handleMouseLeave = () => {
		setMousePosition({ x: 0, y: 0 });
	};
	return (
		<div className="businessSupport">
			<div
				className="banner-section  background-color-primary-light  page-padding"
				onMouseMove={handleMouseMove}
				onMouseLeave={handleMouseLeave}
			>
				<div className="banner-container support-hero-grid">
					<div className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.businessSupport.viatechbankTornar',
								defaultMessage: 'Viatechbank Making managing your money easier',
							})}
						</div>
						<div className="banner-des">
							{intl.formatMessage({
								id: 'page.businessSupport.supportManyBusiness',
								defaultMessage: 'Support many business needs, just to give you the best service',
							})}
						</div>
						<div className="navbar-right">
							<a href="/contactUs" className="button primary small w-inline-block">
								<div className="button-inner">
									<div className="button-inner-text">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
									<div className="button-inner-text-hover">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
								</div>
							</a>
						</div>
					</div>
					<div className="bankCordContentImg">
						<div className="imgContent">
							<div
								className="imgContentCord_1"
								style={{
									transform: `translate(${-mousePosition.x * 1 * 10}px, ${
										-mousePosition.y * 1 * 10
									}px)`,
								}}
							>
								<img src={blackcard} alt="" />
							</div>
							<div
								className="imgContentCord_2"
								style={{
									transform: `translate(${-mousePosition.x * -1 * 10}px, ${
										-mousePosition.y * -1 * 10
									}px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(14deg) skew(0deg, 0deg)`,
								}}
							>
								<img src={greendcard} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className="limit-section page-padding">
				<div className="limit-container">
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={instant} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.businessSupport.instant',
								defaultMessage: 'Instant transactions',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.businessSupport.secureAndFast',
								defaultMessage: 'Secure and fast to facilitate the flow of money',
							})}
						</div>
					</FadeIn>
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={onelump} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.businessSupport.oneLump',
								defaultMessage: 'One lump sum payment',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.businessSupport.oneInterface',
								defaultMessage: 'One interface, global services',
							})}
						</div>
					</FadeIn>
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={cardservices} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.businessSupport.cardServices',
								defaultMessage: 'Card Services',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.businessSupport.highCredit',
								defaultMessage:
									'High credit lines, low interest rates, worldwide availability, secure and controlled',
							})}
						</div>
					</FadeIn>
				</div>
				<div className="limit-container">
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={mobilebanking} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.businessSupport.mobileBanking',
								defaultMessage: 'Mobile banking',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.businessSupport.oneMachine',
								defaultMessage:
									'One machine in hand, you can enjoy payment freedom anytime, anywhere',
							})}
						</div>
					</FadeIn>
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={professional} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.businessSupport.professional',
								defaultMessage: 'Professional technical team',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.businessSupport.deepPlowing',
								defaultMessage:
									'Deep plowing payment, with rich payment experience, to provide professional and efficient service.',
							})}
						</div>
					</FadeIn>
					<FadeIn className="limit-iterm">
						<div className="limit-img">
							<img src={onlinecustomerservice} alt="" />
						</div>
						<div className="limit-title">
							{intl.formatMessage({
								id: 'page.businessSupport.onlineCustomer',
								defaultMessage: 'Online customer service',
							})}
						</div>
						<div className="limit-des">
							{intl.formatMessage({
								id: 'page.businessSupport.providing',
								defaultMessage: '7*24 hours, providing service all the time.',
							})}
						</div>
					</FadeIn>
				</div>
			</section>
			<FadeIn>
				<section className="question page-padding">
					<Questions />
				</section>
			</FadeIn>
		</div>
	);
};
export default BusinessSupport;
