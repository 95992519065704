import { Link, useLocation } from 'react-router-dom';

import logo from './Viatech_logo.png';
import downArrow from '../../asserts/images/downArrow.svg';
import './navbar.scss';
import { routes } from '../../config/routes';
import { useState, useRef } from 'react';
import langImg from '../../asserts/images/lang.png';
import { useLanguage } from '../../config/LanguageContext';
import { FormattedMessage } from 'react-intl';
export const Header = () => {
	const location = useLocation();
	const { pathname } = location;

	const [isHovering, setIsHovering] = useState(false);
	const [isToggle, setIsToggle] = useState(false);
	const [isAboutOpen, setIsAboutOpen] = useState(false);
	const [isSupportOpen, setIsSupportOpen] = useState(false);
	const [isMarketOpen, setIsMarketOpen] = useState(false);
	const [isLangOpen, setIsLangOpen] = useState(false);
	const aboutContentRef = useRef<HTMLUListElement>(null);
	const supportContentRef = useRef<HTMLUListElement>(null);
	const marketContentRef = useRef<HTMLUListElement>(null);
	const langContentRef = useRef<HTMLUListElement>(null);
	const { setLocale } = useLanguage();
	const [isLang, setIsLang] = useState(false);
	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};
	const MenuAboutList = () => {
		return (
			<div className="inner-sub-list">
				{/* <div className="navbar-dropdown-item sub-list-title">About us</div> */}
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/about';
					}}
				>
					<FormattedMessage id="menu.aboutUs" defaultMessage="About us" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/payment';
					}}
				>
					<FormattedMessage id="menu.payment" defaultMessage="Payment" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/digital_wallet';
					}}
				>
					<FormattedMessage id="menu.bankWallet" defaultMessage="Bank wallet" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/creditCard';
					}}
				>
					<FormattedMessage id="menu.creditCard" defaultMessage="Credit cards" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/fiatCurrencyExchange';
					}}
				>
					<FormattedMessage
						id="menu.globalFiat"
						defaultMessage="Global fiat and digital currency conversion"
					/>
				</div>
			</div>
		);
	};
	const MenuSupportList = () => {
		return (
			<div className="inner-sub-list">
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/faq';
					}}
				>
					<FormattedMessage id="menu.faq" defaultMessage="FAQ" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/laws';
					}}
				>
					<FormattedMessage id="menu.legal" defaultMessage="Legal" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/recruitment';
					}}
				>
					<FormattedMessage id="menu.career" defaultMessage="Career" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/business_support';
					}}
				>
					<FormattedMessage id="menu.helpAndSupport" defaultMessage="Help And Support" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/dashbord';
					}}
				>
					<FormattedMessage id="menu.dashboard" defaultMessage="Dashboard" />
				</div>
			</div>
		);
	};
	const MenuMarketList = () => {
		return (
			<div className="inner-sub-list">
				{/* <div className="navbar-dropdown-item sub-list-title">Market</div> */}

				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/cross_border';
					}}
				>
					<FormattedMessage id="menu.cross-border" defaultMessage="Cross-border payment" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/latin';
					}}
				>
					<FormattedMessage id="menu.latinAmerica" defaultMessage="Latin America" />
				</div>
				<div
					className="navbar-dropdown-item"
					onClick={() => {
						window.location.href = '/europe';
					}}
				>
					<FormattedMessage id="menu.europe" defaultMessage="Europe" />
				</div>
			</div>
		);
	};

	const MenuLangList = () => {
		return (
			<div className="inner-sub-list">
				<li
					className="navbar-dropdown-item"
					onClick={() => {
						setLocale('en-US');
					}}
				>
					<FormattedMessage id="menu.lang.en" defaultMessage="English" />
				</li>
				<li
					className="navbar-dropdown-item"
					onClick={() => {
						setLocale('pt-BR');
					}}
				>
					<FormattedMessage id="menu.lang.pt" defaultMessage="Portuguese" />
				</li>
			</div>
		);
	};
	return (
		<header className="header">
			<div className="header-wrapper">
				<section className="headbar">
					<div className="nav page-padding">
						<div className="navbar-left">
							<Link to={'/'}>
								<img src={logo} alt=""></img>
							</Link>
						</div>
						<nav className="navbar-center">
							<div className="navbar-menu">
								<div
									className="nav-item aboutUs"
									onMouseEnter={handleMouseOver}
									onMouseLeave={handleMouseOut}
								>
									<FormattedMessage
										id="menu.productAndService"
										defaultMessage="Product And Service"
									/>
									<div className="navbar-dropdown aboutUs-dropdown">
										<div className="navbar-dropdown-inner page-padding">
											<MenuAboutList />
										</div>
									</div>
								</div>
								<div
									className="nav-item support"
									onMouseEnter={handleMouseOver}
									onMouseLeave={handleMouseOut}
								>
									<FormattedMessage id="menu.support" defaultMessage="Support" />
									<div className="navbar-dropdown support-dropdown">
										<div className="navbar-dropdown-inner page-padding">
											<MenuSupportList />
										</div>
									</div>
								</div>
								<div
									className="nav-item market"
									onMouseEnter={handleMouseOver}
									onMouseLeave={handleMouseOut}
								>
									<FormattedMessage id="menu.globalLayout" defaultMessage="Global Layout" />
									<div className="navbar-dropdown market-dropdown">
										<div className="navbar-dropdown-inner page-padding">
											<MenuMarketList />
										</div>
									</div>
								</div>
							</div>
						</nav>
						<div className="navbar-right">
							<div
								className="langImg"
								onClick={() => {
									setIsLang(!isLang);
								}}
							>
								<img src={langImg} alt="" />
								<ul className={`langSelect ${isLang ? 'show' : null}`}>
									<MenuLangList />
								</ul>
							</div>
							<a href="/contactUs" className="button primary small w-inline-block">
								<div className="button-inner">
									<div className="button-inner-text">
										<FormattedMessage
											id="menu.button.createAccount"
											defaultMessage="Create Account"
										/>
									</div>
									<div className="button-inner-text-hover">
										<FormattedMessage
											id="menu.button.createAccount"
											defaultMessage="Create Account"
										/>
									</div>
								</div>
							</a>
						</div>
						<div className="navbar-right-mobile">
							<a href="/contactUs" className="button primary small w-inline-block">
								<div className="button-inner">
									<div className="button-inner-text">
										<FormattedMessage
											id="menu.button.createAccount"
											defaultMessage="Create Account"
										/>
									</div>
									<div className="button-inner-text-hover">
										<FormattedMessage
											id="menu.button.createAccount"
											defaultMessage="Create Account"
										/>
									</div>
								</div>
							</a>
							<div
								className={`mobile-menu-toggle ${isToggle ? 'is-toggle' : ''}`}
								onClick={() => {
									setIsToggle(!isToggle);
								}}
							>
								<div className="mobile-menu-toggle-line _01"></div>
								<div className="mobile-menu-toggle-line _02"></div>
								<div className="mobile-menu-toggle-line _03"></div>
							</div>
						</div>
					</div>
					<div className={`mobile-menu ${isToggle ? 'is-visible' : ''}`}>
						<div className="">
							<div className="mobile-menu-nav page-padding">
								<div className="mobile-dropdown-item">
									<div
										className="menu--text"
										onClick={() => {
											setIsAboutOpen(!isAboutOpen);
										}}
									>
										<div>
											<FormattedMessage
												id="menu.productAndService"
												defaultMessage="Product And Service"
											/>
										</div>
										<div className={`icon  ${isAboutOpen ? 'open' : ''}`}>
											<img src={downArrow} alt="" />
										</div>
									</div>
									<ul
										className={`mobile-dropdown-sub page-padding ${isAboutOpen ? 'open' : ''}`}
										ref={aboutContentRef}
										style={
											isAboutOpen
												? { maxHeight: aboutContentRef.current?.scrollHeight }
												: { maxHeight: 0 }
										}
									>
										<MenuAboutList />
									</ul>
								</div>
								<div className="mobile-dropdown-item">
									<div
										className="menu--text"
										onClick={() => {
											setIsSupportOpen(!isSupportOpen);
										}}
									>
										<div>
											<FormattedMessage id="menu.support" defaultMessage="Support" />
										</div>
										<div className={`icon  ${isSupportOpen ? 'open' : ''}`}>
											<img src={downArrow} alt="" />
										</div>
									</div>
									<ul
										className={`mobile-dropdown-sub page-padding ${isSupportOpen ? 'open' : ''}`}
										ref={supportContentRef}
										style={
											isSupportOpen
												? { maxHeight: supportContentRef.current?.scrollHeight }
												: { maxHeight: 0 }
										}
									>
										<MenuSupportList />
									</ul>
								</div>
								<div className="mobile-dropdown-item">
									<div
										className="menu--text"
										onClick={() => {
											setIsMarketOpen(!isMarketOpen);
										}}
									>
										<div>
											<FormattedMessage id="menu.globalLayout" defaultMessage="Global Layout" />
										</div>
										<div className={`icon  ${isMarketOpen ? 'open' : ''}`}>
											<img src={downArrow} alt="" />
										</div>
									</div>
									<ul
										className={`mobile-dropdown-sub page-padding ${isMarketOpen ? 'open' : ''}`}
										ref={marketContentRef}
										style={
											isMarketOpen
												? { maxHeight: marketContentRef.current?.scrollHeight }
												: { maxHeight: 0 }
										}
									>
										<MenuMarketList />
									</ul>
								</div>
								<div className="mobile-dropdown-item">
									<div
										className="menu--text"
										onClick={() => {
											setIsLangOpen(!isLangOpen);
										}}
									>
										<div>
											<FormattedMessage id="menu.lang.language" defaultMessage="Language" />
										</div>
										<div className={`icon  ${isLangOpen ? 'open' : ''}`}>
											<img src={downArrow} alt="" />
										</div>
									</div>
									<ul
										className={`mobile-dropdown-sub page-padding ${isLangOpen ? 'open' : ''}`}
										ref={langContentRef}
										style={
											isLangOpen
												? { maxHeight: langContentRef.current?.scrollHeight }
												: { maxHeight: 0 }
										}
									>
										<MenuLangList />
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className={`navbar-overlay ${isHovering ? 'is-visible' : ''}`}></div>
			<div className={`navbar-overlay  ${isToggle ? 'is-visible' : ''}`}></div>
		</header>
	);
};
export default Header;
