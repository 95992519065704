import React, { useEffect, useRef, useState } from 'react';
import './fiatCurrencyExchange.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import AboutBanner from '../../components/AboutBanner';
import sliderImg_01 from '../../asserts/images/FiatCurrencyExchange_slider_01.png';
import sliderImg_02 from '../../asserts/images/FiatCurrencyExchange_slider_02.png';
import sliderImg_03 from '../../asserts/images/FiatCurrencyExchange_slider_03.png';
import sliderImg_04 from '../../asserts/images/FiatCurrencyExchange_slider_04.png';
import { FadeIn } from '../../components/fadein';
type Props = {};

const FiatCurrencyExchange = (props: Props) => {
	const intl = useIntl();
	const sliderList = [
		{
			title: intl.formatMessage({
				id: 'page.FiatCurrencyExchange.sliderList.title_1',
				defaultMessage: 'Real-time exchange rate to reduce volatility risk',
			}),
			descriptions: intl.formatMessage({
				id: 'page.FiatCurrencyExchange.sliderList.desc_1',
				defaultMessage:
					'Provide real-time updated exchange rate information, so that you can get the most accurate exchange rate when conducting cross-border transactions, reducing the risk of exchange rate fluctuations',
			}),
			img: sliderImg_01,
		},
		{
			title: intl.formatMessage({
				id: 'page.FiatCurrencyExchange.sliderList.title_2',
				defaultMessage: 'Support global legal currency, flexible choice',
			}),
			descriptions: intl.formatMessage({
				id: 'page.FiatCurrencyExchange.sliderList.desc_2',
				defaultMessage:
					'Hold the conversion of digital currency and fiat currency worldwide. Whether you are traveling abroad, studying abroad, or trading internationally, you can enjoy the freedom to pay without boundaries.',
			}),
			img: sliderImg_02,
		},

		{
			title: intl.formatMessage({
				id: 'page.FiatCurrencyExchange.sliderList.title_3',
				defaultMessage: 'Professional customer service, 24/7 support',
			}),
			descriptions: intl.formatMessage({
				id: 'page.FiatCurrencyExchange.sliderList.desc_3',
				defaultMessage:
					'Our professional customer service team is online 24 hours a day to provide you with comprehensive support and service. No matter you encounter any problems in the transaction, you can contact us at any time, we will be happy to solve for you.',
			}),
			img: sliderImg_03,
		},

		{
			title: intl.formatMessage({
				id: 'page.FiatCurrencyExchange.sliderList.title_4',
				defaultMessage: 'Secure and reliable transaction protection',
			}),
			descriptions: intl.formatMessage({
				id: 'page.FiatCurrencyExchange.sliderList.desc_4',
				defaultMessage:
					'We use top security technologies and encryption measures to ensure that every cross-border transaction is safe and secure. The platform has a comprehensive risk control system to ensure the safety of your funds.',
			}),
			img: sliderImg_04,
		},
	];
	// 使用 useState 来存储当前图片的索引
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const currentImageIndexRef = useRef(currentImageIndex); // 使用 useRef 存储 currentImageIndex
	// 使用 useRef 来存储对每个 .content-left-iterm 元素的引用
	// 这里使用 (HTMLDivElement | null)[] 来定义它的类型
	const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
	const imgRefs = useRef<(HTMLImageElement | null)[]>([]);
	const previousY = useRef<number[]>([]); // 用于存储每个 item 的前一次 Y 坐标

	const [isUp, setIsUp] = useState(false);
	useEffect(() => {
		// 定义 IntersectionObserver 的选项
		const options = {
			root: null, // 根元素，null 表示使用视口作为根
			rootMargin: '0px', // 根元素的外边距
			// threshold: Array.from({ length: 21 }, (_, i) => (0.8 + i * 0.01).toFixed(2)).map(Number),
			threshold: 0.5,
		};

		// 定义 IntersectionObserver 的回调函数
		const callback = (entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const index = itemRefs.current.indexOf(entry.target as HTMLDivElement);
					if (index === -1) return; // 如果索引无效，则跳过
					const prevIndex = currentImageIndexRef.current;
					if (index !== prevIndex) {
						if (index > prevIndex) {
							setIsUp(false);
						} else {
							setIsUp(true);
						}
						currentImageIndexRef.current = index; // 更新当前索引
						setCurrentImageIndex(index); // 更新状态
					}
				}
			});
		};

		// 创建 IntersectionObserver 实例
		const observer = new IntersectionObserver(callback, options);

		// 观察所有 .content-left-iterm 元素
		itemRefs.current.forEach((item) => {
			if (item) observer.observe(item);
		});

		// 在组件卸载时取消观察所有元素
		return () => {
			itemRefs.current.forEach((item) => {
				if (item) observer.unobserve(item);
			});
		};
	}, []); // 空数组作为依赖项，确保 useEffect 只在组件挂载和卸载时运行
	useEffect(() => {
		// 当currentImageIndex改变时，这个useEffect会被触发
		sliderList.forEach((_, index) => {
			if (imgRefs.current[index]) {
				const imgElement = imgRefs.current[index];
				if (isUp) {
					switch (currentImageIndex) {
						case 2:
							const imgElementPre = imgRefs.current[3];
							if (imgElementPre) {
								imgElementPre.style.transform = `translate3d(0, 0, 0)`;
							}
							break;
						case 1:
							const imgElement_2 = imgRefs.current[2];
							if (imgElement_2) {
								imgElement_2.style.transform = `translate3d(0, 0, 0)`;
							}
							break;
						case 0:
							const imgElement_3 = imgRefs.current[1];
							if (imgElement_3) {
								imgElement_3.style.transform = `translate3d(0, 0, 0)`;
							}
							break;
						default:
							break;
					}
				} else {
					if (imgElement && currentImageIndex !== 0 && currentImageIndex === index) {
						imgElement.style.transform = `translate3d(-${currentImageIndex * 100}%, 0, 0)`;
					}
				}
			}
		});
	}, [currentImageIndex]); // 依赖项数组中只有currentImageIndex，因此只有它改变时才会触发此effect
	return (
		<div className="fiatCurrencyExchange">
			<section className="aboutBanner background-color-primary-light">
				<AboutBanner
					title={intl.formatMessage({
						id: 'page.fiatCurrencyExchange.title',
						defaultMessage: 'You can trade anytime, anywhere',
					})}
					description={intl.formatMessage({
						id: 'page.fiatCurrencyExchange.bannerDesc',
						defaultMessage: 'We offer global fiat and digital currency exchange',
					})}
				/>
			</section>
			<section className="slide-change-img slide-pc">
				<div className="page-padding">
					<div className="container-default">
						<div className="content">
							<div className="content-left">
								{sliderList.map((item, i) => {
									return (
										<FadeIn>
											<div
												className="content-left-iterm"
												ref={(el) => {
													itemRefs.current[i] = el;
												}}
												key={i}
											>
												<div className="item-title">{item.title}</div>
												<div className="item-desc">{item.descriptions}</div>
											</div>
										</FadeIn>
									);
								})}
							</div>
							<div className="content-right">
								<div className="content-right-inner">
									<div className="img-container">
										{sliderList.map((item, index) => {
											return (
												<img
													key={index}
													src={item.img}
													ref={(el) => {
														if (el) imgRefs.current[index] = el;
													}}
													style={
														index !== 0
															? {
																	transform: `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
																	transition: 'transform 0.7s ease',
															  }
															: {}
													}
													alt=""
												/>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      <section className="slide-change-img slide-H5">
				<div className="page-padding">
					<div className="container-default">
						<div className="content">
							<div className="content-left">
								{sliderList.map((item, i) => {
									return (
										<FadeIn>
                      <div className='h5-left-img'>
												<img src={item.img} alt="" />
                      </div>
											<div
												className="content-left-iterm"
											>
												<div className="item-title">{item.title}</div>
												<div className="item-desc">{item.descriptions}</div>
											</div>
										</FadeIn>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default FiatCurrencyExchange;
