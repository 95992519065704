import React from 'react';
import './index.scss';
import recruimentBanner from '../../asserts/images/recruitmentBanner.png';
import { FadeIn } from '../../components/fadein';
import Questions from '../../components/questions';
import weAreOrganization from '../../asserts/images/weAreOrganization.png';
import { useIntl } from 'react-intl';
const Recruitment = () => {
	const intl = useIntl();
	return (
		<div className="recruitment">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container support-hero-grid">
					<div className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.recruitment.viatechLooks',
								defaultMessage: 'Viatech looks forward to your joining us',
							})}
						</div>
						<div className="banner-des">
							{intl.formatMessage({
								id: 'page.recruitment.weLookForward',
								defaultMessage: `We look forward to speaking with you further and exploring your career opportunities
							with our company`,
							})}
						</div>
						<div className="navbar-right">
							<a href="/contactUs" className="button primary small w-inline-block">
								<div className="button-inner">
									<div className="button-inner-text">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
									<div className="button-inner-text-hover">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
								</div>
							</a>
						</div>
					</div>
					<div className="banner-img in">
						<img src={recruimentBanner} alt="" />
					</div>
				</div>
			</div>
			<section className="about-section  page-padding">
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.recruitment.weAreOrganization',
								defaultMessage:
									'We are an organization dedicated to making it easier for users to pay.',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.recruitment.viatechBankHasA',
								defaultMessage: `ViatechBank has a large number of local users and a large market share in Brazil. We
							offer a comprehensive range of online and offline global payment solutions to our
							global customers.`,
							})}
						</div>
					</div>
					<div className="about-item-img">
						<img src={weAreOrganization} alt="" />
					</div>
				</FadeIn>
				<div className="recruitment-item">
					<div className="recruitment-item-content">
						<FadeIn className="recruitment-item-title">
							{intl.formatMessage({
								id: 'page.recruitment.jobDescription',
								defaultMessage: 'Job description',
							})}
						</FadeIn>
						<div className="recruitment-item-des">
							<FadeIn className="recruitment-item-des-text">
								<p className="recruitment-item-des-text-paragraph">
									{intl.formatMessage({
										id: 'page.recruitment.recruitmentInformation',
										defaultMessage: `There is no recruitment information...`,
									})}
								</p>
							</FadeIn>
						</div>
						{/* <div className="recruitment-item-des">
							<FadeIn className="recruitment-item-des-title">
								{intl.formatMessage({
									id: 'page.recruitment.jobDescription',
									defaultMessage: 'Job description',
								})}
							</FadeIn>
							<FadeIn className="recruitment-item-des-text">
								{intl.formatMessage({
									id: 'page.recruitment.sedVulputate',
									defaultMessage: `Sed vulputate odio ut enim blandit volutpat maecenas. Sagittis orci a scelerisque
								purus semper eget duis at. Porta lorem mollis aliquam ut porttitor leo a diam. In
								fermentum et sollicitudin ac orci phasellus egestas tellus. Mauris cursus mattis
								molestie a iaculis at erat pellentesque adipiscing. Nascetur ridiculus mus mauris
								vitae ultricies. Dui nunc mattis enim ut tellus. Duis convallis convallis tellus id
								interdum. Quis ipsum suspendisse ultrices gravida dictum fusce. Scelerisque mauris
								pellentesque pulvinar pellentesque. Tincidunt augue interdum velit euismod. Nibh
								tortor id aliquet lectus. Amet commodo nulla facilisi nullam. Vulputate ut pharetra
								sit amet aliquam id diam maecenas. Tellus pellentesque eu tincidunt tortor. Ultrices
								vitae auctor eu augue ut lectus arcu bibendum.`,
								})}
							</FadeIn>
						</div> */}
					</div>
				</div>
				{/* <FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							A one-click payment experience that connects you to the world
						</div>
						<div className="about-item-des">
							With a global payment network, diversified payment solutions, advanced security
							technology and a dedicated customer service team, we will help you stand out in the
							highly competitive European market
						</div>
					</div>
					<div className="about-item-img">
						<img src={seamless} alt="" />
					</div>
				</FadeIn> */}
			</section>
			<FadeIn>
				<section className="question page-padding">
					<Questions />
				</section>
			</FadeIn>
		</div>
	);
};
export default Recruitment;
