import React from 'react';
import './index.scss';
import { useIntl } from 'react-intl';
import { FadeIn } from '../fadein';
const Questions = () => {
	const intl = useIntl();
	return (
		<FadeIn className="questions">
			<div className="text">
				<div className="textCenter">
					<p className="textQuestion">
						{intl.formatMessage({
							id: 'page.questions.anyOtherQuestions',
							defaultMessage: 'Any other questions?',
						})}
					</p>
					<p className="textHelp">
						{intl.formatMessage({
							id: 'page.questions.weAreHereToHelp',
							defaultMessage: `We're here to help you`,
						})}
					</p>
				</div>
			</div>
			<div className="contactBox">
				<div className="email">
					<div className="emailIcon"></div>
					<div className="emailText">
						<p>
							{intl.formatMessage({
								id: 'page.questions.support@viatechbank',
								defaultMessage: 'suporte@viatechbank.com',
							})}
						</p>
						<p>
							{intl.formatMessage({
								id: 'page.questions.supportEmail',
								defaultMessage: 'Support Email',
							})}
						</p>
					</div>
				</div>
				<div className="navbar-right">
					<a href="/contactUs" className="button primary small w-inline-block">
						<div className="button-inner">
							<div className="button-inner-text">
								{intl.formatMessage({
									id: 'page.button.contactUs',
									defaultMessage: 'Contact us',
								})}
							</div>
							<div className="button-inner-text-hover">
								{intl.formatMessage({
									id: 'page.button.contactUs',
									defaultMessage: 'Contact us',
								})}
							</div>
						</div>
					</a>
				</div>
			</div>
		</FadeIn>
	);
};
export default Questions;
