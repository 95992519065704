import React, { useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import { Layout } from './pages/layout';
import { routes } from './config/routes';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { useLanguage } from '../src/config/LanguageContext';
import messages from '../src/config/messages';

function App() {
	const { locale } = useLanguage() || 'en';
	// useEffect(() => {
	// }, [locale]);

	return (
		<IntlProvider locale={locale} messages={messages[locale]}>
			<div className="App">
				<React.StrictMode>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Layout />}>
								{routes.map((route) => (
									<Route key={route.path} path={route.path} element={route.element} />
								))}
							</Route>
						</Routes>
					</BrowserRouter>
				</React.StrictMode>
			</div>
		</IntlProvider>
	);
}

export default App;
