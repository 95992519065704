// LanguageContext.tsx
// import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// interface LanguageContextProps {
//   locale: string;
//   setLocale: (locale: string) => void;
// }

// const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

// export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
//   const [locale, setLocale] = useState<string>(localStorage.getItem('locale') || 'en');

//   useEffect(() => {
//     localStorage.setItem('locale', locale);
//   }, [locale]);

//   return (
//     <LanguageContext.Provider value={{ locale, setLocale }}>
//       {children}
//     </LanguageContext.Provider>
//   );
// };

// export const useLanguage = () => {
//   const context = useContext(LanguageContext);
//   if (!context) {
//     throw new Error('useLanguage must be used within a LanguageProvider');
//   }
//   return context;
// };
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
interface LanguageContextProps {
	locale: string;
	setLocale: (locale: string) => void;
	getLocale: () => string;
}
const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);
export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [locale, setLocale] = useState<string>(localStorage.getItem('locale') || 'en');
	useEffect(() => {
		localStorage.setItem('locale', locale);
	}, [locale]);
	const getLocale = () => locale;
	return (
		<LanguageContext.Provider value={{ locale, setLocale, getLocale }}>
			{children}
		</LanguageContext.Provider>
	);
};
export const useLanguage = () => {
	const context = useContext(LanguageContext);
	if (!context) {
		throw new Error('useLanguage must be used within a LanguageProvider');
	}
	return context;
};
