import React, { useEffect, useState } from 'react';
import Questions from '../../components/questions';
import './payment.scss';
import { FadeIn } from '../../components/fadein';
import goldImg from '../../asserts/images/goldcard.png';
import premiumImg from '../../asserts/images/premiumcard.png';
import freeImg from '../../asserts/images/freecard.png';
import pagmentImg from '../../asserts/images/about-iterm-des.png';
import limitIterm_01 from '../../asserts/images/payment-iterm-01.png';
import limitIterm_02 from '../../asserts/images/payment-iterm-02.png';
import limitIterm_03 from '../../asserts/images/payment-iterm-03.png';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {};

const Payment = (props: Props) => {
	const intl = useIntl();
	const [cord, setCord] = useState<boolean>(false);
	useEffect(() => {
		setTimeout(() => {
			setCord(true);
		}, 900);
	}, []);
	return (
		<div className="payment-container">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container support-hero-grid">
					<div className="banner-content in">
						<div className="banner-des">
							{intl.formatMessage({ id: 'page.payment.bannerDesc', defaultMessage: 'payment' })}
						</div>
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.payment.bannerTitle',
								defaultMessage: 'Provide payment solutions around the world',
							})}
						</div>
					</div>
					<div className="hero-cards-wrapper">
						<div className="headImg loaded">
							<img src={premiumImg} className="cord premiumImg" alt="" />
							<img src={goldImg} className={`cord ${cord ? 'goldImg' : null}`} alt="" />
							<img src={freeImg} className={`cord ${cord ? 'freeImg' : null}`} alt="" />
						</div>
					</div>
				</div>
			</div>
			<section className="about-section  page-padding">
				<FadeIn className="about-item in">
					<div className="about-item-content">
						<div className="about-item-des">
						{intl.formatMessage({
								id: 'page.payment.item.desc_01',
								defaultMessage: 'We provide you with efficient and convenient payment methods to make your financial transactions simpler. With viatech bank, you can easily meet your transfer payment needs',
							})}
							
						</div>
						<div className="about-item-des">
						{intl.formatMessage({
								id: 'page.payment.item.desc_02',
								defaultMessage: 'At the same time, we also have a lot of traffic resources in the local market, so we can provide money management services for merchants, but also help merchants establish a solid local influence.',
							})}
							
						</div>
					</div>
					<div className="about-item-img">
						<img src={pagmentImg} alt="" />
					</div>
				</FadeIn>
			</section>
			<section className="limit-section page-padding">
				<FadeIn className="limit-container in">
					<div className="limit-iterm">
						<div className="limit-img">
							<img src={limitIterm_01} alt="" />
						</div>
						<div className="limit-title">
						{intl.formatMessage({
								id: 'page.payment.limit_01.title',
								defaultMessage: 'invoice',
							})}
							</div>
						<div className="limit-des">
						{intl.formatMessage({
								id: 'page.payment.limit_01.desc',
								defaultMessage: 'Enter the purchase amount on the viatechbank platform to receive payment from customers and generate a QR code for payment. The amount collected is collected into your account and can be settled in 7*24h to manage your funds flexibly',
							})}
						</div>
					</div>
					<div className="limit-iterm">
						<div className="limit-img">
							<img src={limitIterm_02} alt="" />
						</div>
						<div className="limit-title">
						{intl.formatMessage({
								id: 'page.payment.limit_02.title',
								defaultMessage: 'payout',
							})}</div>
						<div className="limit-des">
						{intl.formatMessage({
								id: 'page.payment.limit_02.desc',
								defaultMessage: 'Make payments to customers using the viatechbank platform Payment can be received in seconds, more convenient and more secure',
							})}
						</div>
					</div>
					<div className="limit-iterm">
						<div className="limit-img">
							<img src={limitIterm_03} alt="" />
						</div>
						<div className="limit-title">
						{intl.formatMessage({
								id: 'page.payment.limit_03.title',
								defaultMessage: 'Bill management',
							})}
							</div>
						<div className="limit-des">
						{intl.formatMessage({
								id: 'page.payment.limit_03.desc',
								defaultMessage: 'We offer personalized solutions for your business. Give you a clear and intuitive understanding of your account fund charges. Make your expense management clearer and more reasonable.',
							})}
							
						</div>
					</div>
				</FadeIn>
			</section>
			<section className="question-section page-padding">
				<Questions />
			</section>
		</div>
	);
};

export default Payment;
