import { FadeIn } from '../../components/fadein';
import Questions from '../../components/questions';
import React from 'react';
import latinBanner from '../../asserts/images/latinBanner.png';
import onlineOayemnt from '../../asserts/images/onlinePayment.png';
import brazilianPay from '../../asserts/images/brazilianPay.png';
import mexicanPay from '../../asserts/images/mexicanPay.png';
import seamless from '../../asserts/images/seamless.png';
import './index.scss';
import { useIntl } from 'react-intl';
const Latin = () => {
	const intl = useIntl();
	return (
		<div className="latin">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container support-hero-grid">
					<div className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.latin.latinAmericanMarket',
								defaultMessage: 'Latin American market',
							})}
						</div>
						<div className="banner-des">
							{intl.formatMessage({
								id: 'page.europe.supportYourBusiness',
								defaultMessage: 'Support your business with our most advanced technology',
							})}
						</div>
						<div className="navbar-right">
							<a href="/contactUs" className="button primary small w-inline-block">
								<div className="button-inner">
									<div className="button-inner-text">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
									<div className="button-inner-text-hover">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
								</div>
							</a>
						</div>
					</div>
					<div className="banner-img in">
						<img src={latinBanner} alt="" />
					</div>
				</div>
			</div>
			<section className="about-section  page-padding">
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.latin.onlinePayment',
								defaultMessage: 'Online payment perspective',
							})}
						</div>
						<div className="about-item-des">
							<p>
								{intl.formatMessage({
									id: 'page.latin.inFastGrowing',
									defaultMessage:
										'In fast-growing Latin America, online payments are becoming a key driver of economic',
								})}
							</p>
							<p>
								{intl.formatMessage({
									id: 'page.latin.growth',
									defaultMessage: `growth. You can take advantage of our advanced payment solutions to keep up with
								this`,
								})}
							</p>
							<p>
								{intl.formatMessage({
									id: 'page.latin.growthMarket',
									defaultMessage: `growing market. Join us for a new era in Latin America's digital economy!`,
								})}
							</p>
						</div>
					</div>
					<div className="about-item-img">
						<img src={onlineOayemnt} alt="" />
					</div>
				</FadeIn>
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.crossborder.tools',
								defaultMessage: 'Tools',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.latin.paymentPreferencesBrazilian',
								defaultMessage: 'Payment preferences of Brazilian consumers',
							})}
						</div>
						<div className="latinPay">
							<img src={brazilianPay} alt="" />
						</div>
					</div>
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.crossborder.tools',
								defaultMessage: 'Tools',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.latin.paymentPreferencesMexican',
								defaultMessage: 'Payment preferences of Mexican consumers',
							})}
						</div>
						<div className="latinPay">
							<img src={mexicanPay} alt="" />
						</div>
					</div>
				</FadeIn>
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.latin.seamless',
								defaultMessage: 'Seamless integration of all payment methods',
							})}
						</div>
						<div className="about-item-des">
							<p>
								{intl.formatMessage({
									id: 'page.latin.weOfferGlobal',
									defaultMessage: `We offer global payment services. We support credit cards, debit cards, e-wallets or instant bank transfers. `,
								})}
							</p>
							<p>
								{intl.formatMessage({
									id: 'page.latin.weOfferCustomized',
									defaultMessage: `We offer customized solutions to ensure that your payment process is efficient and smooth.`,
								})}
							</p>
							<p>
								{intl.formatMessage({
									id: 'page.latin.contactUsNow',
									defaultMessage: `Contact us now for a simpler, more flexible payment experience that will help you stand out from the global competition!`,
								})}
							</p>
						</div>
					</div>
					<div className="about-item-img">
						<img src={seamless} alt="" />
					</div>
				</FadeIn>
			</section>
			<FadeIn>
				<section className="question page-padding">
					<Questions />
				</section>
			</FadeIn>
		</div>
	);
};
export default Latin;
