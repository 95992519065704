import React, { useState } from 'react';
import './index.scss';
import Questions from '../../components/questions';
import add from '../../asserts/images/addicon.png';
import faqBanner from '../../asserts/images/faqBanner.png';
import { useIntl } from 'react-intl';
const Faq = () => {
	const [setup, setSetup] = useState<boolean>(false);
	const [virtual, setVirtual] = useState<boolean>(false);
	const [extra, setExtra] = useState<boolean>(false);
	const [purchase, setPurchase] = useState<boolean>(false);
	const [cancel, setCancel] = useState<boolean>(false);
	const intl = useIntl();
	return (
		<div className="faq">
			<div className="faqHeader">
				<div className="headTitle loaded">
					<div className="supprt">
						<p>
							{intl.formatMessage({
								id: 'page.faq.support',
								defaultMessage: 'Support',
							})}
						</p>
					</div>
					<div className="solve">
						{intl.formatMessage({
							id: 'page.faq.howCan',
							defaultMessage: 'How can',
						})}
					</div>
					<div className="cash">
						{intl.formatMessage({
							id: 'page.faq.weHelpYou',
							defaultMessage: 'we help you?',
						})}
					</div>
				</div>
				<div className="headImg loaded">
					<img src={faqBanner} alt="" />
				</div>
			</div>
			<div className="faqContent page-padding">
				<div className="setupCard">
					<div
						className="setupCardTitle"
						onClick={() => {
							setSetup(!setup);
						}}
					>
						<div className="setupCardTitleText">
							{intl.formatMessage({
								id: 'page.faq.howToCreate',
								defaultMessage: 'How to create a bank account',
							})}
						</div>
						<div className={setup ? 'setupCardTitleIconActive' : 'setupCardTitleIcon'}>
							<img src={add} alt="" />
						</div>
					</div>
					<div className={setup ? 'setupCardDescActive' : 'setupCardDesc'}>
						{intl.formatMessage({
							id: 'page.faq.youCanContact',
							defaultMessage: `You can contact us, we will assist you to open a bank account,
						email:suporte@viatechbank.com`,
						})}
					</div>
				</div>
				<div className="virtualCard">
					<div
						className="virtualCardTitle"
						onClick={() => {
							setVirtual(!virtual);
						}}
					>
						<div className="virtualCardTitleText">
							{intl.formatMessage({
								id: 'page.faq.imNotFinding',
								defaultMessage: `I'm not finding the payment method I want to use, how do I do it?`,
							})}
						</div>
						<div className={virtual ? 'virtualCardTitleIconActive' : 'virtualCardTitleIcon'}>
							<img src={add} alt="" />
						</div>
					</div>
					<div className={virtual ? 'setupCardDescActive' : 'setupCardDesc'}>
						{intl.formatMessage({
							id: 'page.faq.Pagsmile',
							defaultMessage: `Pagsmile offers four options as payment methods: Lottery, Boleto, Express Deposit and
						Credit Card. However, some stores that use viatechbank may choose not to use all of
						them, so you may not find the option you want within the viatechbank method. If your
						favorite store does not offer all viatechbank payment methods, you can contact their
						support directly and give your opinion. :)`,
						})}
					</div>
				</div>
				<div className="extraCard">
					<div
						className="extraCardTitle"
						onClick={() => {
							setExtra(!extra);
						}}
					>
						<div className="extraCardTitleText">
							{intl.formatMessage({
								id: 'page.faq.myOrder',
								defaultMessage: `My order appears as paid at the store but I did not receive my product. What should I
							do?`,
							})}
						</div>
						<div className={extra ? 'extraCardTitleIconActive' : 'extraCardTitleIcon'}>
							<img src={add} alt="" />
						</div>
					</div>
					<div className={extra ? 'setupCardDescActive' : 'setupCardDesc'}>
						{intl.formatMessage({
							id: 'page.faq.viatechbankIsResponsibleForReceiving',
							defaultMessage: `viatechbank is responsible for receiving payments and informing the store when they are
						approved, so that it can deliver the product purchased by you. So, if your order is
						approved, contact the store directly to understand what may have happened with the
						delivery of your order.`,
						})}
					</div>
				</div>
				<div className="purchaseCard">
					<div
						className="purchaseCardTitle"
						onClick={() => {
							setPurchase(!purchase);
						}}
					>
						<div className="purchaseCardTitleText">
							{intl.formatMessage({
								id: 'page.faq.howCanIMake',
								defaultMessage: `How can I make sure that everything goes well with my purchase?`,
							})}
						</div>
						<div className={purchase ? 'purchaseCardTitleIconActive' : 'purchaseCardTitleIcon'}>
							<img src={add} alt="" />
						</div>
					</div>
					<div className={purchase ? 'setupCardDescActive' : 'setupCardDesc'}>
						<p>
							{intl.formatMessage({
								id: 'page.faq.manyApproval',
								defaultMessage: `Many approval problems can be avoided by providing a valid E-mail Address at the time
							you are making the payment.`,
							})}
						</p>
						<p>
							{intl.formatMessage({
								id: 'page.faq.manyCustomers',
								defaultMessage: `Many customers, at the time of purchase, inform any email on the store page. When this
							happens, they no longer receive updates about the purchase, such as data, value,
							payment method, etc. If anything goes wrong with the payment, it is to this email
							address that the information is sent, and if the customer does not have access to the
							email, he stops being informed.`,
							})}
						</p>
						<p>
							{intl.formatMessage({
								id: 'page.faq.anotherReason',
								defaultMessage: `Another reason is that, often depending on the payment method you have chosen, the
							institution needs to check purchase information with the customer, and they notify you
							by email. The customer, having no access to the e-mail, cannot make such confirmation
							and then has his order canceled, bringing an unnecessary frustration.`,
							})}
						</p>
						<p>
							{intl.formatMessage({
								id: 'page.faq.otherFactors',
								defaultMessage: `Other factors to watch out for in your purchase is to always check if you are in the
							correct store where you want to buy your product, check if you are logged in to the
							correct account (in cases of customers who have more than one account) and double
							check the information placed on the store page, thus preventing data from being placed
							incorrectly.Many approval problems can be avoided by providing a valid E-mail Address
							at the time you are making the payment.`,
							})}
						</p>
					</div>
				</div>
				<div className="cancelCard">
					<div
						className="cancelCardTitle"
						onClick={() => {
							setCancel(!cancel);
						}}
					>
						<div className="cancelCardTitleText">
							{intl.formatMessage({
								id: 'page.faq.iWantToCancel',
								defaultMessage: `I want to cancel my purchase, how do I do it?`,
							})}
						</div>
						<div className={cancel ? 'cancelCardTitleIconActive' : 'cancelCardTitleIcon'}>
							<img src={add} alt="" />
						</div>
					</div>
					<div className={cancel ? 'setupCardDescActive' : 'setupCardDesc'}>
						<p>
							{intl.formatMessage({
								id: 'page.faq.hereAtPagsmile',
								defaultMessage: `Here at Pagsmile we are responsible for receiving payments and informing the store
							when they are approved, so that it can deliver the product purchased by you.`,
							})}
						</p>
						<p>
							{intl.formatMessage({
								id: 'page.faq.therefore',
								defaultMessage: `Therefore, as we are not responsible for the purchase or the product, we have no
							autonomy to decide when a purchase can be canceled and, in this case, the only way to
							do this is directly with the customer support of the store where the purchase was
							made.`,
							})}
						</p>
					</div>
				</div>
			</div>
			<section className="question page-padding">
				<Questions />
			</section>
		</div>
	);
};
export default Faq;
