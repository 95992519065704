import React from 'react';
import './index.scss';
import walletTitleBanner from '../../asserts/images/walletTitleBanner.png';
import { FadeIn } from '../../components/fadein';
import { useIntl } from 'react-intl';
const ElectronicWallet = () => {
	const intl = useIntl();
	return (
		<div className="elcetronicWallet">
			<section className="elcetronicWalletContent">
				<div className="elcetronicWalletContentHead">
					<div className="headTitle textLoaded">
						<div className="supprt">
							<p>
								{intl.formatMessage({
									id: 'page.digitalWallet.support',
									defaultMessage: 'Support',
								})}
							</p>
						</div>
						<div className="solve">
							{intl.formatMessage({
								id: 'page.digitalWallet.solve',
								defaultMessage: 'Solve the',
							})}
						</div>
						<div className="cash">
							{intl.formatMessage({
								id: 'page.digitalWallet.cash',
								defaultMessage: 'cash flow',
							})}
						</div>
					</div>
					<div className="headImg loaded">
						<img src={walletTitleBanner} alt="" />
					</div>
				</div>
			</section>
			<section className="elcetronicWalletProvide">
				<div className="provideHeader">
					<div className="provideTitle">
						{intl.formatMessage({
							id: 'page.digitalWallet.expertise',
							defaultMessage: 'We Are Expertise In',
						})}
					</div>
					<div className="provideDesc">
						{intl.formatMessage({
							id: 'page.digitalWallet.provide',
							defaultMessage: 'Provide professional and easy to use, convenient cash flow solution',
						})}
					</div>
				</div>
				<div className="provideItem">
					<div className="provideItemBox">
						<div className="provideItemBoxUser"></div>
						<div className="provideItemBoxDesc">
							{intl.formatMessage({
								id: 'page.digitalWallet.userAccount',
								defaultMessage: 'User account',
							})}
							<div className="provideItemBoxContent">
								{intl.formatMessage({
									id: 'page.digitalWallet.userAccountDesc',
									defaultMessage:
										'Multi-currency account to help you solve your life payment, receipt, transfer problems',
								})}
							</div>
						</div>
					</div>
					<div className="provideItemBox">
						<div className="provideItemBoxMerchant"></div>
						<div className="provideItemBoxDesc">
							{intl.formatMessage({
								id: 'page.digitalWallet.merchantAccount',
								defaultMessage: 'Merchant account',
							})}
							<div className="provideItemBoxContent">
								{intl.formatMessage({
									id: 'page.digitalWallet.merchantAccountDesc',
									defaultMessage: 'Help you solve your operating income problems',
								})}
							</div>
						</div>
					</div>
					<div className="provideItemBox">
						<div className="provideItemBoxWithdraw"></div>
						<div className="provideItemBoxDesc">
							{intl.formatMessage({
								id: 'page.digitalWallet.withdraw',
								defaultMessage: 'withdraw',
							})}
							<div className="provideItemBoxContent">
								{intl.formatMessage({
									id: 'page.digitalWallet.withdrawDesc',
									defaultMessage: 'Cash withdrawal through PIX, code scanning, Transfer',
								})}
							</div>
						</div>
					</div>
				</div>
				<div className="provideItem">
					<div className="provideItemBox">
						<div className="provideItemBoxTopUp"></div>
						<div className="provideItemBoxDesc">
							{intl.formatMessage({
								id: 'page.digitalWallet.top-up',
								defaultMessage: 'top-up',
							})}
							<div className="provideItemBoxContent">
								{intl.formatMessage({
									id: 'page.digitalWallet.top-upDesc',
									defaultMessage:
										'Top up cash to accounts in different currencies, securely and on demand',
								})}
							</div>
						</div>
					</div>
					<div className="provideItemBox">
						<div className="provideItemBoxTransfer"></div>
						<div className="provideItemBoxDesc">
							{intl.formatMessage({
								id: 'page.digitalWallet.transferMoney',
								defaultMessage: 'Transfer money',
							})}
							<div className="provideItemBoxContent">
								{intl.formatMessage({
									id: 'page.digitalWallet.transferMoneyDesc',
									defaultMessage: 'Transfer via QR code, simple and efficient',
								})}
							</div>
						</div>
					</div>
					<div className="provideItemBox">
						<div className="provideItemBoxService"></div>
						<div className="provideItemBoxDesc">
							{intl.formatMessage({
								id: 'page.digitalWallet.hourService',
								defaultMessage: '7x24 hours service',
							})}
							<div className="provideItemBoxContent">
								{intl.formatMessage({
									id: 'page.digitalWallet.hourServiceDesc',
									defaultMessage: 'Online customer service to help you solve questions in time',
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="elcetronicWalletDetails">
				<div className="detailsContent page-padding">
					<FadeIn className="detailsWallet">
						<div className="detailsWalletLeft">
							<div className="detailsWalletDesc">
								<div className="detailsWalletDescIcon"></div>
								<div className="detailsWalletDescText">
									<div className="detailsWalletDescPersonal">
										{intl.formatMessage({
											id: 'page.digitalWallet.yourPersonalWallet',
											defaultMessage: 'Your personal wallet',
										})}
									</div>
									<div className="detailsWalletDescMulti">
										{intl.formatMessage({
											id: 'page.digitalWallet.multiCurrencyAccount',
											defaultMessage: 'Multi-currency account',
										})}
									</div>
								</div>
							</div>
							<div className="detailsWalletWord">
								<div className="detailsWalletWordTitle">
									{intl.formatMessage({
										id: 'page.digitalWallet.createYourOwnAccount',
										defaultMessage: 'Create your own account, secure and easy',
									})}
								</div>
								<div className="detailsWalletWordText">
									{intl.formatMessage({
										id: 'page.digitalWallet.justCompleteTheSimpleOperation',
										defaultMessage: `Just complete the simple operation to create your own account, making it easy to
									pay, transfer and receive money.`,
									})}
								</div>
							</div>
						</div>
						<div className="detailsWalletRight"></div>
					</FadeIn>
					<FadeIn className="detailsMerchant">
						<div className="detailsMerchantLeft"></div>
						<div className="detailsMerchantRight">
							<div className="detailsMerchantDesc">
								<div className="detailsMerchantDescIcon"></div>
								<div className="detailsMerchantDescText">
									<div className="detailsMerchantDescPersonal">
										{intl.formatMessage({
											id: 'page.digitalWallet.yourMerchantWallet',
											defaultMessage: 'Your merchant wallet',
										})}
									</div>
									<div className="detailsMerchantDescMulti">
										{intl.formatMessage({
											id: 'page.digitalWallet.merchantAccount',
											defaultMessage: 'Merchant account',
										})}
									</div>
								</div>
							</div>
							<div className="detailsMerchantWord">
								<div className="detailsMerchantWordTitle">
									{intl.formatMessage({
										id: 'page.digitalWallet.createYourOwnMerchantAccount',
										defaultMessage: 'Create your own merchant account and pay quickly',
									})}
								</div>
								<div className="detailsMerchantWordText">
									{intl.formatMessage({
										id: 'page.digitalWallet.afterOpeningTheMerchantService',
										defaultMessage: `After opening the merchant service, you can create your own merchant account,
									making your business more convenient.`,
									})}
								</div>
							</div>
						</div>
					</FadeIn>
					<FadeIn className="detailsTopUp">
						<div className="detailsTopUpLeft">
							<div className="detailsTopUpDesc">
								<div className="detailsTopUpDescIcon"></div>
								<div className="detailsTopUpDescText">
									<div className="detailsTopUpDescPersonal">
										{intl.formatMessage({
											id: 'page.digitalWallet.top-up',
											defaultMessage: 'top-up',
										})}
									</div>
									<div className="detailsTopUpDescMulti">
										{intl.formatMessage({
											id: 'page.digitalWallet.scanCodeAndRecharge',
											defaultMessage: 'Scan code and recharge',
										})}
									</div>
								</div>
							</div>
							<div className="detailsTopUpWord">
								<div className="detailsTopUpWordTitle">
									{intl.formatMessage({
										id: 'page.digitalWallet.easyToEfficiently',
										defaultMessage: 'Easy to efficiently top up cash to your account',
									})}
								</div>
								<div className="detailsTopUpWordText">
									{intl.formatMessage({
										id: 'page.digitalWallet.afterSuccessfullyCreatingAnAccount',
										defaultMessage: `After successfully creating an account, you can deposit cash into your account,
									eliminating the complicated payment process and making it safe and easy to usevv`,
									})}
								</div>
							</div>
						</div>
						<div className="detailsTopUpRight"></div>
					</FadeIn>
					<FadeIn className="detailsWithdraw">
						<div className="detailsWithdrawLeft"></div>
						<div className="detailsWithdrawRight">
							<div className="detailsWithdrawDesc">
								<div className="detailsWithdrawDescIcon"></div>
								<div className="detailsWithdrawDescText">
									<div className="detailsWithdrawDescPersonal">
										{intl.formatMessage({
											id: 'page.digitalWallet.withdraw',
											defaultMessage: 'withdraw',
										})}
									</div>
									<div className="detailsWithdrawDescMulti">
										{intl.formatMessage({
											id: 'page.digitalWallet.pixCodeScanning',
											defaultMessage: 'PIX, code scanning, Transfer withdrawal',
										})}
									</div>
								</div>
							</div>
							<div className="detailsWithdrawWord">
								<div className="detailsWithdrawWordTitle">
									{intl.formatMessage({
										id: 'page.digitalWallet.makeCashWithdrawal',
										defaultMessage: 'Make cash withdrawal more convenient and fast to the account',
									})}
								</div>
								<div className="detailsWithdrawWordText">
									{intl.formatMessage({
										id: 'page.digitalWallet.youCanEnterYourPIXKey',
										defaultMessage: `You can enter your PIX key, scan the QR code generated by pix, and withdraw cash
									via Transfer, where PX withdrawals are free and take place in an hour or less,
									even on weekends.`,
									})}
								</div>
							</div>
						</div>
					</FadeIn>
					<FadeIn className="detailsTransfer">
						<div className="detailsTransferLeft">
							<div className="detailsTransferDesc">
								<div className="detailsTransferDescIcon"></div>
								<div className="detailsTransferDescText">
									<div className="detailsTransferDescPersonal">
										{intl.formatMessage({
											id: 'page.digitalWallet.transferMoney',
											defaultMessage: 'Transfer money',
										})}
									</div>
									<div className="detailsTransferDescMulti">
										{intl.formatMessage({
											id: 'page.digitalWallet.qrCodePayment',
											defaultMessage: 'Qr code payment and collection',
										})}
									</div>
								</div>
							</div>
							<div className="detailsTransferWord">
								<div className="detailsTransferWordTitle">
									{intl.formatMessage({
										id: 'page.digitalWallet.transfersAreEasier',
										defaultMessage: 'Transfers are easier and faster',
									})}
								</div>
								<div className="detailsTransferWordText">
									{intl.formatMessage({
										id: 'page.digitalWallet.youCanSetUpDIfferent',
										defaultMessage: `You can set up different amounts and generate a transfer QR code, which can be
									scanned by others after you send it to them.`,
									})}
								</div>
							</div>
						</div>
						<div className="detailsTransferRight"></div>
					</FadeIn>
					<FadeIn className="detailsService">
						<div className="detailsServiceLeft"></div>
						<div className="detailsServiceRight">
							<div className="detailsServiceDesc">
								<div className="detailsServiceDescIcon"></div>
								<div className="detailsServiceDescText">
									<div className="detailsServiceDescPersonal">
										{intl.formatMessage({
											id: 'page.digitalWallet.yourMerchantWallet',
											defaultMessage: 'Your merchant wallet',
										})}
									</div>
									<div className="detailsServiceDescMulti">
										{intl.formatMessage({
											id: 'page.digitalWallet.merchantWallet',
											defaultMessage: 'Merchant wallet',
										})}
									</div>
								</div>
							</div>
							<div className="detailsServiceWord">
								<div className="detailsServiceWordTitle">
									{intl.formatMessage({
										id: 'page.digitalWallet.createYourOwnMerchantAccount',
										defaultMessage: 'Create your own merchant account and pay quickly',
									})}
								</div>
								<div className="detailsServiceWordText">
									{intl.formatMessage({
										id: 'page.digitalWallet.afterOpeningTheMerchantService',
										defaultMessage: `After opening the merchant service, you can create your own merchant account,
									making your business more convenient.`,
									})}
								</div>
							</div>
						</div>
					</FadeIn>
				</div>
			</div>
		</div>
	);
};
export default ElectronicWallet;
