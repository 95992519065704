import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';

type FadeInProps = {
	className?: string;
	style?: React.CSSProperties;
	children: React.ReactNode;
};
export const FadeIn = (props: FadeInProps) => {
	const [current, setCurrent] = useState(false);
	return (
		<InView>
			{({ inView, ref, entry }) => (
				<>
					<div className={`${props.className} ${inView ? 'fadeIn' : ''}`} ref={ref}>
						{props.children}
					</div>
				</>
			)}
		</InView>
	);
};
