export default {
	'page.creditCard.bannerTitle': 'Viatechbank credit card',
	'page.creditCard.bannerDesc':
		"The use of credit cards to advance consumption, or installment payment is now more and more people's mainstream payment methods",
	'page.creditCard.': '',
	'page.creditCard.limitIterm_01.title': 'High Credit Limit, Low Interest Rate',
	'page.creditCard.limitIterm_01.desc':
		'offers you a generous credit limit and ultralow interest rates, allowing you to easily manage large purchases and daily expenses, enjoying a worry-free cash flow experience.',
	'page.creditCard.limitIterm_02.title': 'Global Acceptance, Hassle-Free Use',
	'page.creditCard.limitIterm_02.desc':
		'offers you a generous credit limit and ultralow interest rates, allowing you to easily manage large purchases and daily expenses, enjoying a worry-free cash flow experience.',
	'page.creditCard.limitIterm_03.title': 'Secure and Worry-Free Usage',
	'page.creditCard.limitIterm_03.desc':
		'We provide comprehensive security measures to ensure every transaction is safe and worryfree.',
	'page.creditCard.contactUs': 'Contact us to have your exclusive credit card',
	'page.creditCard.contact.button': 'Contact Us',
	'page.aboutUs.bannerTitle': 'Make payments easier',
	'page.aboutUs.bannerDesc': 'We will provide payment services to companies all over the world',
	'page.aboutUs.bannerBtn': 'Open Account',
	'page.aboutUs.item_01.title': 'Who are we',
	'page.aboutUs.item_01.desc':
		'We are an organization dedicated to making payments easier for users. Our mission is to work with you for a long time to develop payment tools that better meet your needs',
	'page.aboutUs.item_02.title': 'Development course',
	'page.aboutUs.item_02.desc_01':
		'Online payments in Latin America are still at an early stage of development. We have always had a vision to develop the Latin American Internet ecosystem to make the payment experience smoother, more stable and more secure for users.',
	'page.aboutUs.item_02.desc_02':
		'At the same time, we also have a lot of traffic resources in the local market, so we can provide money management services for merchants, but also help merchants establish a solid local influence.',
	'page.aboutUs.item_03.title': 'We are the first choice of a large number of merchants',
	'page.aboutUs.item_03.desc':
		'Our excellent customer service has gained a large number of users in the industry, occupied most of the market share in the Brazilian market, and is the first choice for merchants to cooperate with.',
	'page.aboutUs.serves.title': 'ViatechBank serves the global market',
	'page.aboutUs.serves.desc_01': 'daily order volume：30 million',
	'page.aboutUs.serves.desc_02': 'daily order volume: 50 million orders',
	'page.payment.bannerDesc': 'payment',
	'page.payment.bannerTitle': 'Provide payment solutions around the world',
	'page.payment.item.desc_01':
		'We provide you with efficient and convenient payment methods to make your financial transactions simpler. With viatech bank, you can easily meet your transfer payment needs',
	'page.payment.item.desc_02':
		'At the same time, we also have a lot of traffic resources in the local market, so we can provide money management services for merchants, but also help merchants establish a solid local influence.',
	'page.payment.limit_01.title': 'invoice',
	'page.payment.limit_01.desc':
		'Enter the purchase amount on the viatechbank platform to receive payment from customers and generate a QR code for payment. The amount collected is collected into your account and can be settled in 7*24h to manage your funds flexibly',
	'page.payment.limit_02.title': 'payout',
	'page.payment.limit_02.desc':
		'Make payments to customers using the viatechbank platform Payment can be received in seconds, more convenient and more secure',
	'page.payment.limit_03.title': 'Bill management',
	'page.payment.limit_03.desc':
		'We offer personalized solutions for your business. Give you a clear and intuitive understanding of your account fund charges. Make your expense management clearer and more reasonable.',
	'page.contactUs.bannerTitle': 'This is where you start opening your account',
	'page.contactUs.bannerDesc': 'Start your payment journey',
	'page.contactUs.safe': 'Convenient, safe',
	'page.contactUs.service_time': '7*24h service',
	'page.contactUs.fnancial': '"Your premium fnancial steward"',
	'page.contactUs.form_service': '1.  What service do you require?',
	'page.contactUs.form_name': '2.  Your Name:',
	'page.contactUs.form_phone': '3.  Your phone number:',
	'page.contactUs.form_email': '4.  Your email:',
	'page.contactUs.form_say': '5.  What would you like to say to us?',
	'page.contactUs.submit': 'Submit',
	'page.contactUs.placeholder_text': 'Please enter',
	'page.contactUs.placeholder_select': 'Please select',
	'page.contactUs.options_create_account': 'Create  account',
	'page.contactUs.options_currency': 'Fiat currency conversion',
	'page.contactUs.options_credit_card': 'Credit card',
	'page.contactUs.options_answer': 'Answer questions',
	'page.contactUs.options_feedback': 'feedback',
	'page.fiatCurrencyExchange.title': 'You can trade anytime, anywhere',
	'page.fiatCurrencyExchange.bannerDesc': 'We offer global fiat and digital currency exchange',
	'page.FiatCurrencyExchange.sliderList.title_1':
		'Real-time exchange rate to reduce volatility risk',
	'page.FiatCurrencyExchange.sliderList.desc_1':
		'Provide real-time updated exchange rate information, so that you can get the most accurate exchange rate when conducting cross-border transactions, reducing the risk of exchange rate fluctuations',
	'page.FiatCurrencyExchange.sliderList.title_2': 'Support global legal currency, flexible choice',
	'page.FiatCurrencyExchange.sliderList.desc_2':
		'Hold the conversion of digital currency and fiat currency worldwide. Whether you are traveling abroad, studying abroad, or trading internationally, you can enjoy the freedom to pay without boundaries.',
	'page.FiatCurrencyExchange.sliderList.title_3': 'Professional customer service, 24/7 support',
	'page.FiatCurrencyExchange.sliderList.desc_3':
		'Our professional customer service team is online 24 hours a day to provide you with comprehensive support and service. No matter you encounter any problems in the transaction, you can contact us at any time, we will be happy to solve for you.',
	'page.FiatCurrencyExchange.sliderList.title_4': 'Secure and reliable transaction protection',
	'page.FiatCurrencyExchange.sliderList.desc_4':
		'We use top security technologies and encryption measures to ensure that every cross-border transaction is safe and secure. The platform has a comprehensive risk control system to ensure the safety of your funds.',
	'page.crossborder.crossBorderPayments': 'Cross-border payments',
	'page.crossborder.safe':
		'Safe, efficient and convenient. Make your international transactions easier!',
	'page.crossborder.viatechResponsible':
		'Viatech Responsible for all cross-border payment processes: currency conversion,transaction fees, exchange rates and alignment with national entities.',
	'page.crossborder.weGuarantee':
		'We guarantee fixed exchange rates, ideal taxes and direct contact with local partners 24 hours a day.',
	'page.crossborder.tools': 'Tools',
	'page.crossborder.weSupport': 'We support multiple payment methods',
	'page.crossborder.sercure': 'Secure and encrypted integration',
	'page.crossborder.fully': 'Fully API interface',
	'page.crossborder.worldwide': 'Payments worldwide',
	'page.crossborder.crossborderPaymentSecurity': 'Cross-border payment security',
	'page.crossborder.weUseMulti-factor':
		'We use multi-factor authentication techniques to identify users and carry out legitimate transactions, 24*7 transaction monitoring, risk management and protection against fraudsters.',
	'page.digitalWallet.support': 'Support',
	'page.digitalWallet.solve': 'Solve the',
	'page.digitalWallet.cash': 'cash flow',
	'page.digitalWallet.expertise': 'We Are Expertise In',
	'page.digitalWallet.provide':
		'Provide professional and easy to use, convenient cash flow solution',
	'page.digitalWallet.userAccount': 'User account',
	'page.digitalWallet.userAccountDesc':
		'Multi-currency account to help you solve your life payment, receipt, transfer problems',
	'page.digitalWallet.merchantAccount': 'Merchant account',
	'page.digitalWallet.merchantAccountDesc': 'Help you solve your operating income problems',
	'page.digitalWallet.withdraw': 'withdraw',
	'page.digitalWallet.withdrawDesc': 'Cash withdrawal through PIX, code scanning, Transfer',
	'page.digitalWallet.top-up': 'top-up',
	'page.digitalWallet.top-upDesc':
		'Top up cash to accounts in different currencies, securely and on demand',
	'page.digitalWallet.transferMoney': 'Transfer money',
	'page.digitalWallet.transferMoneyDesc': 'Transfer via QR code, simple and efficient',
	'page.digitalWallet.hourService': '7x24 hours service',
	'page.digitalWallet.hourServiceDesc':
		'Online customer service to help you solve questions in time',
	'page.digitalWallet.yourPersonalWallet': 'Your personal wallet',
	'page.digitalWallet.multiCurrencyAccount': 'Multi-currency account',
	'page.digitalWallet.createYourOwnAccount': 'Create your own account, secure and easy',
	'page.digitalWallet.justCompleteTheSimpleOperation':
		'Just complete the simple operation to create your own account, making it easy to pay, transfer and receive money.',
	'page.digitalWallet.yourMerchantWallet': 'Your merchant wallet',
	'page.digitalWallet.createYourOwnMerchantAccount':
		'Create your own merchant account and pay quickly',
	'page.digitalWallet.afterOpeningTheMerchantService':
		'After opening the merchant service, you can create your own merchant account,making your business more convenient.',
	'page.digitalWallet.scanCodeAndRecharge': 'Scan code and recharge',
	'page.digitalWallet.easyToEfficiently': 'Easy to efficiently top up cash to your account',
	'page.digitalWallet.pixCodeScanning': 'PIX, code scanning, Transfer withdrawal',
	'page.digitalWallet.makeCashWithdrawal':
		'Make cash withdrawal more convenient and fast to the account',
	'page.digitalWallet.youCanEnterYourPIXKey':
		'You can enter your PIX key, scan the QR code generated by pix, and withdraw cash via Transfer, where PX withdrawals are free and take place in an hour or less,even on weekends.',
	'page.digitalWallet.qrCodePayment': 'Qr code payment and collection',
	'page.digitalWallet.transfersAreEasier': 'Transfers are easier and faster',
	'page.digitalWallet.youCanSetUpDIfferent':
		'You can set up different amounts and generate a transfer QR code, which can be scanned by others after you send it to them.',
	'page.digitalWallet.merchantWallet': 'Merchant wallet',
	'page.button.openAccount': 'Open Account',
	'page.button.contactUs': 'Contact Us',
	'page.europe.solutions': 'Solutions for the European market',
	'page.europe.supportYourBusiness': 'Support your business with our most advanced technology',
	'page.europe.theProspect': 'The prospect of online payments in Europe',
	'page.europe.atPresent':
		'At present, in European countries, e-commerce, online payment fields, e-wallets are booming, presenting diversified payment methods',
	'page.europe.paymentMethods': 'We support multiple payment methods',
	'page.europe.aOne-Click': 'A one-click payment experience that connects you to the world',
	'page.europe.withAGlobal':
		'With a global payment network, diversified payment solutions, advanced security technology and a dedicated customer service team, we will help you stand out in the highly competitive European market',
	'page.latin.latinAmericanMarket': 'Latin American market',
	'page.latin.onlinePayment': 'Online payment perspective',
	'page.latin.inFastGrowing':
		'In fast-growing Latin America, online payments are becoming a key driver of economic',
	'page.latin.growth':
		'growth. You can take advantage of our advanced payment solutions to keep up with this',
	'page.latin.growthMarket': `growing market. Join us for a new era in Latin America's digital economy!`,
	'page.latin.paymentPreferencesBrazilian': `Payment preferences of Brazilian consumers`,
	'page.latin.paymentPreferencesMexican': `Payment preferences of Mexican consumers`,
	'page.latin.seamless': `Seamless integration of all payment methods`,
	'page.latin.weOfferGlobal': `We offer global payment services. We support credit cards, debit cards, e-wallets or instant bank transfers.`,
	'page.latin.weOfferCustomized': `We offer customized solutions to ensure that your payment process is efficient and smooth.`,
	'page.latin.contactUsNow': `Contact us now for a simpler, more flexible payment experience that will help you stand out from the global competition!`,
	'page.faq.support': 'Support',
	'page.faq.howCan': 'How can',
	'page.faq.weHelpYou': 'we help you?',
	'page.faq.howToCreate': 'How to create a bank account',
	'page.faq.youCanContact':
		'You can contact us, we will assist you to open a bank account,email:suporte@viatechbank.com',
	'page.faq.imNotFinding': `I'm not finding the payment method I want to use, how do I do it?`,
	'page.faq.Pagsmile': `Pagsmile offers four options as payment methods: Lottery, Boleto, Express Deposit and
						Credit Card. However, some stores that use viatechbank may choose not to use all of
						them, so you may not find the option you want within the viatechbank method. If your
						favorite store does not offer all viatechbank payment methods, you can contact their
						support directly and give your opinion. :)`,
	'page.faq.myOrder':
		'My order appears as paid at the store but I did not receive my product. What should I do?',
	'page.faq.viatechbankIsResponsibleForReceiving':
		'viatechbank is responsible for receiving payments and informing the store when they are approved, so that it can deliver the product purchased by you. So, if your order is approved, contact the store directly to understand what may have happened with the delivery of your order.',
	'page.faq.howCanIMake': 'How can I make sure that everything goes well with my purchase?',
	'page.faq.manyApproval':
		'Many approval problems can be avoided by providing a valid E-mail Address at the time you are making the payment.',
	'page.faq.manyCustomers':
		'Many customers, at the time of purchase, inform any email on the store page. When this happens, they no longer receive updates about the purchase, such as data, value,payment method, etc. If anything goes wrong with the payment, it is to this email address that the information is sent, and if the customer does not have access to the email, he stops being informed.',
	'page.faq.anotherReason':
		'Another reason is that, often depending on the payment method you have chosen, the institution needs to check purchase information with the customer, and they notify you by email. The customer, having no access to the e-mail, cannot make such confirmation and then has his order canceled, bringing an unnecessary frustration.',
	'page.faq.otherFactors':
		'Other factors to watch out for in your purchase is to always check if you are in the correct store where you want to buy your product, check if you are logged in to the correct account (in cases of customers who have more than one account) and double check the information placed on the store page, thus preventing data from being placed incorrectly.Many approval problems can be avoided by providing a valid E-mail Address at the time you are making the payment.',
	'page.faq.iWantToCancel': 'I want to cancel my purchase, how do I do it?',
	'page.faq.hereAtPagsmile':
		'Here at Pagsmile we are responsible for receiving payments and informing the store when they are approved, so that it can deliver the product purchased by you.',
	'page.faq.therefore':
		'Therefore, as we are not responsible for the purchase or the product, we have no autonomy to decide when a purchase can be canceled and, in this case, the only way to do this is directly with the customer support of the store where the purchase was made.',
	'page.recruitment.viatechLooks': 'Viatech looks forward to your joining us',
	'page.recruitment.weLookForward':
		'We look forward to speaking with you further and exploring your career opportunities with our company',
	'page.recruitment.weAreOrganization':
		'We are an organization dedicated to making it easier for users to pay.',
	'page.recruitment.viatechBankHasA':
		'ViatechBank has a large number of local users and a large market share in Brazil. We offer a comprehensive range of online and offline global payment solutions to our global customers.',
	'page.recruitment.jobDescription': 'Job description',
	'page.recruitment.recruitmentInformation': 'There is no recruitment information...',
	'page.home.yourGlobal': 'Your global payment partner',
	'page.home.weProvide':
		'We provide efficient, secure and convenient payment services around the world',
	'page.home.loremIpsumDolor':
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
	'page.home.instantTransfer': 'Instant Transfer',
	'page.home.paymentsWorldwide': 'Payments worldwide',
	'page.home.savingAccounts': 'Saving accounts',
	'page.home.mobileBanking': '100% mobile banking',
	'page.home.internationalService': 'International service',
	'page.home.provideInstantPayments':
		'Provide instant payments in Latin America, Europe and other regions to help companies achieve business expansion everywhere',
	'page.home.viatechbankApp': 'Viatechbank app',
	'page.home.paymentTransfer':
		'Payment, transfer, PIX, debit card, credit card, e-wallet, there is always one for you',
	'page.home.makeYourMoney': 'Make your money safer',
	'page.home.cryptographic':
		'Cryptographic multiple algorithms and risk control management make your money safer',
	'page.home.serviceSupport': '7*24 service support',
	'page.home.serviceSupportToAnswer': '24/7 service support to answer your questions at any time',
	'page.home.oneBanking': 'One banking.',
	'page.home.payment': 'Payment',
	'page.home.paymentDesc': 'To provide you with the global instant receipt and payment service',
	'page.home.global': 'Global fiat and digital currency conversion',
	'page.home.globalDesc': '7*24 Provide currency exchange services',
	'page.home.digitalWallet': 'Digtial Wallets',
	'page.home.digitalWalletDesc': 'Solve the problem of capital turnover',
	'page.home.crossBorderPayment': 'Cross Border Payment',
	'page.home.crossBorderPaymentDesc': 'Make it easy to expand your global business',
	'page.home.crditCard': 'Credit cards and border cards',
	'page.home.crditCardDesc': 'Buy now, pay later',
	'page.home.dashbord': 'Dashbord',
	'page.home.dashbordDesc': 'Make managing money easier',
	'page.home.creditCardService': 'Credit card service',
	'page.home.allPayment': 'All payment operations can be operated by a mobile phone.',
	'page.shopcard.oneApp': 'One app',
	'page.questions.anyOtherQuestions': 'Any other questions?',
	'page.questions.weAreHereToHelp': `We're here to help you`,
	'page.questions.support@viatechbank': 'suporte@viatechbank.com',
	'page.questions.supportEmail': 'Support Email',
	'page.businessSupport.viatechbankTornar': 'Viatechbank Making managing your money easier',
	'page.businessSupport.supportManyBusiness':
		'Support many business needs, just to give you the best service',
	'page.businessSupport.instant': 'Instant transactions',
	'page.businessSupport.secureAndFast': 'Secure and fast to facilitate the flow of money',
	'page.businessSupport.oneLump': 'One lump sum payment',
	'page.businessSupport.oneInterface': 'One interface, global services',
	'page.businessSupport.cardServices': 'Card Services',
	'page.businessSupport.highCredit':
		'High credit lines, low interest rates, worldwide availability, secure and controlled',
	'page.businessSupport.mobileBanking': 'Mobile banking',
	'page.businessSupport.oneMachine':
		'One machine in hand, you can enjoy payment freedom anytime, anywhere',
	'page.businessSupport.professional': 'Professional technical team',
	'page.businessSupport.deepPlowing':
		'Deep plowing payment, with rich payment experience, to provide professional and efficient service.',
	'page.businessSupport.onlineCustomer': 'Online customer service',
	'page.businessSupport.providing': '7*24 hours, providing service all the time.',
	'page.dashbord.officeManagement': 'Viatech bank back office management system',
	'page.dashbord.visual': 'Visual fund management',
	'page.dashbord.weGuarantee':
		'We guarantee reliable data and in-depth analysis to facilitate the management of your payment operations',
	'page.dashbord.accurate': 'Accurate data statistics',
	'page.dashbord.control': 'Control your own money flow at any time through our system',
	'page.dashbord.bestUser': 'Best user experience',
	'page.dashbord.prioritize': 'Prioritizing customer needs, just to give you a better experience',
	'page.dashbord.professional':
		'Professional technical team and perfect system to track your business',
};
