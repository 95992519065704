import { FormattedMessage, useIntl } from 'react-intl';
import { FadeIn } from '../../components/fadein';
import contactUs from '../../asserts/images/contactUs-card-banner.png';
import './contactUs.scss';
import type { FormProps } from 'antd';
import { ConfigProvider, Input, Form, Select, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
type FieldType = {
	service?: string;
	username?: string;
	email?: string;
	phone?: string;
	say?: string;
};

const ContactUs = () => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const serviceOptions = [
		{
			value: 4,
			label: (
				<span>
					{intl.formatMessage({
						id: 'page.contactUs.options_create_account',
						defaultMessage: 'Create  account',
					})}
				</span>
			),
		},
		{
			value: 3,
			label: (
				<span>
					{intl.formatMessage({
						id: 'page.contactUs.options_currency',
						defaultMessage: 'Fiat currency conversion',
					})}
				</span>
			),
		},
		{
			value: 2,
			label: (
				<span>
					{intl.formatMessage({
						id: 'page.contactUs.options_credit_card',
						defaultMessage: 'Credit card',
					})}
				</span>
			),
		},
		{
			value: 1,
			label: (
				<span>
					{intl.formatMessage({
						id: 'page.contactUs.options_answer',
						defaultMessage: 'Answer questions',
					})}
				</span>
			),
		},
		{
			value: 5,
			label: (
				<span>
					{intl.formatMessage({
						id: 'page.contactUs.options_feedback',
						defaultMessage: 'Feedback',
					})}
				</span>
			),
		},
	];
	const create = (parmas: any) => {
		axios
			.post(
				'https://ajovxg1nca.execute-api.sa-east-1.amazonaws.com/v1/create-merchant-application',
				parmas
			)
			.then((response) => {
				// 处理成功响应
				message.success(
					intl.formatMessage({
						id: 'page.contactUs.success',
						defaultMessage: 'Success',
					})
				);
			})
			.catch((error) => {
				// 处理错误
				message.error(`${error}`);
			});
	};
	const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
		create(values);
		form.resetFields();
	};

	const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<div className="contactUs">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container">
					<FadeIn className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.contactUs.bannerTitle',
								defaultMessage: 'This is where you start opening your account',
							})}
						</div>
						<div className="banner-des">
							{intl.formatMessage({
								id: 'page.contactUs.bannerDesc',
								defaultMessage: 'Start your payment journey',
							})}
						</div>
						<div className="banner-serveice">
							<div className="banner-serveice-item">
								<div className="banner-serveice-item-icon"></div>
								<div>
									{intl.formatMessage({
										id: 'page.contactUs.safe',
										defaultMessage: 'Convenient, safe',
									})}
								</div>
							</div>
							<div className="banner-serveice-item">
								<div className="banner-serveice-item-icon"></div>
								<div>
									{intl.formatMessage({
										id: 'page.contactUs.service_time',
										defaultMessage: '7*24h service',
									})}
								</div>
							</div>
							<div className="banner-serveice-item">
								<div className="banner-serveice-item-icon"></div>
								<div>
									{intl.formatMessage({
										id: 'page.contactUs.fnancial',
										defaultMessage: '"Your premium fnancial steward"',
									})}
								</div>
							</div>
						</div>
					</FadeIn>
					<div className="banner-img">
						<img className="in" src={contactUs} alt="" />
					</div>
				</div>
			</div>
			<div className="contact-form-container page-padding">
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: '#5bb5a2',
							borderRadius: 6,
							colorBgContainer: '#FFFFFF',
							controlHeight: 46,
						},
						components: {
							Form: {
								itemMarginBottom: 36,
								verticalLabelPadding: '0 0 12px 0',
								labelFontSize: 20,
								labelColor: '#1A191E',
							},
						},
					}}
				>
					<Form
						name="basic"
						// labelCol={{ span: 8 }}
						// wrapperCol={{ span: 16 }}
						layout="vertical"
						initialValues={{ remember: true }}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						form={form}
					>
						<Form.Item
							name="require"
							label={
								<FormattedMessage
									id="page.contactUs.form_service"
									defaultMessage="1.  What service do you require?"
								/>
							}
							required
							rules={[{ required: true }]}
						>
							<Select
								placeholder={intl.formatMessage({
									id: 'page.contactUs.placeholder_select',
									defaultMessage: 'Please select',
								})}
								options={serviceOptions}
							/>
						</Form.Item>
						<Form.Item
							label={
								<FormattedMessage id="page.contactUs.form_name" defaultMessage="2.  Your Name:" />
							}
							name="name"
							required
							rules={[{ required: true, message: 'Please input your username!' }]}
						>
							<Input
								placeholder={intl.formatMessage({
									id: 'page.contactUs.placeholder_text',
									defaultMessage: 'Please enter',
								})}
							/>
						</Form.Item>

						<Form.Item
							label={
								<FormattedMessage
									id="page.contactUs.form_phone"
									defaultMessage="3.  Your phone number:"
								/>
							}
							name="contactInformation"
							required
							rules={[{ required: true, message: 'Please input your phone number!' }]}
						>
							<Input
								placeholder={intl.formatMessage({
									id: 'page.contactUs.placeholder_text',
									defaultMessage: 'Please enter',
								})}
							/>
						</Form.Item>

						<Form.Item
							label={
								<FormattedMessage id="page.contactUs.form_email" defaultMessage="4.  Your email:" />
							}
							name="email"
							required
							rules={[{ required: true, message: 'Please input your email!' }]}
						>
							<Input
								placeholder={intl.formatMessage({
									id: 'page.contactUs.placeholder_text',
									defaultMessage: 'Please enter',
								})}
							/>
						</Form.Item>
						<Form.Item
							label={
								<FormattedMessage
									id="page.contactUs.form_say"
									defaultMessage="5.  What would you like to say to us?"
								/>
							}
							name="remark"
							// rules={[{ required: true, message: 'Please input your password!' }]}
						>
							<TextArea
								rows={4}
								placeholder={intl.formatMessage({
									id: 'page.contactUs.placeholder_text',
									defaultMessage: 'Please enter',
								})}
								maxLength={6}
							/>
						</Form.Item>
						<Form.Item>
							<a
								onClick={() => {
									form?.submit();
								}}
								className="button primary  w-inline-block"
							>
								<div className="button-inner">
									<div className="button-inner-text">
										<FormattedMessage id="page.contactUs.submit" defaultMessage="Submit" />
									</div>
									<div className="button-inner-text-hover">
										<FormattedMessage id="page.contactUs.submit" defaultMessage="Submit" />
									</div>
								</div>
							</a>
						</Form.Item>
					</Form>
				</ConfigProvider>
			</div>
		</div>
	);
};
export default ContactUs;
