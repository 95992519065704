import { Home } from '../pages/home/index';
import { ProductAndService } from '../components/productandservice';
import { Solution } from '../components/solution/index';
import { Contact } from '../components/contact';
import { Help } from '../components/help';
import AboutUs from '../pages/AboutUs/index';
import ElectronicWallet from '../pages/electronicwallet';
import Faq from '../pages/faq';
import CrossBorder from '../pages/crossborder';
import CreditCard from '../pages/CreditCard/index';
import Payment from '../pages/Payment/index';
import Europe from '../pages/europe';
import Latin from '../pages/latin';
import Recruitment from '../pages/recruitment';
import ContactUs from '../pages/ContactUs';
import FiatCurrencyExchange from '../pages/FiatCurrencyExchange';
import BusinessSupport from '../pages/businesssupport';
import DashBord from '../pages/dashbord';
import Laws from '../pages/laws';
type Router = {
	name: string;
	path: string;
	element?: JSX.Element;
	children?: Router[];
};

export const routes: Router[] = [
	{
		name: 'Home',
		path: '/',
		element: <Home />,
	},
	{
		name: 'Product & Service',
		path: '/productandservice',
		element: <ProductAndService />,
	},
	{
		name: 'Solution',
		path: '/solutions',
		element: <Solution />,
	},
	{
		name: 'Contact',
		path: '/contacts',
		element: <Contact />,
	},
	{
		name: 'Help',
		path: '/helps',
		element: <Help />,
	},
	{
		name: 'AboutUs',
		path: '/about',
		element: <AboutUs />,
	},
	{ name: 'Electronic Wallet', path: '/digital_wallet', element: <ElectronicWallet /> },
	{ name: 'FAQ', path: '/faq', element: <Faq /> },
	{ name: 'CrossBorder', path: '/cross_border', element: <CrossBorder /> },
	{
		name: 'CreditCard',
		path: '/creditCard',
		element: <CreditCard />,
	},
	{
		name: 'Payment',
		path: '/payment',
		element: <Payment />,
	},
	{ name: 'Europe', path: '/europe', element: <Europe /> },
	{ name: 'Latin', path: '/latin', element: <Latin /> },
	{ name: 'Recruitment', path: '/recruitment', element: <Recruitment /> },
	{ name: 'ContactUs', path: '/contactUs', element: <ContactUs /> },
	{
		name: 'FiatCurrencyExchange',
		path: '/fiatCurrencyExchange',
		element: <FiatCurrencyExchange />,
	},
	{ name: 'BusinessSupport', path: '/business_support', element: <BusinessSupport /> },
	{ name: 'DashBord', path: '/dashbord', element: <DashBord /> },
	{ name: 'Laws', path: '/laws', element: <Laws /> },
];
