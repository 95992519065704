import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import ShopCard from '../../components/shopcard';
import './index.scss';
import Questions from '../../components/questions';
import blackcard from '../../asserts/images/premiumcard.png';
import greendcard from '../../asserts/images/freecard.png';
import { FadeIn } from '../../components/fadein';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
export const Home = () => {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const dateRef = useRef<HTMLDivElement>(null);
	const shopRef = useRef<HTMLDivElement>(null);
	const phoneRef = useRef<HTMLDivElement>(null);
	const qesRef = useRef<HTMLDivElement>(null);
	const intl = useIntl();
	const [date, setDate] = useState<boolean>(false);
	const [shop, setShop] = useState<boolean>(false);
	const [phone, setPhone] = useState<boolean>(false);
	const [qes, setQus] = useState<boolean>(false);
	const navigate = useNavigate();
	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
		const rect = e.currentTarget.getBoundingClientRect();
		const mouseX = e.clientX - rect.left;
		const mouseY = e.clientY - rect.top;
		const centerX = rect.width / 2;
		const centerY = rect.height / 2;
		const offsetX = (mouseX - centerX) / centerX;
		const offsetY = (mouseY - centerY) / centerY;

		setMousePosition({ x: offsetX, y: offsetY });
	};
	const handleMouseLeave = () => {
		setMousePosition({ x: 0, y: 0 });
	};
	useEffect(() => {
		const options = {
			root: null, // 视口的根元素，默认为浏览器视口
			rootMargin: '0px', // 根元素的边距，可用来扩展或收缩交叉观察器的根边界框
			threshold: 0.3, // 一个阈值数组或一个单个数值，表示目标的可见性阈值
		};

		const handleIntersect: IntersectionObserverCallback = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					if (entry.target.className === 'date ') {
						setDate(true);
					} else if (entry.target.className === 'phone ') {
						setPhone(true);
					} else if (entry.target.className === 'shop ') {
						setShop(true);
					} else if (entry.target.className === 'question ') {
						setQus(true);
					}
				}
			});
		};

		const observer = new IntersectionObserver(handleIntersect, options);

		if (shopRef.current) {
			observer.observe(shopRef.current);
		}
		if (dateRef.current) {
			observer.observe(dateRef.current);
		}
		if (phoneRef.current) {
			observer.observe(phoneRef.current);
		}
		if (qesRef.current) {
			observer.observe(qesRef.current);
		}

		return () => {
			observer.disconnect();
		};
	}, []);
	return (
		<div className="home">
			<div className="card" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
				<section className="bankCord">
					<div className="bankCordContent">
						<div className="bankCordContentText">
							<div className="bankCordContentTextTitle">
								<p>
									{intl.formatMessage({
										id: 'page.home.yourGlobal',
										defaultMessage: 'Your global payment partner',
									})}
								</p>
							</div>
							<div className="bankCordContentTextDesc">
								{intl.formatMessage({
									id: 'page.home.weProvide',
									defaultMessage: `We provide efficient, secure and convenient payment services around the world`,
								})}
							</div>
							<div className="bankCordContentTextTag">
								<div className="bankCordContentTextTagItem">
									<div className="tagLeft">
										<div className="tagIcon"></div>
										<div className="tagDesc">
											{intl.formatMessage({
												id: 'page.home.instantTransfer',
												defaultMessage: `Instant Transfer`,
											})}
										</div>
									</div>
									<div className="tagRight">
										<div className="tagIcon"></div>
										<div className="tagDesc">
											{intl.formatMessage({
												id: 'page.home.paymentsWorldwide',
												defaultMessage: `Payments worldwide`,
											})}
										</div>
									</div>
								</div>
								<div className="bankCordContentTextTagItem">
									<div className="tagLeft">
										<div className="tagIcon"></div>
										<div className="tagDesc">
											{intl.formatMessage({
												id: 'page.home.creditCardService',
												defaultMessage: `Credit card service`,
											})}
										</div>
									</div>
									<div className="tagRight">
										<div className="tagIcon"></div>
										<div className="tagDesc">
											{intl.formatMessage({
												id: 'page.home.mobileBanking',
												defaultMessage: `100% mobile banking`,
											})}
										</div>
									</div>
								</div>
							</div>
							<div className="navbar-right">
								<a href="/contactUs" className="button primary small w-inline-block">
									<div className="button-inner">
										<div className="button-inner-text">
											{intl.formatMessage({
												id: 'page.button.openAccount',
												defaultMessage: 'Open Account',
											})}
										</div>
										<div className="button-inner-text-hover">
											{intl.formatMessage({
												id: 'page.button.openAccount',
												defaultMessage: 'Open Account',
											})}
										</div>
									</div>
								</a>
							</div>
						</div>
						<div className="bankCordContentImg">
							<div className="imgContent">
								<div
									className="imgContentCord_1"
									style={{
										transform: `translate(${-mousePosition.x * 1 * 10}px, ${
											-mousePosition.y * 1 * 10
										}px)`,
									}}
								>
									<img src={blackcard} alt="" />
								</div>
								<div
									className="imgContentCord_2"
									style={{
										transform: `translate(${-mousePosition.x * -1 * 10}px, ${
											-mousePosition.y * -1 * 10
										}px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(14deg) skew(0deg, 0deg)`,
									}}
								>
									<img src={greendcard} alt="" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<section className={`date `}>
				<div className="dateContent">
					<FadeIn className="dateContentDesc">
						<div>
							<div className="descItem">
								{intl.formatMessage({
									id: 'page.home.internationalService',
									defaultMessage: `International service`,
								})}
								<div className="descItemText">
									{intl.formatMessage({
										id: 'page.home.provideInstantPayments',
										defaultMessage: `Provide instant payments in Latin America, Europe and other regions to help
									companies achieve business expansion everywhere`,
									})}
								</div>
							</div>
							<div className="descItem">
								{intl.formatMessage({
									id: 'page.home.viatechbankApp',
									defaultMessage: `Viatechbank app`,
								})}
								<div className="descItemText">
									{intl.formatMessage({
										id: 'page.home.paymentTransfer',
										defaultMessage: `Payment, transfer, PIX, debit card, credit card, e-wallet, there is always one for
									you`,
									})}
								</div>
							</div>
							<div className="descItem">
								{intl.formatMessage({
									id: 'page.home.makeYourMoney',
									defaultMessage: `Make your money safer`,
								})}
								<div className="descItemText">
									{intl.formatMessage({
										id: 'page.home.cryptographic',
										defaultMessage: `Cryptographic multiple algorithms and risk control management make your money
									safer`,
									})}
								</div>
							</div>
							<div className="descItem">
								{intl.formatMessage({
									id: 'page.home.serviceSupport',
									defaultMessage: `7*24 service support`,
								})}
								<div className="descItemText">
									{intl.formatMessage({
										id: 'page.home.serviceSupportToAnswer',
										defaultMessage: `24/7 service support to answer your questions at any time`,
									})}
								</div>
							</div>
						</div>
					</FadeIn>
					<FadeIn className="dateContentImg">
						<div></div>
					</FadeIn>
				</div>
			</section>

			<section className={`phone page-padding `} ref={phoneRef}>
				<div className="phoneContent">
					<FadeIn className="phoneContentDesc">
						<div>
							<div className="phoneContentDescTitle">
								{intl.formatMessage({
									id: 'page.home.oneBanking',
									defaultMessage: `One banking.`,
								})}
							</div>
							<FadeIn className="phoneContentDescItem">
								<div
									className="phoneLeft"
									onClick={() => {
										window.location.href = '/payment';
									}}
								>
									<div className="bill"></div>
									<div className="phoneTitle">
										{intl.formatMessage({
											id: 'page.home.payment',
											defaultMessage: `Payment`,
										})}
									</div>
									<div className="phoneDesc">
										{intl.formatMessage({
											id: 'page.home.paymentDesc',
											defaultMessage: `To provide you with the global instant receipt and payment service`,
										})}
									</div>
								</div>
								<div
									className="phoneRight"
									onClick={() => {
										window.location.href = '/';
									}}
								>
									<div className="wallet"></div>
									<div className="phoneTitle">
										{intl.formatMessage({
											id: 'page.home.global',
											defaultMessage: `Global fiat and digital currency conversion`,
										})}
									</div>
									<div className="phoneDesc">
										{intl.formatMessage({
											id: 'page.home.globalDesc',
											defaultMessage: `7*24 Provide currency exchange services`,
										})}
									</div>
								</div>
							</FadeIn>
							<FadeIn className="phoneContentDescItem">
								<div
									className="phoneLeft"
									onClick={() => {
										window.location.href = '/digital_wallet';
									}}
								>
									<div className="telephone"></div>
									<div className="phoneTitle">
										{intl.formatMessage({
											id: 'page.home.digitalWallet',
											defaultMessage: `Digtial Wallets`,
										})}
									</div>
									<div className="phoneDesc">
										{intl.formatMessage({
											id: 'page.home.digitalWalletDesc',
											defaultMessage: `Solve the problem of capital turnover`,
										})}
									</div>
								</div>
								<div
									className="phoneRight"
									onClick={() => {
										window.location.href = '/cross_border';
									}}
								>
									<div className="airplane"></div>
									<div className="phoneTitle">
										{intl.formatMessage({
											id: 'page.home.crossBorderPayment',
											defaultMessage: `Cross Border Payment`,
										})}
									</div>
									<div className="phoneDesc">
										{intl.formatMessage({
											id: 'page.home.crossBorderPaymentDesc',
											defaultMessage: `Make it easy to expand your global business`,
										})}
									</div>
								</div>
							</FadeIn>
							<FadeIn className="phoneContentDescItem">
								<div
									className="phoneLeft"
									onClick={() => {
										window.location.href = '/creditCard';
									}}
								>
									<div className="creditCard"></div>
									<div className="phoneTitle">
										{intl.formatMessage({
											id: 'page.home.crditCard',
											defaultMessage: `Credit cards and border cards`,
										})}
									</div>
									<div className="phoneDesc">
										{intl.formatMessage({
											id: 'page.home.crditCardDesc',
											defaultMessage: `Buy now, pay later`,
										})}
									</div>
								</div>
								<div
									className="phoneRight"
									onClick={() => {
										window.location.href = '/dashbord';
									}}
								>
									<div className="index"></div>
									<div className="phoneTitle">
										{intl.formatMessage({
											id: 'page.home.dashbord',
											defaultMessage: `Dashbord`,
										})}
									</div>
									<div className="phoneDesc">
										{intl.formatMessage({
											id: 'page.home.dashbordDesc',
											defaultMessage: `Make managing money easier`,
										})}
									</div>
								</div>
							</FadeIn>
						</div>
					</FadeIn>
					<FadeIn className="phoneContentImg">
						<div></div>
					</FadeIn>
				</div>
			</section>
			<FadeIn className="shop page-padding ">
				<section ref={shopRef}>
					<ShopCard />
				</section>
			</FadeIn>
			<FadeIn className="question page-padding ">
				<section ref={qesRef}>
					<Questions />
				</section>
			</FadeIn>
		</div>
	);
};
