export default {
	'menu.lang.language': 'linguagem',
	'menu.lang.en': 'English',
	'menu.lang.pt': 'Português',
	'menu.productAndService': 'Produto e serviço',
	'menu.support': 'Apoiar',
	'menu.globalLayout': 'Layout Global',
	'menu.aboutUs': 'Sobre nós',
	'menu.payment': 'Pagamento',
	'menu.bankWallet': 'Carteira bancária',
	'menu.creditCard': 'Cartões de crédito',
	'menu.globalFiat': 'Conversão global de moeda fiduciária e digital',
	'menu.faq': 'Perguntas frequentes',
	'menu.legal': 'Jurídico',
	'menu.career': 'Carreira',
	'menu.helpAndSupport': 'Ajuda e suporte',
	'menu.dashboard': 'Painel',
	'menu.cross-border': 'Pagamento transfronteiriço',
	'menu.latinAmerica': 'América latina',
	'menu.europe': 'Europa',
	'menu.button.createAccount': 'Criar uma conta',
};
