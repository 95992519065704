import './AboutBanner.scss';
import goldImg from '../../asserts/images/goldcard.png';
import premiumImg from '../../asserts/images/premiumcard.png';
import freeImg from '../../asserts/images/freecard.png';
import { FadeIn } from '../fadein';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
	title: string;
	description: string;
};

export const AboutBanner = (props: Props) => {
	const intl = useIntl();
	const { title, description } = props;
	return (
		<div className="about-banner">
			<FadeIn className="banner-content page-padding in">
				<div className="banner-title">{title}</div>
				<div className="banner-des">{description}</div>
				<a href="/contactUs" className="button primary">
					<div className="button-inner">
						<div className="button-inner-text">
							<FormattedMessage id="aboutBanner.bannerBtn" defaultMessage="Open Account" />
						</div>
						<div className="button-inner-text-hover">
							<FormattedMessage id="aboutBanner.bannerBtn" defaultMessage="Open Account" />
						</div>
					</div>
				</a>
			</FadeIn>
			<div className="features-hero-cards">
				<div className="features-hero-cards-inner">
					<div className="features-hero-card-wrapper goldImg">
						<img src={goldImg} alt="" />
					</div>
					<div className="features-hero-card-wrapper premiumImg">
						<img src={premiumImg} alt="" />
					</div>
					<div className="features-hero-card-wrapper freeImg">
						<img src={freeImg} alt="" />
					</div>
					<div className="features-hero-card-wrapper premiumImg">
						<img src={premiumImg} alt="" />
					</div>
					<div className="features-hero-card-wrapper goldImg">
						<img src={goldImg} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default AboutBanner;
