import React from 'react';
import './index.scss';
import { useIntl } from 'react-intl';
import { FadeIn } from '../fadein';
const ShopCard = () => {
	const intl = useIntl();
	return (
		<FadeIn className="shopCard">
			<div className="shopCardDesc">
				<div className="bankCordContentText">
					<div className="bankCordContentTextTitle">
						<p>
							{intl.formatMessage({
								id: 'page.shopcard.oneApp',
								defaultMessage: 'One app.',
							})}
						</p>
						<p>
							{intl.formatMessage({
								id: 'page.home.oneBanking',
								defaultMessage: 'One banking.',
							})}
						</p>
					</div>
					<div className="bankCordContentTextDesc">
						{intl.formatMessage({
							id: 'page.home.allPayment',
							defaultMessage: `All payment operations can be operated by a mobile phone.`,
						})}
					</div>
					<div className="bankCordContentTextTag">
						<div className="bankCordContentTextTagItem">
							<div className="tagLeft">
								<div className="tagIcon"></div>
								<div className="tagDesc">
									{intl.formatMessage({
										id: 'page.home.instantTransfer',
										defaultMessage: 'Instant Transfer',
									})}
								</div>
							</div>
							<div className="tagRight">
								<div className="tagIcon"></div>
								<div className="tagDesc">
									{intl.formatMessage({
										id: 'page.home.paymentsWorldwide',
										defaultMessage: 'Payments worldwide',
									})}
								</div>
							</div>
						</div>
						<div className="bankCordContentTextTagItem">
							<div className="tagLeft">
								<div className="tagIcon"></div>
								<div className="tagDesc">
									{intl.formatMessage({
										id: 'page.home.creditCardService',
										defaultMessage: 'Credit card service',
									})}
								</div>
							</div>
							<div className="tagRight">
								<div className="tagIcon"></div>
								<div className="tagDesc">
									{intl.formatMessage({
										id: 'page.home.mobileBanking',
										defaultMessage: '100% mobile banking',
									})}
								</div>
							</div>
						</div>
					</div>
					<div className="shopBtnBox">
						<div
							className="appleShop"
							onClick={() => {
								window.open('https://apps.apple.com/app');
							}}
						></div>
						<div
							className="googleShop"
							onClick={() => {
								window.open(
									'https://play.google.com/store/apps/details?id=br.com.viatech.onz&hl=zh_HK'
								);
							}}
						></div>
					</div>
				</div>
			</div>
			<div className="shopCardImg"></div>
		</FadeIn>
	);
};
export default ShopCard;
