const LegalPr = () => {
	return (
		<div className="legal page-padding">
			<div id="en">
				<h1>POLÍTICA DE PRIVACIDADE</h1>
				<p>
					Este instrumento particular, <strong>“Política de Privacidade”</strong>, regula a coleta
					de dados pessoais dos usuários, denominados como "Titular", bem como a forma de seu
					tratamento pela VIATECH BANK PROCESSADORA DE PAGAMENTO LTDA, instituição de pagamento,
					pessoa jurídica inscrita no CNPJ sob o nº35.858.847/0001­96, denominada neste documento
					como "Viatech".
				</p>
				<h2>DEFINIÇÕES INICIAIS</h2>
				<ul>
					<li>
						Endereço de Protocolo de Internet (Endereço IP): código atribuído a um Terminal de 
						uma rede para permitir sua identificação, definido segundo parâmetros internacionais;
					</li>
					<li>
						Internet: sistema constituído do conjunto de protocolos lógicos, estruturado em escala 
						mundial para uso público e irrestrito, com a finalidade de possibilitar a comunicação de 
						dados entre terminais por meio de diferentes redes;
					</li>
					<li>
						Aplicativo: meio pelo qual o Usuário acessa os serviços e conteúdos disponibilizados
						pela Viatech;
					</li>
					<li>
						Usuário: toda a pessoa natural, maior de 18 (dezoito) anos ou emancipada e totalmente
						capaz de praticar os atos da vida civil ou as pessoas absolutamente ou relativamente
						incapazes devidamente representadas ou assistidas, que, atendendo aos critérios
						necessários e preestabelecidos, utilizarão o site ou aplicativo;
					</li>
					<li>
						Data Protection Officer: é o profissional que, dentro de uma Instituição, encarrega­se
						de cuidar das questões referentes à proteção dos dados da organização e de seus
						clientes, de acordo com as disposições da Lei n ̊13.709/2018. Em seu trabalho, ele
						auxilia a Instituição a adaptar seus processos para estruturar um programa de compliance
						com foco em maior segurança das informações que estão sob a sua tutela;
					</li>
					<li>
						Backlinks: websites da Viatech é indicado ("linkado*), não se confundindo com um
						referral;
					</li>
					<li>
						Browser: Software cuja função primordial é garantir acesso à interface da loja virtual e
						à internet;
					</li>
					<li>
						Cookies: pequenos arquivos gravados por websites no disco rígido do computador do(a)
						usuário(a) e que destinam a armazenar informações preferenciais do(a) usuário(a), tais
						como nomes de usuário, produtos e serviços prediletos e endereços visitados;
					</li>
					<li>
						Domínio: endereço único na internet que identifica, pelo prefixo www (world wide web) e
						por determinado sufixo (por exemplo, www.dominio.com.br), um website de determinada
						empresa ou sujeito;
					</li>
					<li>
						E­mail:  endereço  eletrônico  de  recebimento  de  correspondências  e  informações 
						em  formato digital (por exemplo, 
						<a href="destinatario@dominio.com.br" target="_blank" rel="external nofollow noopener">
							destinatario@dominio.com.br
						</a>
						)
					</li>
					<li>
						Firewall:  software ou  hardware  de  uma  rede  de  computadores  que tem 
						por objetivo 
						proteger uma rede, controlando o fluxo de dados entre redes e computador distintos e 
						bloqueando as comunicações não autorizadas;
					</li>
					<li>Internet: rede mundial de comunicação entre computadores;</li>
					<li>
						Links:  endereços  virtuais,  localizados  dentro  da internet,  que  poderão  ser 
						acessados  dentro do Portal (links internos) ou fora dele (links externos);
					</li>
					<li>Online: por meio da internet;</li>
					<li>
						Offline:  fora do ambiente da internet, ou  seja, no ambiente  físico e tangível em que 
						vivemos;
					</li>
					<li>
						Portal:  plataforma  on­line  que  permite  que  o(a)  usuário(a)  acessar  um 
						conjunto  de 
						serviços que, por sua vez, viabilizam a atividade da Viatech por meio da internet;
					</li>
					<li>
						Referral:  mecanismo  de  busca  por  meio  do  qual  o  website  da  Viatech  pode, 
						e,  eventualmente, é localizado;
					</li>
					<li>
						Spam: envio em massa de correspondências (e­mails) não­desejados e não­solicitados 
						para um número indeterminado de destinatários;
					</li>
					<li>
						SSL  (Secure  Sockets  Layer):  tecnologia  (protocolo)  padrão  de  segurança  no 
						estabelecimento de uma conexão segura e criptografada entre um servidor e um cliente, 
						como por exemplo entre um website e um browser.
					</li>
				</ul>
				<h2>COOKIES</h2>
				<p>
					Os cookies são pequenos arquivos de texto enviados pelos sites ao seu navegador, que 
					podem ou não ser armazenados no diretório do navegador do seu dispositivo. Estes arquivos 
					armazenam e reconhecem dados que garantem o funcionamento correto dos sites e aplicativos, 
					além de acompanhar seus movimentos nestes canais e ajudar a identificar preferências e outras 
					funções de personalização.
				</p>
				<h3>São exemplos de Cookies e suas finalidades:</h3>
				<ul>
					<li>
						Funcionais:  garantem  o  correto  acesso  e  funcionamento  das  aplicações  e 
						funcionalidades;
					</li>
					<li>
						Autenticação: reconhecem o usuário, possibilitando o seu acesso de áreas restritas ou 
						não, com o objetivo de oferecer conteúdo, ofertas e serviços;
					</li>
					<li>
						Segurança: para auxiliar no monitoramento e detecção de atividades não autorizadas,  na 
						prevenção  à  fraude  e  na  proteção  das  informações dos  associados,  potenciais 
						associados, clientes, parceiros e, também, da Viatech;
					</li>
					<li>
						Marketing: utilizados para verificar, medir e analisar a audiência, oferecer conteúdo 
						mais relevante e de interesse dos usuários dos canais, além de avaliar o desempenho de 
						campanhas promocionais e publicitárias. 
					</li>
				</ul>
				<p>
					Você pode concordar em usar Cookies quando visitar este site pela primeira vez, através 
					do alerta exibido em nossa página inicial. É possível, a qualquer momento, alterar ou retirar seu 
					consentimento da Declaração de Cookies em nosso site, clicando no ícone "Cookie", na parte 
					inferior  da  tela.  No  entanto,  algumas  das  funções  e/ou  conteúdos  do  site 
					podem  ter  suas  funcionalidades limitadas.
				</p>
				<h2>DADOS COLETADOS E FINALIDADES</h2>
				<p>
					Por  toda  e  qualquer  navegação  na  plataforma  disponibilizada  pela 
					<strong>Viatech</strong>
					,  no
					momento da realização de cadastros, com a aquisição ou não de qualquer serviço ou produto 
					disponibilizado, entrando em contato através dos canais oficiais de atendimento ou até mesmo 
					apenas participando de pesquisas e promoções, a
					<strong> Viatech</strong> poderá coletar tanto dados pessoais  quanto não pessoais.
				</p>
				<h2>
					As Informações Pessoais, solicitadas quando da realização de cadastro pelo usuário, via 
					aplicativos digitais, por sua vez, incluem dados que permitem a individualização do 
					<strong>Titular</strong>
					no 
					ambiente online e offline, através da coleta de dados como o nome, e­mail, telefones, cidade e 
					estado, dados, estes, a serem fornecidos pelo <strong>Titular</strong>
					, com o seu consentimento, no momento  de seu pré­cadastro e/ou aceite.
				</h2>
				<h3>As informações pessoais são coletadas sempre que o titular:</h3>
				<ul>
					<p>
						a) Utilizar os aplicativos oficiais da <strong>Viatech</strong>
						, destinados para smartphones; 
					</p>
					<p>b) Solicitar a contratação de qualquer um dos nossos produtos ou serviços;</p>
					<p>
						c) Navegar no website da <strong>Viatech</strong>;
					</p>
					<p>d) Entrar em contato conosco através dos canais oficiais de atendimento;</p>
					<p>e) Participar de pesquisas ou promoções organizadas pela Viatech.</p>
				</ul>
				<h3>Também serão armazenados os dados, conforme delimita a Legislação, de quem:</h3>
				<ul>
					<p>a) Foi nosso cliente;</p>
					<p>b) Realizou transações por meio da Instituição ou com algum de nossos clientes;</p>
					<p>
						c)
						Foi ou é representante, procurador, tutor, curador, empregado ou sócio de algum cliente, 
						alguma empresa ou entidade da qual mantemos relação.
					</p>
				</ul>
				<p>
					Os  dados  coletados,  bem  como  seu  tratamento,  têm  como  objetivo  atender 
					suficientemente às finalidades previstas nesta política de privacidade e nos termos de uso. São 
					exemplos de dados pessoais que podem ser coletados pela <strong>Viatech</strong> :
				</p>
				<ul>
					<p>
						a) Dados de identificação, contemplando nome completo, data de nascimento, atividade 
						profissional,  gênero,  CPF,  RG  e  outros  documentos  de  identificação,  estado 
						civil,  nacionalidade, naturalidade, entre outros;
					</p>
					<p>
						b) Informações  de  contato  e  localização,  compreendendo  endereço  residencial  e 
						comercial,  endereço  de  e­mail,  número  de telefone  residencial  e  comercial, 
						celular,  entre outros;
					</p>
					<p>
						c) Informações  relacionadas  a  terceiros  vinculados  ao  titular,  como  filiação, 
						representantes,  fiador,  procurador,  colaborador,  sócio  ou  beneficiário  de 
						produto ou  serviço, dentre outros;
					</p>
					<p>d) Dados pessoais sensíveis, compreendendo dados biométricos (facial e/ou digital);</p>
					<p>
						e) Informações  de utilização  de  produtos  e  serviços  provenientes,  por  exemplo, 
						de  transações e movimentações financeiras junto à <strong>Viatech</strong>;
					</p>
					<p>
						f) Dados financeiros, contemplando as informações relacionadas à situação financeira e 
						patrimonial do <strong>Titular</strong>;
					</p>
					<p>
						g) Informações  relativas  a  registros  eletrônicos,  dispositivos  e  de  interação 
						nos  canais 
						oficiais, coletando dados de identificação e uso dos dispositivos, de conexão (data, hora, 
						endereço de IP, rede utilizada, dentre outros), bem como informações obtidas a partir 
						do uso de cookies nos sites e aplicativos da <strong>Viatech</strong>
						. Além de informações necessárias 
						à realização de funcionalidades específicas, como geolocalização;
					</p>
					<p>
						h) Informações  de  dados  de  navegação,  mídia  e  redes  sociais,  como  páginas  e 
						funcionalidades acessadas nos canais oficiais da <strong>Viatech</strong>
						Viatech, assim como a quantidade de  cliques;
					</p>
					<p>
						i) Dados de pesquisa, compreendendo as informações, avaliações e opiniões concedidas 
						de maneira voluntária durante a realização de pesquisas conduzidas pela 
						<strong>Viatech</strong>
						Viatech, da 
						mesma forma, no preenchimento de formulários nos canais de relacionamento, central 
						de relacionamento e demais canais oficiais disponíveis;
					</p>
					<p>
						j) Dados  de  acesso,  como  informações  de  login  e  informações  profissionais, 
						como  currículo,  histórico  profissional  e  demais  informações,  disponibilizadas 
						pelo  próprio Titular, necessárias à candidatura de vagas profissionais divulgadas pela
						<strong> Viatech</strong>.
					</p>
				</ul>
				<p>
					As informações e dados podem ser fornecidos diretamente pelos Titulares nos nossos 
					canais,  serem  coletadas  em  decorrência  da  prestação  de  serviços  ou  fornecidas 
					por  fontes 
					externas legítimas, como parceiros estratégicos, prestadores de serviços, outras instituições do 
					sistema  financeiro,  bureaus de crédito, 
					sistemas de informações de crédito, órgãos públicos, 
					além de fontes de prevenção à lavagem de dinheiro e fraudes.
				</p>
				<p>
					Os dados coletados e tratados são utilizados pela <strong>Viatech</strong>
					, em observância aos limites 
					da legislação vigente, especialmente no que tange a Lei Geral de Proteção de Dados (LGPD), 
					para as seguintes finalidades:
				</p>
				<ul>
					<p>
						a)
						atender aos interesses e necessidades do cliente, potencial cliente, parceiros e demais 
						terceiros interessados;
					</p>
					<p>
						b)
						Prestar serviços, oferecer produtos e executar atividades relacionadas à sua atuação no 
						sistema financeiro nacional;
					</p>
					<p>
						c)
						Manter atualizado o cadastro do cliente, bem como verificar sua identidade e viabilizar 
						o acesso aos produtos e serviços;
					</p>
					<p>
						d) Executar  contratos  firmados  junto  à  Viatech com  clientes,  prestadores  de 
						serviço  e  parceiros;
					</p>
					<p>
						e) Promover  aprimoramentos  técnicos  e  de  segurança,  bem  como  resolver 
						problemas  relativos  aos  temas  e  monitorar  o  uso  e  performance  dos  produtos 
						e  serviços  disponibilizados;
					</p>
					<p>f) Cumprir obrigações legais, regulatórias e autorregulatórias;  </p>
					<p>g) Possibilitar as operações de Open Finance;</p>
					<p>
						h) Realizar investigações e tomar medidas de prevenção e combate de condutas ilícitas, 
						fraudulentas
						e crimes financeiros, visando garantir a segurança dos clientes e do sistema 
						financeiro nacional;
					</p>
					<p>i) Cumprir ordens e decisões judiciais, administrativas e arbitrais;</p>
					<p>
						j)
						Cumprir as disposições obrigatórias previstas na legislação e na regulação pertinentes 
						ao segmento, atividades e operações realizadas pela instituição;
					</p>
					<p>
						k) Promover  ações  de  divulgação,  promoção,  pesquisa,  eventos,  patrocínios, 
						apoios  e 
						quaisquer outras atividades de Marketing e comunicação com públicos de interesse;
					</p>
					<p>
						l)
						Desenvolver estatísticas, estudos, pesquisas e levantamentos relativas ao segmentos e 
						às atividades realizadas pela Instituição; 
					</p>
					<p>
						m)
						Criar, analisar e aperfeiçoar produtos e serviços, bem como medir e entender a interação 
						e  satisfação  de  clientes  com  os  serviços  prestados,  canais  de  relacionamento, 
						redes  sociais, sites, aplicativos e outras ferramentas necessárias ao atendimento.
					</p>
				</ul>
				<p>
					Para  atendimento  das  finalidades  previstas  ao  tratamento  dos  dados  coletados, 
					bem  como  cumprir  as  determinações  legislativas  e  normativas,  a 
					<strong>Viatech</strong> poderá  realizar o 
					compartilhamento desses dados com terceiros, parceiros, autoridades judiciais, administrativas, 
					regulatórias,  governamentais,  entes  públicos,  instituições  do  sistema  financeiro 
					e  órgãos  reguladores, com estrita observância às necessidades e finalidades previstas.
				</p>
				<h3>
					As  principais  possibilidades  de  compartilhamento  de  dados  detidos  pela  Viatech
					compreendem: 
				</h3>
				<ul>
					<p>Prestação e oferecimento de produtos e serviços;</p>
					<p>Aprimoramento da estrutura operacional e de oferta dos produtos e serviços;</p>
					<p>Marketing, prospecção, pesquisa e promoção de novos produtos e serviços;</p>
					<p>
						Manutenção e aprimoramento da estrutura tecnológica, bem como na resolução 
						de problemas técnicos e/ou de segurança;
					</p>
					<p>Aprimoramento e manutenção dos sites e aplicativos da instituição;</p>
					<p>
						Contato  por  telefone,  e­mail,  SMS,  Whatsapp,  push  ou  outros  meios  de 
						comunicação disponíveis;
					</p>
					<p>Viabilização de operações de Open Finance;</p>
					<p>
						Validação das informações e documentos de identificação, bem como aplicação 
						de avaliações periódicas;
					</p>
					<p>
						Cumprimento das obrigações legais e regulatórias, bem como para o exercício 
						regulado de direitos;
					</p>
					<p>
						Investigação e desenvolvimento de ferramentas, metodologias e demais meios 
						de prevenção de ilicitudes.
					</p>
				</ul>
				<p>
					Os Dados Pessoais poderão ser transferidos e tratados em outros países, de acordo com 
					as hipóteses previstas na LGPD e legislação aplicável, em observância às finalidades previstas 
					nesta Política de Privacidade.
				</p>
				<p>
					Com o irretratável e irrevogável encerramento da conta virtual do <strong>Titular</strong>
					, por qualquer 
					motivo, o armazenamento e tratamento dos dados pessoais poderá ser mantido por um período 
					adicional de tempo de 10 (dez) anos ou pelo exato prazo prescricional obrigatório de seu direito 
					de  ação,  o  que  for  maior,  para  fins  de  auditoria  cumprimento  de  obrigações 
					legais  ou  regulatórias, para o exercício 
					regular de direitos ou também pelo prazo necessário de acordo  com  a  base  legal  que 
					justifique  a  retenção  dos  dados,  por  exemplo,  para  cumprir  com 
					disposições do Banco Central do Brasil ou para preservação de direitos em caso de ajuizamento 
					de ações judiciais em desfavor da própria <strong>Viatech</strong>.
				</p>
				<h2>DIREITOS E RESPONSABILIDADES DO TITULAR</h2>
				<h3>
					A Lei Geral de Proteção de Dados (LGPD) garante os seguintes direitos aos titulares 
					de dados:
				</h3>
				<ul>
					<p>a) Confirmação da existência de tratamento;</p>
					<p>b) Acesso aos dados;</p>
					<p>c) Correção de dados incompletos, inexatos ou desatualizados;</p>
					<p>
						d) Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados 
						em desconformidade com o disposto na LGPD;
					</p>
					<p>
						e)
						Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição 
						expressa,  de  acordo  com  a  regulamentação  da  autoridade  nacional,  observados 
						os  segredos comercial e industrial; 
					</p>
					<p>
						f) Eliminação  dos  dados  pessoais  tratados  com  o  consentimento  do titular, 
						exceto  nas  hipóteses previstas no art. 16 da Lei 13.709;
					</p>
					<p>g) Revogação do consentimento, nos termos do § 5º do art. 8º da Lei 13.709. </p>
				</ul>
				<p>
					Nos termos do art. 41 da Lei nº 13.709/2018 – Lei Geral de Proteção de Dados (LGPD),  a
					<strong> Viatech</strong> conta com um encarregado de dados – Data Protection Officer
					(DPO). Através do  e­mail dpo@viatechbr.com.br,  o <strong>Titular</strong>
					poderá  requerer  ao  encarregado  a  análise  de  sua  solicitação, elencadas acima.
				</p>
				<h3>
					Ao <strong>Titular</strong> atribui­se as seguintes responsabilidades:
				</h3>
				<ul>
					<p>
						a)
						pelas ações ou omissões por ele realizadas durante o uso da internet, do site, aplicativo 
						e demais canais oficiais da Viatech, bem como pelos conteúdos transmitidos através 
						destes canais;
					</p>
					<p>
						b) a  indenização  de  todos  e  quaisquer  danos,  diretos  ou  indiretos,  que 
						possam  surgir  devido  a  violações  de  direitos,  como  os  de  propriedade 
						intelectual,  sigilo  e  individualidade, de outros
						usuários ou terceiros, e que possam vir a afetar a Viatech e 
						seja devido ao não cumprimento destes Termos de Uso e Política de Privacidade ou a 
						qualquer ação realizada durante o acesso à internet, ao site ou ao aplicativo.
					</p>
				</ul>
				<h2>DISPOSIÇÕES GERAIS</h2>
				<p>
					Ao  ler  esta  Política  de  Privacidade  e  clicar,  ao  final,  em  "Eu  li  e  estou 
					ciente  das  condições  de  tratamento  dos  meus  dados  pessoais  e  forneço  meu 
					consentimento,  quando  aplicável”, o Titular consente com o Tratamento dos Dados pessoais
					mencionados nesta Política.
				</p>
				<p>
					As disposições previstas nesta poderão sofrer alterações sempre que necessário, visando 
					o adequado cumprimento das legislações e regulamentações vigentes e, da mesma forma, para 
					a promoção de melhorias e adequações na estrutura de privacidade da Viatech. 
				</p>
				<h3>
					Ainda,  na  utilização  das  plataformas,  aplicativos  e  canais  oficiais  da 
					Instituição,  é  expressamente proibido:
				</h3>
				<ul>
					<p>
						a)
						Fazer uso do site para meios, fins ou atividades ilícitas, ilegais, fraudulentas ou danosas 
						à  <strong>Viatech</strong>, bem como para concretizar ataques que interfiram  no  fiel 
						funcionamento  das plataformas, aplicativos e demais canais disponíveis;
					</p>
					<p>
						b)
						Captar, mediante artifícios fraudulentos e não permitidos em lei, dados transmitidos por 
						terceiros entre seus computadores pessoais e os servidores de conexão e hospedagem  do
						site;
					</p>
					<p>
						c)
						Nos termos da Política de Privacidade, utilizar dados falsos para o acesso e cadastro na 
						plataforma do site da <strong>Viatech;</strong>
					</p>
					<p>
						d)
						Fazer uso deste site para copiar, armazenar, hospedar, transmitir, enviar, usar, publicar 
						ou  distribuir  quaisquer  materiais  que  consistam,  ou  estejam  ligados  a 
						spyware, 
						malware, vírus de computadores, keyloggers, rootkits ou outros softwares maliciosos;
					</p>
					<p>
						e) Executar procedimentos sistemáticos ou automatizados de coleta de dados, incluindo 
						data scraping, data mining e data harvesting, sem a autorização expressa da Instituição;
					</p>
					<p>
						f)
						Divulgar, para fins pessoais ou comerciais, o conteúdo deste website por meio de envio
						em  massa  (spam)  de  mala  direta,  por  e­mail,  SMS,  telemarketing  ou  outros 
						mecanismos, para destinatários não solicitantes ou indeterminados;
					</p>
					<p>
						g) Utilizar  no  cadastro  criado  neste  website  palavras  com  caráter  ofensivo, 
						discriminatório,  pejorativo,  de  baixo  calão  ou  que  possam,  em  razão  de  sua 
						terminologia, caracterizar apologia ao crime.
					</p>
				</ul>
				<p>
					O  foro eleito como o competente para dirimir quaisquer divergências que porventura 
					venham a ocorrer em virtude do cumprimento desta Política de Privacidade, em detrimento de 
					qualquer outro, é o da comarca de São Paulo­SP.
				</p>
			</div>
		</div>
	);
};
export default LegalPr;
