import React, { useEffect, useState } from 'react';
import goldImg from '../../asserts/images/goldcard.png';
import premiumImg from '../../asserts/images/premiumcard.png';
import freeImg from '../../asserts/images/freecard.png';
import './index.scss';
import Questions from '../../components/questions';
import payBank from '../../asserts/images/paybank.png';
import { FadeIn } from '../../components/fadein';
import { useIntl } from 'react-intl';
const CrossBorder = () => {
	const [cord, setCord] = useState<boolean>(false);
	const intl = useIntl();
	useEffect(() => {
		setTimeout(() => {
			setCord(true);
		}, 900);
	}, []);
	return (
		<div className="crossBorder">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container support-hero-grid">
					<div className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.crossborder.crossBorderPayments',
								defaultMessage: 'Cross-border payments',
							})}
						</div>
						<div className="banner-des">
							{intl.formatMessage({
								id: 'page.crossborder.safe',
								defaultMessage:
									'Safe, efficient and convenient. Make your international transactions easier!',
							})}
						</div>
					</div>
					<div className="hero-cards-wrapper">
						<div className="headImg loaded">
							<img src={premiumImg} className="cord premiumImg" alt="" />
							<img src={goldImg} className={`cord ${cord ? 'goldImg' : null}`} alt="" />
							<img src={freeImg} className={`cord ${cord ? 'freeImg' : null}`} alt="" />
						</div>
					</div>
				</div>
			</div>
			{/* <div className="crossBorderHeader">
				<div className="headTitle">
					<div className="solve">Cross-border payments</div>
					<div className="cash">
						Safe, efficient and convenient. Make your international transactions easier!
					</div>
				</div>
				<div className="headImg loaded">
					<img src={premiumImg} className="cord premiumImg" alt="" />
					<img src={goldImg} className={`cord ${cord ? 'goldImg' : null}`} alt="" />
					<img src={freeImg} className={`cord ${cord ? 'freeImg' : null}`} alt="" />
				</div>
			</div> */}
			<FadeIn className="crossBorderSolution page-padding">
				<div className="crossBorderSolutionText">
					<div className="crossBorderSolutionTitle">
						{intl.formatMessage({
							id: 'page.crossborder.crossBorderPayments',
							defaultMessage: 'Cross-border payments',
						})}
					</div>
					<div className="crossBorderSolutionDesc">
						<p>
							{intl.formatMessage({
								id: 'page.crossborder.viatechResponsible',
								defaultMessage: `Viatech Responsible for all cross-border payment processes: currency conversion,
							transaction fees, exchange rates and alignment with national entities.`,
							})}
						</p>
						<p>
							{intl.formatMessage({
								id: 'page.crossborder.weGuarantee',
								defaultMessage: `We guarantee fixed exchange rates, ideal taxes and direct contact with local partners
							24 hours a day.`,
							})}
						</p>
					</div>
				</div>
				<div className="crossBorderSolutionImg"></div>
			</FadeIn>
			<FadeIn className="crossBorderTools page-padding">
				<div className="crossBorderToolsDetails">
					<div className="crossBorderToolsDetailsTitle">
						{intl.formatMessage({
							id: 'page.crossborder.tools',
							defaultMessage: 'Tools',
						})}
					</div>
					<div className="crossBorderToolsDetailsDesc">
						{intl.formatMessage({
							id: 'page.crossborder.weSupport',
							defaultMessage: 'We support multiple payment methods',
						})}
					</div>
					<div className="crossBorderToolsDetailsImg">{/* <img src={payBank} alt="" /> */}</div>
				</div>
				<div className="crossBorderToolsTag">
					<div className="crossBorderToolsTagBox">
						<div className="crossBorderToolsTagBoxIcon"></div>
						<div className="crossBorderToolsTagBoxText">
							{intl.formatMessage({
								id: 'page.crossborder.sercure',
								defaultMessage: 'Secure and encrypted integration',
							})}
						</div>
					</div>
					<div className="crossBorderToolsTagBox">
						<div className="crossBorderToolsTagBoxIcon"></div>
						<div className="crossBorderToolsTagBoxText">
							{intl.formatMessage({
								id: 'page.crossborder.fully',
								defaultMessage: 'Fully API interface',
							})}
						</div>
					</div>
					<div className="crossBorderToolsTagBox">
						<div className="crossBorderToolsTagBoxIcon"></div>
						<div className="crossBorderToolsTagBoxText">
							{intl.formatMessage({
								id: 'page.crossborder.worldwide',
								defaultMessage: 'Payments worldwide',
							})}
						</div>
					</div>
				</div>
			</FadeIn>
			<FadeIn className="crossBorderSecurity page-padding">
				<div className="crossBorderSecurityDesc">
					<div className="crossBorderSecurityDescTitle">
						{intl.formatMessage({
							id: 'page.crossborder.crossborderPaymentSecurity',
							defaultMessage: 'Cross-border payment security',
						})}
					</div>
					<div className="crossBorderSecurityDescText">
						{intl.formatMessage({
							id: 'page.crossborder.weUseMulti-factor',
							defaultMessage: `We use multi-factor authentication techniques to identify users and carry out legitimate
						transactions, 24*7 transaction monitoring, risk management and protection against
						fraudsters.`,
						})}
					</div>
				</div>
				<div className="crossBorderSecurityImg"></div>
			</FadeIn>
			<FadeIn>
				<section className="question page-padding">
					<Questions />
				</section>
			</FadeIn>
		</div>
	);
};
export default CrossBorder;
