import React from 'react';
import './index.scss';
import europeBanner from '../../asserts/images/europebanner.png';
import paymentsInEurope from '../../asserts/images/paymentsInEurope.png';
import paymentExperience from '../../asserts/images/paymentExperience.png';
import europePay from '../../asserts/images/europePay.png';
import { FadeIn } from '../../components/fadein';
import Questions from '../../components/questions';
import { useIntl } from 'react-intl';
const Europe = () => {
	const intl = useIntl();
	return (
		<div className="europe">
			<div className="banner-section  background-color-primary-light  page-padding">
				<div className="banner-container support-hero-grid">
					<div className="banner-content in">
						<div className="banner-title">
							{intl.formatMessage({
								id: 'page.europe.solutions',
								defaultMessage: 'Solutions for the European market',
							})}
						</div>
						<div className="banner-des">
							{intl.formatMessage({
								id: 'page.europe.supportYourBusiness',
								defaultMessage: 'Support your business with our most advanced technology',
							})}
						</div>
						<div className="navbar-right">
							<a href="/contactUs" className="button primary small w-inline-block">
								<div className="button-inner">
									<div className="button-inner-text">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
									<div className="button-inner-text-hover">
										{intl.formatMessage({
											id: 'page.button.openAccount',
											defaultMessage: 'Open Account',
										})}
									</div>
								</div>
							</a>
						</div>
					</div>
					<div className="banner-img in">
						<img src={europeBanner} alt="" />
					</div>
				</div>
			</div>
			<section className="about-section  page-padding">
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.europe.theProspect',
								defaultMessage: 'The prospect of online payments in Europe',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.europe.atPresent',
								defaultMessage: `At present, in European countries, e-commerce, online payment fields, e-wallets are
							booming, presenting diversified payment methods`,
							})}
						</div>
					</div>
					<div className="about-item-img">
						<img src={paymentsInEurope} alt="" />
					</div>
				</FadeIn>
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.crossborder.tools',
								defaultMessage: 'Tools',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.europe.paymentMethods',
								defaultMessage: 'We support multiple payment methods',
							})}
						</div>
						<div className="europePay">
							<img src={europePay} alt="" />
						</div>
					</div>
					<div className="about-item-img">
						<div className="crossBorderToolsTag">
							<div className="crossBorderToolsTagBox">
								<div className="crossBorderToolsTagBoxIcon"></div>
								<div className="crossBorderToolsTagBoxText">
									{intl.formatMessage({
										id: 'page.crossborder.sercure',
										defaultMessage: 'Secure and encrypted integration',
									})}
								</div>
							</div>
							<div className="crossBorderToolsTagBox">
								<div className="crossBorderToolsTagBoxIcon"></div>
								<div className="crossBorderToolsTagBoxText">
									{intl.formatMessage({
										id: 'page.crossborder.fully',
										defaultMessage: 'Fully API interface',
									})}
								</div>
							</div>
							<div className="crossBorderToolsTagBox">
								<div className="crossBorderToolsTagBoxIcon"></div>
								<div className="crossBorderToolsTagBoxText">
									{intl.formatMessage({
										id: 'page.crossborder.worldwide',
										defaultMessage: 'Payments worldwide',
									})}
								</div>
							</div>
						</div>
					</div>
				</FadeIn>
				{/* <FadeIn className="crossBorderTools page-padding">
					<div className="crossBorderToolsDetails">
						<div className="crossBorderToolsDetailsTitle">Tools</div>
						<div className="crossBorderToolsDetailsDesc"></div>
						<div className="crossBorderToolsDetailsImg"></div>
					</div>
				</FadeIn> */}
				<FadeIn className="about-item">
					<div className="about-item-content">
						<div className="about-item-title">
							{intl.formatMessage({
								id: 'page.europe.aOne-Click',
								defaultMessage: 'A one-click payment experience that connects you to the world',
							})}
						</div>
						<div className="about-item-des">
							{intl.formatMessage({
								id: 'page.europe.withAGlobal',
								defaultMessage: `With a global payment network, diversified payment solutions, advanced security
							technology and a dedicated customer service team, we will help you stand out in the
							highly competitive European market`,
							})}
						</div>
					</div>
					<div className="about-item-img">
						<img src={paymentExperience} alt="" />
					</div>
				</FadeIn>
			</section>
			<FadeIn>
				<section className="question page-padding">
					<Questions />
				</section>
			</FadeIn>
		</div>
	);
};

export default Europe;
